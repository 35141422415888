import React from 'react'
import classnames from "classnames"
import NuagikePieChart from "./pie"
import NuagikeBarChart from "./bar"
import NuagikeRadialChart from "./radial"

import "./index.scss"
const NuagikeChart = ({
  type,
  height,
  ...props
}) => {

  return props.data?.length ?

  (type === "bar"
    ? <div className={classnames("bg-white", props.className)} style={{
          width: '100%',
          height: height || (props.data && props.data.length ?  props.data.length * 40 : 500 )
        }}><NuagikeBarChart {...props}/></div>
    : type === "pie"
    ? <div className={classnames("bg-white", props.className)} style={{
          width: '100%',
          height: height || 220
        }}><NuagikePieChart {...props}/></div>
    : type === "radial"
    ? <div className={classnames("bg-white", props.className)} style={{
          width: '100%',
          height: height || 220
        }}><NuagikeRadialChart {...props}/></div>
    : "")
    : <div className="nuagike-chart-enmpty">
      </div>;

}

export default NuagikeChart;
