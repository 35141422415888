import React, {useState} from 'react'
import {useSelector} from 'react-redux'
import classnames from 'classnames';
import {useIntl} from 'react-intl';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {Document, Page as PDFPage, pdfjs} from "react-pdf";
import {Button, Badge, Loading, Input} from "src/components/nuagike/index"


class PDFView extends React.Component {

  constructor(props) {
    super(props);
    var host = window.location.protocol + "//" + window.location.host;
    this.state = {
      url: props.url ? props.url : (host + "/default.pdf")
    };
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    // You don't have to do this check first, but it can help prevent an unneeded render
    var host = window.location.protocol + "//" + window.location.host;
    if (nextProps.url !== this.state.url) {
      this.setState({url: nextProps.url ? nextProps.url : (host + "/default.pdf")});
    }
  }

  shouldComponentUpdate(nextProps, nextState) {

    return nextProps.url !== this.state.url;
  }

  render() {
    return (<div className="nuagike-pdf rounded p-2 m-0 bg-grise-1">
      <div className="w-100 d-flex p-0 m-0">
        <Document loading={<Loading/>} file={{
            url: this.state.url
          }} onLoadSuccess={() => {}} onFailure={() => {}} onLoadError={() => {}}>
          <PDFPage pageNumber={1} width={800}/>
        </Document>
      </div>
    </div>);
  }
}
export default PDFView;
