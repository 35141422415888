import React from 'react'
import {useDrag, useDrop} from 'react-dnd';
import {getEmptyImage} from 'react-dnd-html5-backend';
import classnames from "classnames"
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import "./index.scss";

import {Card} from "src/components/nuagike/index"
const DND_ITEM_TYPE = 'NUAGIKE_REF_MODEL';

const DraggableItem = ({
  data,
  index,
  orderField,
  moveRow,
  saveMoveRow,
  hoverOn,
  errors,
  dirty,
  invalid,
  ...props
}) => {
  const dropRef = React.useRef(null);
  const dragRef = React.useRef(null);

  const [isHoverOnId, hoverOnId] = React.useState(null)

  const [{shallow, targetId}, drop] = useDrop({
    accept: DND_ITEM_TYPE,
    canDrop(item, monitor) {
      return data && data.ref !== "new"
    },
    hover(item, monitor) {


      if (!dropRef.current) {
        return
      }

      const dragIndex = item.index;
      const hoverIndex = index;
      // Don't replace items with themselves
      if (dragIndex === hoverIndex) {
        return
      }
      // Determine rectangle on screen
      const hoverBoundingRect = dropRef.current.getBoundingClientRect()
      // Get vertical middle
      const hoverMiddleY = (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2
      // Determine mouse position
      const clientOffset = monitor.getClientOffset()
      // Get pixels to the top
      const hoverClientY = clientOffset.y - hoverBoundingRect.top
      // Only perform the move when the mouse has crossed half of the items height
      // When dragging downwards, only move when the cursor is below 50%
      // When dragging upwards, only move when the cursor is above 50%
      // Dragging downwards
      if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
        return
      }
      // Dragging upwards
      if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
        return
      }
      // Time to actually perform the action
      //console.log(monitor);

      handleMoveRow(dragIndex, hoverIndex, monitor.targetId)

      // Note: we're mutating the monitor item here!
      // Generally it's better to avoid mutations,
      // but it's good here for the sake of performance
      // to avoid expensive index searches.
      item.index = hoverIndex;
      hoverOnId(hoverIndex === index)
    },
    drop(item, monitor) {
      saveMoveRow(item.index)
    },
    collect: (monitor, item) => {
      return ({targetId: monitor.targetId})
    }
  })

  const [
    {
      currentDraggedItem, isDragging
    }, drag, preview]
  ////
  = useDrag({
    type: DND_ITEM_TYPE,
    item: {
      index
    },
    canDrag(monitor) {
      return data && data.ref !== "new"
    },
    collect: (monitor) => ({
      currentDraggedItem: monitor.getItem(),
      isDragging: !!monitor.getItem()
    })
  });

  preview(drop(dropRef));
  drag(dragRef);

  let draggableitemClassName = isDragging
    ? "isDragging"
    : "";

  let cardClassName =  "";

  let colDragClassNames = errors && errors.includes(data.ref)
    ? " border-1 border-light "
    : "";

  const handleMoveRow = (dragIndex, hoverIndex, _targetId)=>{
    moveRow(dragIndex, hoverIndex);
  }

  return <Card selected={props.selected} className={classnames(cardClassName, "",
  {"border-success-90" : isDragging},
  ////
  {"border-warning bg-warning-70" : dirty && !invalid},
  {"border-danger bg-danger-70" : invalid},
  {["opacity-25 bg-success-90"]: currentDraggedItem?.index === index}


)}>

    <div ref={dropRef}
    className={classnames(draggableitemClassName,
      "draggable-item row p-0 m-0  d-flex align-items-center justify-content-between rounded w-100"
    )}>
      <div className="col col-12 p-0 m-0 d-flex align-items-center  w-100">
        <div ref={dragRef}
        className={classnames(colDragClassNames,
          "col-drag d-flex m-0 p-1 align-items-center justify-content-center")}>
          <FontAwesomeIcon icon="grip-horizontal" size="2x" className="text-info "/>
        </div>

        <div className="p-0 m-0 d-flex flex-grow-1 align-items-center">
          {props.children}
        </div>

      </div>
    </div>
  </Card>
}

export default DraggableItem;
