import React, { useState } from 'react'
import classnames from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useLoading, useEnums } from "src/components/nuagike/index"
import config from "src/config/index";
import NuagikeSelect from "./select";
import NuagikeDate from "./date/index";

const DefaultInput = (props) => {
  const loading = false //useLoading();
  const [localValue, setLocalValue] = React.useState("")
  const [dirty, setDirty] = React.useState(false)

  React.useEffect(() => {
    setLocalValue(props.value)
  }, [props.value]);

  const handleChange = (value) => {
    if (value !== localValue) {
      setLocalValue(value)
      setDirty(true)
    }
  }

  const handleBlur = (e) => {
    if (dirty) {
      checkAndUpdate(e.target.value);
    }
  }

  const handleMouseOut = (e) => {
    if (props.value !== e.target.value && dirty) {
      checkAndUpdate(e.target.value);
    }
  }

  const checkAndUpdate = (value) => {
    if (props.type === "number") {
      if (props.min != null && Number(props.min) >= Number(value)) {
        value = props.min
      }
      if (props.max != null && Number(props.max) <= Number(value)) {
        value = props.max
      }
    }
    props.onChange(props.name, value)
  }

  const regexValidation = (regex, value) => {
    return !value || (regex.test(value) === true);
  }

  return <div
    className={classnames("nuagike-input p-0 m-0 border-none d-flex align-items-center",
      //
      {
        ['form-floating']: !props.nofloating
      }, {
      ["nuagike-input-disabled"]: props.disabled || loading || props.loading
    }, {
      "d-none": props.hidden
    }, {
      "prepended": props.prepend
    }, {
      "mt-1": !props.nomargin
    }, props.className)}>
    {
      props.prependIcon
        ? <div className={classnames("nuagike-input-prependicon d-flex align-items-center rounded-start", props.prependIconClassName)}>
          <FontAwesomeIcon icon={props.prependIcon} />
        </div>
        : ""
    }
    {
      props.prepend
        ? <div className="nuagike-input-prepend d-flex align-items-center">
          <span className="pe-1 fw-light lh-sm one-line">{props.prepend}</span>
        </div>
        : ""
    }
    {

      ["date"].includes(props.type)
        ? <NuagikeDate {...props} />
        : ["text", "email", "phone", "postal", "password"].includes(props.type)
          ? <input autoComplete={"off"} maxLength="250" type={props.type} id={props.id} name={props.name
            ? props.name
            : props.id} className={classnames("form-control", {
              "rounded-0 rounded-end": props.prependIcon
            }, {
              "is-invalid": props.invalid || (props.type === "phone" && !regexValidation(config.REGEX.phone, localValue)) || (props.type === "email" && !regexValidation(config.REGEX.email, localValue)) || (
                props.type === "postal" && !regexValidation(config.REGEX.postal, localValue)
              )
            }, props.className)}
            //
            value={localValue} placeholder={props.placeholder
              ? props.placeholder
              : props.label} onChange={(e) => handleChange(e.target.value)}
            ////
            onBlur={(e) => handleBlur(e)} onMouseOut={handleMouseOut}
            ///////
            disabled={props.disabled || loading || props.loading} onKeyPress={props.onKeyPress} />

          : ["number"].includes(props.type)
            ? <input type={props.type} id={props.id} className={classnames("form-control", {
              "rounded-0 rounded-end ": props.prependIcon
            }, { "is-invalid": props.invalid })}
              //
              value={localValue} placeholder={props.placeholder
                ? props.placeholder
                : props.label} onChange={(e) => checkAndUpdate(e.target.value)}
              /////
              disabled={props.disabled || loading || props.loading} />

            : props.type === "select"
              ? <NuagikeSelect {...props} />
              : <span>{props.value}</span>
    }

    {
      props.label
        ? <label className={classnames("form-label form-check-label text-truncate w-100 ", { "form-label-prependicon": props.prependIcon })} htmlFor={props.id}>
          {props.label}<FontAwesomeIcon icon="sun" className="ms-1 text-danger" hidden={!props.required} /></label>
        : ""
    }
  </div>

}

export default DefaultInput;
