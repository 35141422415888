import React, {useState, useEffect, useMemo} from 'react'
import {useSelector, useDispatch} from 'react-redux'
import classnames from "classnames"
import {useIntl} from 'react-intl';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'

const AccordionItem = (props) => {
  const intl = useIntl()

  return <div className={classnames("accordion-item", props.className)} hidden={props.hidden}>
    <h2 className="accordion-header" id={"heading" + props.id}>
      <button disabled={props.disabled} className="accordion-button" type="button"
      data-bs-toggle="collapse" data-bs-target={"#collapse" + props.id} aria-expanded="true" aria-controls={"collapse" + props.id}>
        {
          props.icon
            ? <FontAwesomeIcon size="1x" icon={props.icon} className={classnames("me-2", {
                  ["text-" + props.iconColor]: props.iconColor
                })}/>
            : ""
        }{props.title}
      </button>
    </h2>
    <div id={"collapse" + props.id} className={classnames("accordion-collapse collapse", {
        "show": props.show
          ? props.show
          : false
      })} aria-labelledby={"heading" + props.id} data-bs-parent={"#accordion" + props.parent}>
      <div className="accordion-body">
        {props.children}
      </div>
    </div>
  </div>

}

export default AccordionItem;
