import React, {useState, useEffect, useMemo} from 'react'
import classnames from "classnames"
import {Input} from "src/components/nuagike/index"

import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import "./index.scss"

const Param = ({
  name,
  title,
  description,
  value,
  type,
  size,
  alertType,
  alerMessage,
  error,
  onChange
}) => {

  return <div className={classnames("nuagike-param row p-0 m-0 mb-1", "border rounded border-light bg-white")}>

    <div className={classnames("col col-12 m-0 p-2")}>

      <Input size={size || "lg"} label={title || ""} type={type || "text"} onChange={onChange} value={value != null
          ? value
          : ''} name={name} id={"switch-param-" + name}/>

    </div>
    {
      description
        ? <div className="col col-12">
            <small className="text-info d-flex align-items-center p-1">
              <FontAwesomeIcon icon="info-circle" className="me-2"/>{description}
            </small>
          </div>
        : ""
    }
    {
      alertType
        ? <div className="col col-12" hidden={!value}>
            <div className={"float-start p-0 m-0 px-0 my-1 py-0 alert alert-" + alertType}>

              <small className="ps-1 pe-2"><FontAwesomeIcon icon={alertType === "warning"
              ? "exclamation-circle"
              : alertType === "danger"
                ? "exclamation-triangle"
                : "info"} className="me-1"/>{alerMessage}
              </small>
            </div>
          </div>
        : ""
    }
  </div>
}

export default Param;
