import React from "react";
import {Badge, Input} from "src/components/nuagike/index"
import classnames from "classnames"
import {useIntl} from 'react-intl';
import formatDate from "date-fns/format";
import parseISO from "date-fns/parseISO";
import {getEnumIcon} from "src/tools/icons"

Object.byString = function(o, s) {
  s = s.replace(/\[(\w+)\]/g, '.$1'); // convert indexes to properties
  s = s.replace(/^\./, ''); // strip a leading dot
  var a = s.split('.');
  for (var i = 0, n = a.length; i < n; ++i) {
    var k = a[i];
    if (k in o) {
      o = o[k];
    } else {
      return;
    }
  }
  return o;
}

// Create an editable cell renderer
export default function EditableCell({
  value: value,
  row: {
    index,
    original
  },
  column: {
    id,
    onClick,
    editable,
    format,
    accessor_currency,
    selectFromList,
    errors,
    accessor,
    locked,
    hide,
    enums,
    badgeClass,
    icon
  },
  updateData, // This is a custom function that we supplied to our table instance
}) {
  // We need to keep and update the state of the cell normally
  const intl = useIntl()

  if (hide && hide({original})) {
    return "";
  }

  if (editable) {

    const onChange = (name, value) => {
      //setValue(value)
      if (value)
        updateData(index, id, value, true, original)
    }

    const switchBoolean = e => {
      updateData(index, id, !value, true, original)
    }

    let inputClassName = classnames("w-100", {
      "new-input": "new" === original.ref
    }, {
      "hidden-label": !(errors)
    }, {"errors": errors});

    return (editable && editable === "switch")
      ? <Input type="switch" normargin={true} disabled={locked && locked(original)} value={value
            ? true
            : false} size="md" label={false} onChange={switchBoolean} className=""/>
      : (
        editable && editable === "select" && selectFromList
        ? <Input type="select" normargin={true} disabled={locked && locked(original)} id={"form-field-type-" + index} value={value} className={inputClassName} onChange={onChange} invalid={errors && errors.includes(original.ref)}>
          <option>{intl.formatMessage({id: "text.admin.choosevalue"})}</option>
          {
            selectFromList && selectFromList.map((item, itemindex) => {
              return <option value={item} key={"option-field-" + itemindex}>{item}</option>
            })
          }

        </Input>
        : <Input className={inputClassName} normargin={true} disabled={locked && locked(original)}
        placeholder={intl.formatMessage({id: "text.admin.introducevalue"})} invalid={errors} value={value
            ? value
            : ""} type={editable && ["text", "number", "date"].includes(editable)
            ? editable
            : "text"} onChange={onChange} min={0}/>)

  } else if (format === "price") {
    return <div className="align-items-center one-line text-info">
      {

        (value !== null && accessor_currency)
          ? <Badge value={value} text={null}
          type="price" onClick={onClick ? () => onClick({original}) : null}
          currency={Object.byString(original, accessor_currency) || ""}/>
          : ""
      }
    </div>
  } else if (format === "date" || format === "datetime" || format === "number") {
    return <div className="align-items-center bg-transparent">
        {value !==null ?<Badge icon={icon}
        tooltip={value} text={value} type={format} bg="transparent" hideBorder={true} className="w-100"
        time={format === "datetime"} onClick={onClick ? () => onClick({original}) : null}/>: ""}
    </div>
  } else if (enums) {
    return value
          ? <Badge tooltip={intl.formatMessage({
            id: "text.enum." + enums + "."+value
          })} className={classnames("d-flex w-100", {
                [badgeClass + value]: badgeClass
              })} text={intl.formatMessage({
                id: "text.enum." + enums + "." +value
              })} onClick={onClick ? () => onClick({original}) : null} icon={getEnumIcon(enums, value, null)} iconSize="1x"
              hideIcon={!enums || !getEnumIcon(enums, value, null)} hideBorder={true} copyclipboard={true}/>
          : null

  } else
    return <Badge icon={icon} text={value} tooltip={value}
    className="bg-transparent text-truncate text-start "
    onClick={onClick ? () => onClick({original}) : null}
    hideIcon={!icon} hideBorder={true} copyclipboard={true}/>
}
