import config from 'src/config/index';
import {postFormData, requestData} from 'src/tools/index';


export function downloadFinanceProducstsStats(search){
  let options = {}
  options.url = config.API_TKT_BASE_URL + "/api/tkt/organizations/stats/csv";
  options.method = 'POST';
  options.body = JSON.stringify({...search});

  return requestData(options, 'application/json', true, "octet-stream").then(response => {
    return response.blob().then(blob => {
      //const url = URL.createObjectURL(blob);
      const url = URL.createObjectURL(blob);
      console.log("download stats url is " + url);
      return Promise.resolve(url);
    })
  }).catch(e => {
    console.error(e);
    return Promise.reject(e);
  });

}

export function uploadFile(data) {
  console.log({"uploadFile": data});
  let uri = "/api/doc/files/upload";
  if (data.ref) {
    uri = "/api/doc/files/" + data.ref + "/upload"
  }
  return postFormData(config.API_DOC_BASE_URL + uri, data, true, false);
}


export function downloadFile(data) {
  let form = {};
  form.name = data.name;
  form.ref = data.ref;
  form.thumbnail = data.thumbnail;
  let options = {}
  options.url = config.API_DOC_BASE_URL + "/api/doc/files/" + data.ref + "/download";
  options.method = 'POST';
  options.body = JSON.stringify(form);

  return requestData(options, 'application/json', true, "octet-stream").then(response => {
    return response.blob().then(blob => {
      //const url = URL.createObjectURL(blob);
      const url = URL.createObjectURL(blob);
      console.log("download file url is " + url);
      return Promise.resolve(url);
    })
  }).catch(e => {
    console.error(e);
    return Promise.reject(e);
  });
}

export function downloadDocument(data) {
  let options = {}
  options.url = config.API_DOC_BASE_URL + "/api/doc/groups/documents/" + data.ref + "/download";
  options.method = 'POST';
  options.body = JSON.stringify({
    ref: data.ref,
    downloadFileName: data.ref + ".pdf",
    context: data.context
  });

  return requestData(options, 'application/json', true, "octet-stream").then(response => {
    return response.blob().then(blob => {
      //const url = URL.createObjectURL(blob);
      const url = URL.createObjectURL(blob);
      console.log("download document url is " + url);
      return Promise.resolve(url);
    })
  }).catch(e => {
    console.error(e);
    return Promise.reject(e);
  });
}
