import React, {useState} from 'react'
import classnames from 'classnames';

import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'

const Range = ({
  id,
  value,
  label,
  min,
  max,
  step,
  onChange,
  name,
  unit,
  required,
  disabled,
  className,
  labelClassName,
  ...props
}) => {
  return <div className="nuagike-input rounded p-1 m-0 border border-1 border-light mt-1 bg-white d-flex">

    <div className="w-100">
      <label className={classnames("form-label ps-2 text-truncate w-100", labelClassName)} htmlFor={id}>
        {label}<span className="ps-1">{props.text}</span><FontAwesomeIcon icon="sun" className="ms-1 text-danger"
        hidden={!required}/></label>

      <input type="range" min={min || 0} max={max || 100} step={step || 1} id={id}
      name={name} className={classnames("form-range border-0 text-info ", className)}
        //
        value={value || 0} onClick={(e) => {
          e.stopPropagation();
          return false;
        }} disabled={disabled} onChange={(e) => {
          //setValue(e.target.value)
          onChange && onChange(name, e.target.value)
        }}/>
    </div>
    <div className="w-25">
      <span className="d-flex align-items-center justify-content-center fw-bold h-100">{value + (unit ? (" " + unit) : "")}</span>
    </div>

  </div>
}

export default Range;
