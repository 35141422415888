import React from 'react'

const Loading = () =>{

  return <div className="container justify-content-center text-center vh-50">
    <div className="p-5 my-auto">
      <span className="text-grise-2 p-2">Unraveling the mysteries of the universe...</span>
    </div>
    <div className="spinner-border" role="status">
      <span className="sr-only"></span>
    </div>
  </div>
}

export default React.memo(Loading);
