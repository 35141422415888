import React, {useState} from 'react'
import classnames from 'classnames';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {Typeahead, Token} from 'react-bootstrap-typeahead';
import {useRepos, useLoading} from "src/components/nuagike/index"
import "./index.scss"

const Country = ({
  onChange,
  ...props
}) => {
  const data = useRepos("countries");
  const loading = false;// useLoading()
  const [countries, setCountries] = React.useState([])
  const ref = React.createRef();

  React.useEffect(() => {
    if (data) {
      let country = data.filter(c => c.cca2 === props.value)[0]
      if (country) {
        setCountries(countries => [
          {
            ...country
          }
        ])
      } else {

        setCountries(countries => [])
        ref.current.clear();
      }

    }
  }, [data, props.value]);

  const handleChange = (values) => {
    onChange(
      props.name, values[0]
      ? values[0].cca2
      : "")
    setCountries(values)
  }

  return <div className="nuagike-input form-floating mt-1 d-flex align-items-center">
    <div className="nuagike-input-prependicon d-flex align-items-center justify-contents-center rounded-start">
      {
        props.value
          ? <div className={classnames("fi fis fi-" + props.value.toLowerCase())}></div>
          : <FontAwesomeIcon icon={"globe-africa"} className=""/>
      }
    </div>

    <Typeahead ref={ref} dropup={props.dropup} className={classnames("form-control", {
        "rounded-0 rounded-end": true
      }, {"is-invalid": props.invalid})}
//////////////
disabled={props.disabled || props.loading || loading}

      id={props.id} name={props.id} multiple={false}
      //
      onChange={handleChange} labelKey={(option) => option.name.common}
      clearButton={true}
      renderMenuItemChildren={(option) => <div className="row">
        <div className="col col-12">
          <div className="d-flex justify-contents-center">
            <div className={classnames("me-2 fi fis fi-" + option.cca2.toLowerCase())}></div>
            <span>{option.name.common}</span>
          </div>
        </div>
        <div className="col col-12"><small>{option.cca3 + " - " + option.timezones[0]}</small></div>
      </div>} highlightOnlyResult={true} minLength={1} options={data || []} placeholder={props.placeholder
        ? props.placeholder
        : props.label} selected={countries ||[]}/> {
      props.label
        ? <label className={classnames("form-label text-truncate w-100", {"form-label-prependicon": true})} htmlFor={props.id}>
            {props.label}<FontAwesomeIcon icon="sun" className="ms-1 text-danger" hidden={!props.required}/></label>
        : ""
    }
  </div>
}

export default React.memo(Country);
