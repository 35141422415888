import React from 'react'
import {
  PieChart,
  Pie,
  Sector,
  Cell,
  Legend,
  Tooltip,
  ResponsiveContainer
} from 'recharts';

const NuagikePie = ({
  data,
  innerRadius,
  outerRadius,
  fill,
  startAngle,
  endAngle
}) => {

  const [activeIndex, setActiveIndex] = React.useState(0);

  const DEFAULT_COLOR = "var(--bs-primary)";

  const COLORS = ["var(--bs-blue-1)", "var(--bs-orange-1)", "var(--bs-cyan-1)", "var(--bs-green-1)"];

  const onPieEnter = (_, index) => {
    setActiveIndex(index)
  };

  const renderActiveShape = (props) => {
    const RADIAN = Math.PI / 180;
    const {
      cx,
      cy,
      midAngle,
      innerRadius,
      outerRadius,
      startAngle,
      endAngle,
      fill,
      payload,
      percent,
      value,
      index
    } = props;
    const sin = Math.sin(-RADIAN * midAngle);
    const cos = Math.cos(-RADIAN * midAngle);
    const sx = cx + (outerRadius + 10) * cos;
    const sy = cy + (outerRadius + 10) * sin;
    const mx = cx + (outerRadius + 30) * cos;
    const my = cy + (outerRadius + 30) * sin;
    const ex = mx + (
      cos >= 0
        ? 1
        : -1
    ) * 22;
    const ey = my;
    const textAnchor = cos >= 0
      ? 'start'
      : 'end';

    return (
      <g>
        <text x={cx} y={cy} dy={8} textAnchor="middle" fill={"#0d1b2a"}>
          {payload.name}
        </text>
        <Sector cx={cx} cy={cy} innerRadius={innerRadius} outerRadius={outerRadius} startAngle={startAngle} endAngle={endAngle} fill={fill}/>
        <Sector cx={cx} cy={cy} startAngle={startAngle} endAngle={endAngle} innerRadius={outerRadius + 6} outerRadius={outerRadius + 10} fill={fill}/>
        <path d={`M${sx},${sy}L${mx},${my}L${ex},${ey}`} stroke={COLORS[value % COLORS.length]} fill="none"/>
        <circle cx={ex} cy={ey} r={2} fill={fill} stroke="none"/>
        <text x={ex + (
            cos >= 0
              ? 1
              : -1
          ) * 12} y={ey} textAnchor={textAnchor} fill="#0d1b2a">{payload.name + ` (${value})`}</text>
        <text x={ex + (
            cos >= 0
              ? 1
              : -1
          ) * 12} y={ey} dy={18} textAnchor={textAnchor} fill="#9da5b1">
          {`${ (percent * 100).toFixed(0)}%`}
        </text>
      </g>
    );
  };

  return <React.Suspense fallback={<div> Loading ...</div>}>
    <ResponsiveContainer width="100%" height="100%">
      <PieChart>
        <Pie activeIndex={activeIndex} activeShape={renderActiveShape} data={data} cx="50%" cy="50%" innerRadius={innerRadius || 50}
          ////
          outerRadius={outerRadius || 60} dataKey="value" onMouseEnter={onPieEnter}>
          {data.map((entry, index) => (<Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]}/>))}
        </Pie>
        <Legend/>
        <Tooltip/>
      </PieChart>
    </ResponsiveContainer>
  </React.Suspense>

}

export default NuagikePie;
