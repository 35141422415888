import React, {useState} from 'react'
import classnames from 'classnames';
import {Link} from 'react-router-dom'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {OverlayTrigger, Overlay, Tooltip} from 'react-bootstrap';
import Moment from 'moment';
import CountryBadge from "./country"
import TimezoneBadge from "./timezone"
import {Button, useCopyToClipboard} from "src/components/nuagike/index"
import PrependBadge from "./prepend"
import DateBadge from "./date"
import Price from "./price"
import Number from "./number"
import LinkBadge from "./link"

import "./index.scss"

const DefaultBadge = (props) => {

  const [clipboard, setClipboard] = useCopyToClipboard()

  return <div className={classnames("d-flex align-items-center p-0 m-0 text-truncate w-100 rounded", {
      ["d-none"]: !props.text && !props.value
    })}>
    <div
      className={classnames("badge nuagike-badge rounded d-flex align-items-center",
      ////
      "m-0 p-0 p-0 px-1 text-truncate  w-100 ",
      //
      {
        ["text-nowrap"]: !props.wrap,
        ["text-wrap"]: props.wrap
      }, {
        ["size-" + (
            props.size || "small"
          )]: true
      }, {
        ["nuagike-badge-clickable"]: props.onClick
      }, {
        ["border rounded border-1 border-light"]: !props.hideBorder
      }, {
        ["bg-" + props.bg]: props.bg
      }, {
        ["text-" + props.color]: props.color
      }, {
        ["d-none"]: props.hidden
      }, {
        ["nuagike-badge-copy"]: props.copy
      }, {
        ["nuagike-badge-copied"]: props.varvalue && props.clipboard === props.varvalue
      }, props.className)}
      onClick={props.onClick}>
      <div className={classnames("d-flex align-items-center  ", {
        "d-none": props.hideIcon || !props.icon
      })}>
        <FontAwesomeIcon
          icon={props.icon
            ? props.icon
            : "tag"}
          size={props.iconSize
            ? props.iconSize
            : "1x"}
          className={classnames("fa-fw ", {
            ["me-1"]: props.text
          }, {
            ["text-" + props.iconColor]: props.iconColor,
            ["text-" + props.color]: props.color && !props.iconColor,
            ["opacity-" + props.iconOpacity]: props.iconOpacity,
            ["opacity-50"]: !props.iconOpacity
          })}/>
      </div>
      <div className="d-flex align-items-center  ps-0">
        <PrependBadge icon={props.prependIcon} text={props.prependText}/>
      </div>{
        props.link
          ? <Link
              to={props.link}
              className={classnames("d-flex align-items-center pe-2 text-truncate text-decoration-none user-select-all ", {
                ["bg-" + props.bg]: props.bg
              }, {
                ["text-" + props.color]: props.color
              })}>{props.text}</Link>
          : <div className="p-0 d-flex align-items-center text-truncate">
              <span className="d-block text-truncate p-1 align-items-center">{props.text}</span>
            </div>
      }
      {
        props.value !== null
          ? <span className=" fw-bold pe-1 d-inline-block align-items-center flex-grow-1 text-truncate text-end">{props.value}</span>
          : ""
      }

      {
        props.copyclipboard
          ? <div className="nuagike-badge-copyclipboard top-0 end-0 ">
              <div className=" d-flex align-items-center opacity-100" onClick={(e) => {
                  event.stopPropagation();
                }}>

                <Button
                  type="icon"
                  icon={clipboard === props.text || clipboard === props.value || clipboard === props.clipboard
                    ? "check"
                    : "clipboard"}
                  className={classnames(" ", {["clipboard-copied"]: clipboard})}
                  action={(e) => {
                    setClipboard(props.clipboard || props.value || props.text);
                    e.stopPropagation();
                  }}/>

              </div>
            </div>
          : ""
      }

    </div>
  </div>

}

export default DefaultBadge;
