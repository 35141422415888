import React from 'react'
import Modal from "react-bootstrap/Modal";
import classnames from 'classnames';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {Button, useLoading} from "src/components/nuagike/index"

import "./index.scss"

const NuagikeModal = (props) => {
  const loading = useLoading()
  return <Modal className={classnames("nuagike-modal", {["modal-content-100"] : props.h100}, props.className)}
  show={props.show}
  centered={true}
  scrollable={true}
  onHide={props.onClose}
  size={props.size
      ? props.size
      : "xl"}>
    <Modal.Header className={classnames({
        ["bg-" + (
            props.color
            ? props.color
            : "secondary")]: true
      })}>
      <Modal.Title className="text-light"><FontAwesomeIcon icon={props.icon
      ? props.icon
      : "tag"} className="me-2"/>{props.title}</Modal.Title>
    </Modal.Header>
    <Modal.Body className="overflow-visible w-100 ">

      <div className="row w-100 text-dark float-start m-0 p-0  mb-1">

        {props.children}

      </div>

    </Modal.Body>
    <Modal.Footer className={classnames({
        "d-none": (!props.onClose && !props.onSave || props.hideButtons)
      })}>

      <Button type="icon" className="me-1" size="2x"
      icon="save" color="success" action={() => props.onSave()}
      hidden={!props.onSave}
      loading={props.loading || false}
      disabled={loading || props.dirty==false}/>

      <Button type="icon" icon="window-close" size="2x" color="danger-30" action={() => props.onClose()}
      data-bs-dismiss="modal" hidden={!props.onClose} disabled={loading || false}/>


    </Modal.Footer>
  </Modal>
}

export default React.memo(NuagikeModal);
