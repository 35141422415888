import React from 'react'
import {useDrag, useDrop} from 'react-dnd'
import classnames from "classnames"
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'

const DND_ITEM_TYPE = 'row'

const DraggableRow = ({
  row,
  index,
  moveRow,
  saveMoveRow,
  errors,
  ...rowProps
}) => {
  const dropRef = React.useRef(null)
  const dragRef = React.useRef(null)

  const [, drop] = useDrop({
    accept: DND_ITEM_TYPE,
    canDrop(item, monitor) {
      return row && row.original && row.original.ref !== "new"
    },
    hover(item, monitor) {
      if (!dropRef.current) {
        return
      }
      const dragIndex = item.index
      const hoverIndex = index
      // Don't replace items with themselves
      if (dragIndex === hoverIndex) {
        return
      }
      // Determine rectangle on screen
      const hoverBoundingRect = dropRef.current.getBoundingClientRect()
      // Get vertical middle
      const hoverMiddleY = (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2
      // Determine mouse position
      const clientOffset = monitor.getClientOffset()
      // Get pixels to the top
      const hoverClientY = clientOffset.y - hoverBoundingRect.top
      // Only perform the move when the mouse has crossed half of the items height
      // When dragging downwards, only move when the cursor is below 50%
      // When dragging upwards, only move when the cursor is above 50%
      // Dragging downwards
      if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
        return
      }
      // Dragging upwards
      if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
        return
      }
      // Time to actually perform the action
      //console.log(monitor);

      moveRow(dragIndex, hoverIndex)

      // Note: we're mutating the monitor item here!
      // Generally it's better to avoid mutations,
      // but it's good here for the sake of performance
      // to avoid expensive index searches.
      item.index = hoverIndex
    },
    drop(item, monitor) {
      saveMoveRow(item.index)
    }
  })

  const [
    {
      isDragging
    }, drag, preview] = useDrag({
    type: DND_ITEM_TYPE,
    item: {
      index
    },
    canDrag(monitor) {
      return row && row.original && row.original.ref !== "new"
    },
    collect: monitor => ({isDragging: monitor.isDragging()})
  })

  const opacity = isDragging
    ? .5
    : 1

  preview(drop(dropRef))
  drag(dragRef)

  let trClassNames = isDragging
    ? "text-primary border border-1 border-primary shadow"
    : "";

  let tdClassNames = errors && errors.includes(row.original.ref)
    ? " border-start border-start-2 border-start-danger "
    : "";

  return (<tr ref={dropRef} className={trClassNames} style={{
      opacity
    }} {...rowProps}>
    <td ref={dragRef} className={classnames(tdClassNames, "text-center")}>
      <FontAwesomeIcon icon="th-list" className="icon-drag text-grise-3 "/>
    </td>
    {
      row.cells.map((cell, cell_idx) => {
        return <td key={"cell_"+cell_idx} {...cell.getCellProps([
  {
    className: cell.column.className
  }
])}>{cell.render('Cell')}</td>
      })
    }
  </tr>)
}

export default DraggableRow
