import React from 'react'
import {
  takeEvery,
  takeLatest,
  call,
  put,
  all,
  select
} from "redux-saga/effects";
import {post, get} from 'src/tools/index'
import config from "src/config/index"
import {toast} from 'react-toastify';
import {translate} from "src/components/nuagike/index"
import initialState from "src/redux/initialState"
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import classnames from "classnames";

import {

  loadConfiguration,
  loadConfigurationSuccess,
  loadConfigurationError,

  login,
  loginSuccess,
  loginError,
  logout,

  successMessage,
  errorMessage,

  confirm,
  confirmDone,

  loadRepository,
  loadRepositorySuccess
} from 'src/redux/actions'

export default function* watcherDefaultSaga() {

  yield takeEvery(confirmDone, workerConfirmDone);

  yield takeEvery(successMessage, workerSuccessMessage);
  yield takeEvery(errorMessage, workerErrorMessage);

  yield takeEvery(login, workerLogin);

  yield takeEvery(logout, workerLogout);

  yield takeEvery(loadConfiguration, workerSagaLoadConfiguration);

  yield takeEvery(loadRepository, workerLoadRepository);
}

function* workerConfirmDone(action) {}

function* workerLogin(action) {

  let user = Object.assign({}, initialState.user);

  try {
    let userDetails = yield call(get, config.API_IAM_BASE_URL + "/api/iam-core/users/" + action.payload.principal);
    let nonNullAttributes = Object.fromEntries(Object.entries(userDetails).filter(([_, v]) => v !== null));

    user = {
      ...user,
      ...nonNullAttributes
    };

  } catch (e) {
    yield put(loginError({error: e}));
  }

  try {
    let org = yield call(get, config.API_EVT_BASE_URL + "/api/evt/organization");
    let nonNullAttributes = Object.fromEntries(Object.entries(org).filter(([_, v]) => v !== null));
    user = {
      ...user,
      organization: {
        ...user.organization,
        ...nonNullAttributes
      }
    };
  } catch (e) {
    yield put(loginError({error: e}));
  }

  yield put(loginSuccess({user: user}));
}

function* workerLogout(action) {
  yield localStorage.removeItem(config.USER_PRINCIPAL);
  yield localStorage.removeItem(config.REFRESH_TOKEN);
  yield localStorage.removeItem(config.ACCESS_TOKEN);
  window.keycloak.logout();
}

function* workerLoadRepository(action) {
  try {
    let repoName = action.payload;
    let repository = yield call(get, config.API_EVT_BASE_URL + "/api/evt/repo/" + repoName);
    yield put(loadRepositorySuccess({repository: repository, name: repoName}));
  } catch (e) {
    yield put({type: "LOAD_REPOSITORY_ERROR", payload: e});
  }
}

function* workerSagaLoadConfiguration(action) {

  let configuration = Object.assign({}, initialState.config);

  try {
    if (localStorage.getItem("nuagike.enums") && config.LOCAL_STORAGE_CACHE_ENABLED) {
      configuration.enums = JSON.parse(localStorage.getItem("nuagike.enums"));
    } else {
      configuration.enums = yield call(get, config.API_EVT_BASE_URL + "/api/evt/enums", false);
      localStorage.setItem("nuagike.enums", JSON.stringify(configuration.enums));
    }
  } catch (e) {
    yield put(loadConfigurationError({error: e}));
  }

  try {
    if (localStorage.getItem("nuagike.public") && config.LOCAL_STORAGE_CACHE_ENABLED) {
      configuration.public = JSON.parse(localStorage.getItem("nuagike.public"));
    } else {
      configuration.public = yield call(get, config.API_EVT_BASE_URL + "/api/evt/config", false);
      configuration.public.forms = yield call(get, config.API_CMS_BASE_URL + "/api/cms/config", false);

      localStorage.setItem("nuagike.public", JSON.stringify(configuration.public));
    }
  } catch (e) {
    yield put(loadConfigurationError({error: e}));
  }

  try {
    let doGetWordings = false;

    if (config.LOCAL_STORAGE_CACHE_ENABLED && localStorage.getItem("nuagike.wordings")) {
      configuration.wordings = JSON.parse(localStorage.getItem("nuagike.wordings"));
      if(!configuration.wordings.fr || !Object.keys(configuration.wordings.fr).length){
        doGetWordings =true;
      }
    }else{
      doGetWordings =true;
    }

    if(doGetWordings){
      configuration.wordings = yield call(get, config.API_CMS_BASE_URL + "/api/cms/wordings");
      localStorage.setItem("nuagike.wordings", JSON.stringify(configuration.wordings));
    }

  } catch (e) {
    yield put(loadConfigurationError({error: e}));
  }

  try {
    if (localStorage.getItem("nuagike.repositories") && config.LOCAL_STORAGE_CACHE_ENABLED) {
      configuration.repositories = JSON.parse(localStorage.getItem("nuagike.repositories"));
    } else {
      configuration.repositories = {}
      configuration.repositories.filetypes = yield call(get, config.API_EVT_BASE_URL + "/api/evt/repositories/filetypes", false);

      let all_countries = yield call(get, config.API_EVT_BASE_URL + "/api/evt/repositories/countries", false);

      if (all_countries) {
        let tempCurrencies = [];
        configuration.repositories.countries = all_countries.map(country => {

          let countryCurrencies = country.currencies;
          countryCurrencies && Object.keys(countryCurrencies).map(cr => {
            let currency = {}
            currency.id = cr;
            currency.country = country.name.common;
            currency.cca2 = country.cca2
            currency.name = countryCurrencies[cr].name;
            currency.symbol = countryCurrencies[cr].symbol;

            tempCurrencies.push(currency)
          });

          return {
            'name': {
              'common': country.name.common
            },
            'cca2': country.cca2,
            'cca3': country.cca3,
            'timezones': country.timezones,
            currencies: country.currencies
          };
        });

        configuration.repositories.currencies = tempCurrencies;
        localStorage.setItem("nuagike.repositories", JSON.stringify(configuration.repositories));
      }

    }
  } catch (e) {
    yield put(loadConfigurationError({error: e}));
  }

  yield put(loadConfigurationSuccess({config: configuration}));
}

function* workerSuccessMessage(action) {

  const message = action.payload.message
    ? action.payload.message
    : "text.admin.info";
  const details = action.payload.ref || "";

  yield toast.error(<Msg error = {
    false
  }
  message1 = {
    message
  }
  message2 = {
    details
  } />, {
    position: "bottom-center",
    autoClose: 1000,
    hideProgressBar: true,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: false,
    progress: undefined,
    icon: false
  });
}

function* workerErrorMessage(action) {

  const message = action.payload.error && action.payload.error.title
    ? action.payload.error.title
    : "text.admin.error";
  const details = action.payload.error && action.payload.error.detail
    ? action.payload.error.detail
    : action.payload.error && action.payload.error.type
      ? action.payload.error.type
      : action.payload.message;

  yield toast.error(<Msg error = {
    true
  }
  message1 = {
    message
  }
  message2 = {
    details || ""
  } />, {
    position: "bottom-center",
    autoClose: 2000,
    hideProgressBar: true,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: false,
    progress: undefined,
    icon: false
  });
}

const Msg = ({error, closeToast, toastProps, message1, message2}) => <div className="row p-2 m-0 d-grid">
  <div className="col col-12 p-0 m-0 text-truncate">
    <h3 className={classnames("align-items-center text-break ps-0", {
        ["text-danger"]: error
      }, {
        ["text-success"]: !error
      })}>
      <FontAwesomeIcon icon={error
          ? "bug"
          : "check"} className="fa-fw m-0 me-1 p-0"/>
      <span className="flex-grow-1 text-wrap">
        {translate(message1, null, message1)}</span>
    </h3>
  </div>
  {
    message2
      ? <div className="col col-12 p-0 m-0 text-truncate">
          <p className="text-truncate text-wrap text-grise-3">
            {translate(message2, null, message2)}
          </p>
        </div>
      : ""
  }
</div>
