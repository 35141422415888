import React, {useState} from 'react'
import classnames from 'classnames';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {SketchPicker, ChromePicker, CompactPicker} from "react-color";

import {Button} from "src/components/nuagike/index"

const ColorPicker = ({color, onChange}) => {

  const [pickerColor, setPickerColor] = useState("#000");

  React.useEffect(() => {
    if (color) {
      setPickerColor(color)
    }
  }, [color]);

  const handleChangeColor = (color, e) =>{
    setPickerColor(color.hex);
    onChange && onChange(color)
  }

  return <div className="row bg-white" >
      <div className="col col-12">
        <ChromePicker color={pickerColor} onChange ={handleChangeColor} />
      </div>
      <div className="col col-12">
        <CompactPicker color={pickerColor} onChange ={handleChangeColor} />
      </div>
      <div className="col col-12 d-flex justify-content-end">
          <Button type="icon" icon="ban" action={()=>{onChange && onChange(null)}}/>
      </div>
    </div>
};

export default ColorPicker;
