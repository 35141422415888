{/*
 Pagination can be built however you'd like.
 This is just a very basic UI implementation:
*/
}
import React from "react";
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
export default function Pagination({
  previousPage,
  nextPage,
  canPreviousPage,
  canNextPage,
  gotoPage,
  pageOptions,
  setPageSize,
  hidden,
  pageIndex,
  pageSize,
  updatePagination,
  loading = false,
  count
}) {


  return count ? <div className="row justify-content-between p-0 m-0 pagination ">
    <div className="col p-0 m-0 col-12 col-lg-6 d-flex align-items-center float-start py-1 ">
      <span className="badge border-0 text-info">
        {count + " records"}
      </span>
    </div>
    {
      count && count > pageSize
        ? <div className="col p-0 m-0 col-12 col-lg-6 d-flex justify-content-end py-1">

            <div className="row p-0 m-0 d-flex justify-content-center border border-light border-2 rounded bg-light-90">

              <div className="col p-0 m-0 d-flex align-items-center flex-row-reverse justify-content-center h-100">

                <button id="pagination-previous" className="p-1 border-0 h-100 rounded" disabled={!canPreviousPage || loading} onClick={(e) => {
                    previousPage();
                    updatePagination && updatePagination({index: pageIndex-1, size: pageSize});
                  }}>
                  <FontAwesomeIcon icon="angle-left" className="text-info fa-fw"/>
                </button>
              </div>
              <div className="col p-0 m-0 d-flex align-items-center justify-content-center h-100">
                <div className="d-flex align-items-center justify-content-center h-100">
                  <small className="d-flex align-items-center text-grise-2 text-end h-100 px-2 h-100">
                    Page
                  </small>

                  <input disabled={loading} type="number" className="form-control p-0 text-center box-shadow-none border-0 h-100 bg-light-90" value={pageIndex + 1} onChange={e => {
                      const page = e.target.value
                        ? Number(e.target.value)
                        : 0
                      gotoPage(page - 1)
                    }}/>
                  <small className="d-flex align-items-center pe-3 text-grise-3 h-100">/{pageOptions.length}</small>
                  {pageSize && pageSize >5 ?
                  <span className="d-flex align-items-center">
                    <select disabled={loading} className="form-control m-0 border-0 h-100 text-info" value={pageSize} onChange={e => {
                        setPageSize(Number(e.target.value));
                        updatePagination && updatePagination({index: 0, size: Number(e.target.value)});
                      }}>
                      {
                        [
                          10,
                          20,
                          30,
                          40,
                          50,
                          100
                        ].map(pageSize => (<option key={pageSize} value={pageSize} className="text-info">
                          {pageSize + " records"}
                        </option>))
                      }
                    </select>
                  </span> : ""}

                </div>
              </div>
              <div className="col p-0 m-0 d-flex align-items-center justify-content-center h-100">
                <button id="pagination-next" className="m-0 p-1 border-0 h-100 rounded" disabled={!canNextPage || loading} onClick={(e) => {
                    nextPage();
                    updatePagination && updatePagination({index: pageIndex+1, size: pageSize});
                  }}>
                  <FontAwesomeIcon icon="angle-right" className="text-info fa-fw"/>
                </button>
              </div>

            </div>

          </div>
        : ""
    }

  </div> : ""

}
