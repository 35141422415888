import React from 'react'
import {OverlayTrigger, Overlay, Tooltip} from 'react-bootstrap';

const NuagikeTooltip = ({
  icon,
  text,
  ...props
}) => {

  return <OverlayTrigger className="d-flex align-items-center text-truncate"
    ////
    placement="top" overlay={//
      <Tooltip>{text}</Tooltip>}>
    {props.children}
  </OverlayTrigger>
}

export default NuagikeTooltip;
