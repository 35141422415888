export function getEnumIcon(enumName, value, defaultIcon = "tag") {
  let icon = defaultIcon;

  if (enumName === "venuetypes") {
    icon = "hotel"
  } else if (enumName === "mainstatus") {
    icon = getMainStatusIcon(value);
  } else if (enumName === "eventtypes") {
    icon = getEventTypeIcon(value);
  } else if (enumName === "fieldtypes") {
    if (value) {
      if (["DROPDOWN", "CHECKBOXES", "RADIOBUTTONS", "AUTOCOMPLETE"].some(substr => value.includes(substr))) {
        icon = "list"
      } else if (["PHOTO"].some(substr => value.includes(substr))) {
        icon = "file-image";
      } else if (["FILE"].some(substr => value.includes(substr))) {
        icon = "file-alt";
      } else if (["TEXTAREA"].some(substr => value.includes(substr))) {
        icon = "paragraph";
      } else if (["EMAIL"].some(substr => value.includes(substr))) {
        icon = "at";
      } else if (["DATE", "TIME"].some(substr => value.includes(substr))) {
        icon = "calendar-alt";
      } else if (["NUMBER"].some(substr => value.includes(substr))) {
        icon = "infinity";
      } else if (["COUNTRY"].some(substr => value.includes(substr))) {
        icon = "globe-africa";
      } else {
        icon = "font";
      }
    } else {
      icon = "font";
    }

  } else if (enumName === "paymentstatus") {
    if (value === "PAYMENT_ACCEPTED") {
      icon = "check"
    } else {
      icon = "comments-dollar";
    }
  } else if (enumName === "paymentorigins") {
    if (value === "STRIPE") {
      icon = {
        "prefix": "fab",
        "iconName": "stripe-s"
      };
    } else if (value === "ADMIN") {
      icon = "user-cog";
    }
  } else if (enumName === "subscriptionstatus") {
    if (value && value.includes("VALIDATED")) {
      icon = "check";
    } else if (value && value.includes("REQUIRE")) {
      icon = "exclamation-circle";
    } else if (value && value === "CANCELED" || value === "REJECTED") {
      icon = "times-circle";
    }
  } else if (enumName === "applicationnames") {
    if (value && value.includes("evt")) {
      icon = "calendar";
    } else if (value && value.includes("doc")) {
      icon = "file-alt";
    } else if (value && value.includes("cms")) {
      icon = "folder";
    } else if (value && value.includes("tkt")) {
      icon = "ticket-alt";
    } else if (value && value.includes("mkt")) {
      icon = "cubes";
    }
  } else if (enumName === "orderstatus") {
    if (value == "DRAFT") {
      icon = {
        "prefix": "fab",
        "iconName": "firstdraft"
      };
    } else if (value === "REGISTERED") {
      icon = "shopping-cart";
    } else if (value === "CONFIRMED") {
      icon = "check";
    } else if (value === "DELIVERED") {
      icon = "paper-plane";
    } else if (value === "CANCELED") {
      icon = "ban";
    } else if (value === "CLOSED") {
      icon = "stop";
    } else if (value === "EXPIRED") {
      icon = "hourglass-end";
    } else if (value === "RETURNED") {
      icon = "stop-circle";
    }
  } else if (enumName === "paymentmodes") {
    if (value && value.includes("CASH")) {
      icon = "money-bill";
    } else if (value && value.includes("CHEQUE")) {
      icon = "money-check";
    } else if (value && value.includes("BANK_TRANSFER")) {
      icon = "university";
    } else if (value && value.includes("CREDIT_CARD")) {
      icon = "credit-card";
    }
  } else if (enumName === "paymenttypes") {
    if (value && value.includes("PERSONAL_ON_CHECKIN")) {
      icon = "cash-register";
    } else if (value && value.includes("PERSONAL_ON_CHECKOUT")) {
      icon = "cash-register";
    } else if (value && value.includes("ORGANIZATION_SUPPORT")) {
      icon = "university";
    }
  } else if (enumName === "subscriptionorigins") {
    if (value === "ADMIN") {
      icon = "user-cog";
    } else if (value === "FORM") {
      icon = {
        "prefix": "fab",
        "iconName": "wpforms"
      };
    } else if (value === "IMPORT") {
      icon = "cloud-download-alt";
    }
  } else if (enumName === "subscriptionroles") {
    //ATTENDEE, APPLICANT, COMMITEE, EXHIBITOR, SPONSOR, PARTNER, GUEST

    icon = "user-astronaut";

  } else if (enumName === "paperstatus") {
    //ATTENDEE, APPLICANT, COMMITEE, EXHIBITOR, SPONSOR, PARTNER, GUEST

    icon = "archive";

  } else if (enumName === "reviewstatus") {
    //ATTENDEE, APPLICANT, COMMITEE, EXHIBITOR, SPONSOR, PARTNER, GUEST

    icon = "check-double";

  }
  return icon;
}

export function getCFPStatusIcon(status) {
  return getMainStatusIcon(status);
}
export function getEventStatusIcon(status) {
  return getMainStatusIcon(status);
}
export function getMainStatusIcon(status) {
  //	OPENED, SUSPENDED, POSTPONED, CLOSED, CANCELED
  if (status === "OPENED") {
    return "play"
  } else if (status === "SUSPENDED") {
    return "pause"
  } else if (status === "SCHEDULED") {
    return "stopwatch"
  } else if (status === "REPORTED") {
    return "forward"
  } else if (status === "CLOSED") {
    return "power-off"
  } else if (status === "CANCELED") {
    return "ban"
  }

  return "pencil-ruler"
}

export function getEventTypeIcon(type) {
  switch (type) {
    case 'CONFERENCE':
      return 'calendar-week'
    case 'RACE':
      return 'flag-checkered'
    case 'FUNDRAISER':
      return 'donate'
    case 'CEREMONY':
      return 'glass-cheers'
    case 'FESTIVAL':
      return 'guitar'
    case 'VIRTUAL':
      return 'headset'
    case 'BUSINESS':
      return 'handshake'
    default:
      return 'star'
  }
}
