const commonConfiguration = {
  COLOR: 1,
  API_VERSION: "vnd.nuagike.api.v1.0",
  API_EVT_BASE_URL: 'http://localhost:9100',
  API_DOC_BASE_URL: 'http://localhost:9103',
  API_CMS_BASE_URL: 'http://localhost:9101',
  API_TKT_BASE_URL: 'http://localhost:9102',
  API_MKT_BASE_URL: 'http://localhost:9104',
  API_LOG_BASE_URL: 'http://localhost:9105',
  API_BAT_BASE_URL: 'http://localhost:9106',
  API_OMC_BASE_URL: 'http://localhost:9107',
  API_IAM_BASE_URL: 'http://localhost:9108',

  PUBLIC_SITE_URL : "https://admin.nuagike.com",

  KEYCLOAK: {
    MODE: 'OIDC', // or OIDC
    CLIENT_ID: 'nuagike_events',
    REALM: 'nuagike',
    URL: 'https://authv2.nuagike.com',
    TOKEN_URI: 'https://authv2.nuagike.com/realms/nuagike/protocol/openid-connect/token',
    USERINFO_URI: 'https://authv2.nuagike.com/realms/nuagike/protocol/openid-connect/userinfo'
  },
  HOME: '/',
  ACCESS_TOKEN: "nuagike.atoken",
  REFRESH_TOKEN: "nuagike.rtoken",
  USER_PRINCIPAL: "nuagike.puser",
  REGEX: {
    email: /\S+@\S+\.\S+/,
    phone: /^([+]?[\s0-9]+)?(\d{3}|[(]?[0-9]+[)])?([-]?[\s]?[0-9])+$/i,
    postal: /[0-9]{4,8}$/
  },
  MESSAGES: {},
  LOCAL_STORAGE_CACHE_ENABLED: true,
  MAX_FILE_UPLOAD_SIZE: 5838334
}

if (process.env.REACT_APP_NODE_ENV === 'development') {
  Object.assign(commonConfiguration, {
    LOCAL_STORAGE_CACHE_ENABLED: true,
    API_EVT_BASE_URL: 'https://evt.nuagike.com',
    API_DOC_BASE_URL: 'https://doc.nuagike.com',
    API_CMS_BASE_URL: 'https://cms.nuagike.com',
    API_TKT_BASE_URL: 'https://tkt.nuagike.com',
    API_LOG_BASE_URL: 'https://log.nuagike.com',
    //API_MKT_BASE_URL: 'https://local.nuagike.com',
    API_MKT_BASE_URL: 'https://mkt.nuagike.com',
    API_BAT_BASE_URL: 'https://bat.nuagike.com',
    API_OMC_BASE_URL: 'https://omc.nuagike.com',
    API_IAM_BASE_URL: 'https://iam.nuagike.com'
  });
}

if (process.env.REACT_APP_NODE_ENV === 'production') {

  Object.assign(commonConfiguration, {
    PUBLIC_SITE_URL : "https://admin.nuagike.com",
    LOCAL_STORAGE_CACHE_ENABLED: true,
    API_EVT_BASE_URL: 'https://evt.nuagike.com',
    API_DOC_BASE_URL: 'https://doc.nuagike.com',
    API_CMS_BASE_URL: 'https://cms.nuagike.com',
    API_TKT_BASE_URL: 'https://tkt.nuagike.com',
    API_MKT_BASE_URL: 'https://mkt.nuagike.com',
    API_LOG_BASE_URL: 'https://log.nuagike.com',
    API_BAT_BASE_URL: 'https://bat.nuagike.com',
    API_OMC_BASE_URL: 'https://omc.nuagike.com',
    API_IAM_BASE_URL: 'https://iam.nuagike.com'
  });
}
export default commonConfiguration;
