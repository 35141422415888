import React from 'react'
import {
  RadialBarChart,
  RadialBar,
  Cell,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  LabelList,
  Label,
  ResponsiveContainer
} from 'recharts';

const NuagikeRadial = ({data, bars}) => {

  const DEFAULT_COLOR = "#c4c9d0";

  const COLORS = ['#0088FE', '#00C49F', '#FFBB28', '#FF8042'];

  const renderCustomizedLabel = (props) => {
    const {
      content,
      ...rest
    } = props;

    return <Label {...rest} fontSize="12" fill="#FFFFFF" fontWeight="Bold"/>;
  };

  React.useEffect(() => {}, [data]);

  return <ResponsiveContainer width="100%" height="100%">
    <RadialBarChart width={730} height={250} innerRadius="10%" outerRadius="80%" data={data} startAngle={180} endAngle={0}>
      <RadialBar minAngle={15} label={{
          fill: '#666',
          position: 'insideStart'
        }} background="background" clockWise={true} dataKey='uv'/>
      <Legend iconSize={10} width={120} height={140} layout='vertical' verticalAlign='middle' align="right"/>
      <Tooltip/>
    </RadialBarChart>
  </ResponsiveContainer>

}

export default NuagikeRadial;
