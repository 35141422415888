import React, {useState} from 'react'
import classnames from 'classnames';

import "./index.scss"


const Progress = ({max, value, label}) => {

  const [width, setWidth] = React.useState(0);

  React.useEffect(() => {
    if (max && value) {
      setWidth(Number(value * 100 / max))
    } else {
      setWidth(0)
    }
  }, [max, value]);

  return <div className="progress">
    <div className="progress-bar" role="progressbar" style={{
        width: width + '%'
      }} aria-valuenow={value} aria-valuemin="0" aria-valuemax={max}>{label}</div>
  </div>
}

export default Progress;
