import React from 'react'
import {useSelector, useDispatch} from 'react-redux'
import {
  logout as logout_action,
  loadResource,
  loadResources,
  cloneResource,
  lockResource,
  searchResources,
  replaceResources,
  updateResource,
  addResource,
  selectRef,
  selectResource,
  selectResources,
  clearResources,
  removeResource,
  successMessage,
  errorMessage
} from "src/redux/actions/index"
import resources_config from "src/config/resources"

function useResource(type, on = true) {

  const dispatch = useDispatch();

  const current = on
    ? useSelector((state) => {
      if (state.resources && state.resources[type]) {
        return state.resources[type].current;
      } else {
        const resource_config = resources_config[type];

        if (resource_config.store.type === "override_parent") {
          const parent_resource_type = resource_config.parent;
          if (parent_resource_type && state.resources[parent_resource_type]) {
            return state.resources[parent_resource_type].current
          }
        }
        return {}
      }
    })
    : null;

  const lastSearchDate = on
    ? useSelector((state) => {
      return state.resources[type] && state.resources[type].data && state.resources[type].data.date
        ? state.resources[type].data.date
        : null
    })
    : null;

  const data = on
    ? useSelector((state) => {
      return state.resources[type] && state.resources[type].data && Array.isArray(state.resources[type].data.results)
        ? state.resources[type].data.results
        : (
          (state.resources[type] && state.resources[type].data)
            ? state.resources[type].data
            : []
        )
    })
    : null;

  const page = on
    ? useSelector((state) => {
      return state.resources[type] && state.resources[type].data
        ? state.resources[type].data
        : null
    })
    : null;

  const count = on
    ? useSelector((state) => {
      return state.resources[type] && state.resources[type].data && state.resources[type].data.count !== null
        ? state.resources[type].data.count
        : 0
    })
    : null;

  function clear(callback) {
    dispatch(clearResources({type: type, callback: callback}));
  }

  function select(object, callback) {
    dispatch(selectResource({type: type, resource: object, ref: object.ref, callback: callback}));
  }

  function selectByRef(ref, callback) {
    dispatch(selectRef({type: type, ref: ref, callback: callback}));
  }

  function selectAll(objects, callback) {
    dispatch(selectResources({type: type, resources: objects, callback: callback}));
  }

  function load(ref, parentRef, callback) {
    dispatch(loadResource({type: type, ref: ref, parentRef: parentRef, callback: callback}));
  }

  function loadAll(parentRef, filter, callback) {
    dispatch(loadResources({type: type, parentRef: parentRef, filter: filter, callback: callback}));
  }

  function clone(ref, payload, parentRef, callback) {
    dispatch(cloneResource({type: type, ref: ref, data: payload, parentRef: parentRef, callback: callback}));
  }

  function update(ref, payload, parentRef, callback) {
    dispatch(updateResource({type: type, ref: ref, data: payload, parentRef: parentRef, callback: callback}));
  }

  function add(payload, parentRef, callback) {
    dispatch(addResource({type: type, data: payload, parentRef: parentRef, callback: callback}));
  }

  function remove(ref, password, parentRef, callback) {
    dispatch(removeResource({type: type, ref: ref, password: password, parentRef: parentRef, callback: callback}));
  }

  function search(payload, parentRef, callback) {
    dispatch(searchResources({type: type, search: payload, parentRef: parentRef, callback: callback}));
  }

  function replaceAll(data, parentRef, partialMode, callback) {
    dispatch(replaceResources({type: type, data: data, parentRef: parentRef, partialMode: partialMode, callback: callback}));
  }

  function lock(ref, payload, parentRef, callback) {
    dispatch(lockResource({type: type, ref: ref, parentRef: parentRef, data: payload, callback: callback}));
  }

  return {
    name: type,
    clear,
    select,
    selectByRef,
    selectAll,
    current,
    data,
    lastSearchDate,
    count,
    page,
    load,
    loadAll,
    search,
    clone,
    remove,
    update,
    add,
    replaceAll,
    lock
  };
}

function useLogout() {
  const dispatch = useDispatch();

  function logout() {
    dispatch(logout_action());
  }

  return {logout}
}

function useUser() {
  return useSelector((state) => state.user || {});
}

function useLoading() {
  return useSelector((state) => state.loading || false);
}

function useEnums(enum_name) {
  if (enum_name) {
    return useSelector((state) => state.config.enums[enum_name] || [])
  }
  return useSelector((state) => state.config.enums || []);
}

function useEnum() {
  const enums = useSelector((state) => state.config.enums);
  function fetch(enumName) {
    if (enums) {
      return enums[enumName];
    }
    return null;
  }
  return {fetch}
}

function useRepos(repo_name) {
  if (repo_name) {
    return useSelector((state) => state.config.repositories[repo_name] || [])
  }
  return useSelector((state) => state.config.repositories || {});
}

function useRepo() {
  const repos = useSelector((state) => state.config.repositories);
  const fetch = (repo_name) => {
    if (repos) {
      return repos[repo_name];
    }
    return null;
  }
  return {fetch}
}

function useLocale() {
  return useSelector((state) => state.config.locale);
}

function useConfig(config_name) {
  if (config_name) {
    return useSelector((state) => state.config.public[config_name] || {})
  }
  return useSelector((state) => state.config.public || {});
}

function useErrors(type) {
  let allErrors = useSelector((state) => state.errors.resources || {});

  function find(errorInDepth, field) {

    //console.log({errorInDepth, field});
    let errors = allErrors[type];

    if (errors) {
      if (errorInDepth) {
        errors = errors[errorInDepth]
      } else {
        return Object.keys(errors).length
      }
    }

    if (errors && field) {
      if (errors.violations) {
        const errorField = errors.violations.filter(v => v.field && v.field.includes(field));
        if (errorField) {
          return errorField[0]
        }
      }
      return false;
    }
    return errors;
  }

  return {find};
}

export {
  useLocale,
  useResource,
  useEnums,
  useEnum,
  useRepos,
  useRepo,
  useErrors,
  useUser,
  useLoading,
  useConfig,
  useLogout
}
