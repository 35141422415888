import React from 'react'
import Breadcrumb from 'react-bootstrap/Breadcrumb';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'


import "./index.scss"

const NuagikeBreadcrumb = ({ items }) => {
  return (
    <Breadcrumb className='nuagike_breadcrumb'>
      {items && items.map((item, _idx) => (
        <Breadcrumb.Item
          className={!item.href ? "nuagike_breadcrumb_leaf" : ""}
          href={item.href} itemType='span' key={"breadcrumb_" + _idx} active={item.active}>
          {item.icon && <FontAwesomeIcon icon={item.icon} className='me-1' />}{item.label}
        </Breadcrumb.Item>)
      )}
    </Breadcrumb>
  );
}

export default NuagikeBreadcrumb;