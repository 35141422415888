import React, {useState} from 'react'
import classnames from 'classnames';
import {useLoading} from "src/components/nuagike/index"
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {format} from "date-fns";

const NuagikeDate = ({
  id,
  name,
  value,
  onChange,
  placeholder,
  label,
  className,
  prependIcon,
  invalid,
  ...props
}) => {

  const loading = false;// useLoading()
  const [localValue, setLocalValue] = React.useState("");

  const [defaultLocalDateFormat, setDefaultLocalDateFormat] = React.useState("yyyy-MM-dd");
  const [defaultLocalDateTimeFormat, setDefaultLocalDateTimeFormat] = React.useState('yyyy-MM-dd\'T\'HH:mm:ssXXX');

  React.useEffect(() => {
    if (value) {
      let day = Date.parse(value, defaultLocalDateTimeFormat);
      let lv = format(new Date(value), defaultLocalDateFormat);
      setLocalValue(lv);
      //console.log({"value": value, "date": day});
    } else {
      setLocalValue("")
    }
  }, [value]);

  const handleChange = (value) => {
    if (value !== localValue) {
      let isoDate = format(new Date(value), defaultLocalDateTimeFormat, "UTC");
      onChange(name, isoDate)
    }
  }

  return <input maxLength="250" type="date" id={id} name={name
        ? name
        : id} className={classnames("form-control", {
        "rounded-0 rounded-end": prependIcon
      }, {
        "is-invalid": invalid
      }, className)}
      //
      value={localValue} placeholder={placeholder
        ? placeholder
        : label} onChange={(e) => handleChange(e.target.value)} disabled={props.disabled || loading}/>
}

export default NuagikeDate;
