import React from 'react'
import {useIntl} from 'react-intl'

const translate = (message, enumName, defaultMessage) => {

  const intl = useIntl();

  return enumName
    ? intl.formatMessage({
      id: "text.enums." + enumName + "." + message,
      defaultMessage: (defaultMessage || message)
    })
    : intl.formatMessage({
      id: "text.admin." + message,
      defaultMessage: defaultMessage
    })
}

export default translate;
