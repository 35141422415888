import React from "react";
import {Button, DropDown} from "src/components/nuagike/index"
import {useIntl} from 'react-intl';
const getActionsColumn = ({currentEdit, currentEditDirty, onSelect, onSave, onCancelEdit}) => {
  return {
    Header: "",
    id: "actions",
    accessor: "ref",
    action: true,
    width: '60px',
    Cell: ({value, row}) => {
      return <div className="d-flex justify-content-center">{
          !(currentEdit && currentEdit.ref)
            ? <Button type="icon" icon="edit" color="grise-1" action={() => onSelect(row.original.ref)}/>
            : (currentEdit && row.original.ref === currentEdit.ref)
              ? <div className="d-flex justify-contents-center">

                  <Button type="icon" icon="save" color="success" action={() => onSave(row.original.ref)} disabled={!currentEditDirty}/>
                  <Button type="icon" icon="times" color="danger" action={() => onCancelEdit()}/>
                </div>
              : ""
        }</div>
    }
  }
}

const getEditColumn = ({onEdit, onClone, onDelete, getCustomActions}) => {
  const intl = useIntl()
  return {
    id: "edit",
    Header: "",
    headerClassName: "text-center",
    accessor: "ref",
    action: true,
    Cell: ({value, row}) => {
      const actions = [];
      if (onEdit) {
        actions.push({
          title: intl.formatMessage({id: "text.admin.default.details"}),
          action: () => onEdit(row.original),
          icon: "book"
        })
      }

      if (onClone) {
        actions.push({
          title: intl.formatMessage({id: "text.admin.default.clone"}),
          action: () => onClone(row.original),
          icon: "clone"
        })
      }

      if (getCustomActions) {
        let customActions = getCustomActions(row);
        customActions && customActions.map(act=>{
          actions.push(act);
        });
      }


      if (onDelete) {
        actions.push({
          divider: true
        });
        actions.push({
          title: intl.formatMessage({id: "text.admin.default.delete"}),
          action: () => onDelete(row.original),
          className: "text-danger-30",
          icon: "trash"
        })
      }

      return <DropDown items={actions} className="" small={true}/>

    },
    width: '40px'
  }
}

export {
  getActionsColumn,
  getEditColumn
}
