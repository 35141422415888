import {configureStore} from '@reduxjs/toolkit';

import {applyMiddleware, compose} from "redux";

import createSagaMiddleware from "redux-saga";
import loggerMiddleware from './middleware/logger';

import reducer from "src/redux/reducers/index";
import sagas from "src/redux/sagas/index";

const initialiseSagaMiddleware = createSagaMiddleware();

const store = configureStore({
  reducer,
  //middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(initialiseSagaMiddleware).concat(loggerMiddleware)
  middleware: (getDefaultMiddleware) => getDefaultMiddleware({
    serializableCheck: {
      // Ignore these action types
      //ignoredActions: ['your/action/type'],
      // Ignore these field paths in all actions
      ignoredActionPaths: ['payload.data.formData', 'payload.callback', 'payload.action.callback'],
      // Ignore these paths in the state
      //ignoredPaths: ['items.dates']
    }
  }).concat(initialiseSagaMiddleware).concat(loggerMiddleware)
})

initialiseSagaMiddleware.run(sagas);

export default store;
