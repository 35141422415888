import React from 'react';
import {getEventStatusIcon, getEventTypeIcon} from "src/tools/icons"
import CloneEvent from "src/views/econf/events/config/generalinfos/modals/clone";
import ReportEvent from "src/views/econf/events/config/generalinfos/modals/report";
import CancelEvent from "src/views/econf/events/config/generalinfos/modals/cancel";
import SuspendEvent from "src/views/econf/events/config/generalinfos/modals/suspend";
import DeleteEvent from "src/views/econf/events/config/generalinfos/modals/delete";
import {useResource} from "../rest/resource";
import {useNavigate} from "react-router-dom"
import {useIntl} from 'react-intl';

const HandleEvent = () => {
  const intl = useIntl();
  const navigate = useNavigate();
  const eventStatusResource = useResource("event_status", false)
  const eventResource = useResource("event", false);

  const [action, setAction] = React.useState();

  const handleClose = () => {
    setAction(null)
  }

  const handleLockEvent = (evtRef) => {
    evtRef && eventResource.lock(evtRef, {ref: evtRef});
  }

  const handleCloneEvent = (cloned, evtRef) => {
    let payload = Object.assign({}, cloned)
    payload.eventRef = evtRef;
    eventResource.clone(evtRef, payload, null, (clonedEvt) => {
      navigate("/events/" + clonedEvt.ref);
      handleClose();
    });
  }

  const handleCancelEvent = (canceled, evtRef) => {
    let payload = Object.assign({}, canceled)
    //OPENED, SUSPENDED, POSTPONED, CLOSED, CANCELED
    payload.nextStatus = "CANCELED";
    payload.eventRef = evtRef;
    payload.emailRef = canceled.emailRef;
    eventStatusResource.replaceAll(payload, evtRef, null, () => {
      handleClose();
    });
  }

  const handleReportEvent = (postponed, evtRef) => {
    let payload = Object.assign({}, postponed)
    //OPENED, SUSPENDED, POSTPONED, CLOSED, CANCELED
    payload.nextStatus = "REPORTED";
    payload.eventRef = evtRef;
    payload.startDate = postponed.startDate;
    payload.endDate = postponed.endDate;
    payload.emailRef = postponed.emailRef;
    eventStatusResource.replaceAll(payload, evtRef, null, () => {
      handleClose();
    });
  }

  const handleDeleteEvent = (deleteEvent, evtRef) => {
    eventResource.remove(deleteEvent.ref, deleteEvent.password, null, () => {
      handleClose();
    });
  }

  const handleSuspendEvent = (suspended, evtRef) => {
    let payload = {}
    //OPENED, SUSPENDED, POSTPONED, CLOSED, CANCELED
    payload.nextStatus = "SUSPENDED"
    payload.eventRef = evtRef;
    payload.startDate = suspended.startDate;
    payload.endDate = suspended.endDate;
    payload.emailRef = suspended.emailRef;
    eventStatusResource.replaceAll(payload, evtRef, null, () => {
      handleClose();
    });
  }

  const handleOpenEvent = (activate, evtRef) => {
    let payload = Object.assign({}, activate)
    //OPENED, SUSPENDED, POSTPONED, CLOSED, CANCELED
    payload.nextStatus = "OPENED"
    payload.eventRef = evtRef;
    eventStatusResource.replaceAll(payload, evtRef, null, () => {
      handleClose();
    });
  }

  const handleScheduleEvent = (activate, evtRef) => {
    let payload = Object.assign({}, activate)
    //OPENED, SUSPENDED, POSTPONED, CLOSED, CANCELED
    payload.nextStatus = "SCHEDULED"
    payload.eventRef = evtRef;
    eventStatusResource.replaceAll(payload, evtRef, null, () => {
      handleClose();
    });
  }

  const handleCloseEvent = (closed, evtRef) => {
    let payload = Object.assign({}, closed)
    //OPENED, SUSPENDED, POSTPONED, CLOSED, CANCELED
    payload.nextStatus = "CLOSED"
    payload.eventRef = evtRef || event.ref;
    eventStatusResource.replaceAll(payload, evtRef || event.ref, null, () => {
      handleClose();
    });
  }

  const Modals = React.useMemo(() => {
    return({children}) => action && <div>
      <CloneEvent event={action
          ?.event} show={action && action.name === "CLONE"} onClone={handleCloneEvent} onClose={() => handleClose()}/>
      <ReportEvent event={action
          ?.event} show={action && action.name === "REPORT"} onReport={handleReportEvent} onClose={() => handleClose()}/>
      <SuspendEvent event={action
          ?.event} show={action && action.name === "SUSPEND"} onSuspend={handleSuspendEvent} onClose={() => handleClose()}/>
      <CancelEvent event={action
          ?.event} show={action && action.name === "CANCEL"} onCancel={handleCancelEvent} onClose={() => handleClose()}/>
      <DeleteEvent event={action
          ?.event} show={action && action.name === "DELETE"} onDelete={handleDeleteEvent} onClose={() => handleClose()}/>
    </div>
  }, [action]);

  Modals.displayName = "Modals";

  const actions = () => {
    return {
      "open": {
        title: intl.formatMessage({id: "text.admin.default.open"}),
        do : (event) => {
            handleOpenEvent(null, event.ref);
          }
        ,
        icon: getEventStatusIcon("OPENED"),
        hidden : (event) => event.status === "OPENED"
      },
      "schedule": {
        title: intl.formatMessage({id: "text.admin.default.schedule"}),
        do : (event) => {
            handleScheduleEvent(null, event.ref);
          }
        ,
        icon: getEventStatusIcon("SCHEDULED"),
        hidden : (event) => event.status === "SCHEDULED"
      },
      "close": {
        title: intl.formatMessage({id: "text.admin.default.close"}),
        do : (event) => {
            handleCloseEvent(null, event.ref);
          }
        ,
        icon: getEventStatusIcon("CLOSED"),
        hidden : (event) => event.status === "CLOSED"
      },
      "suspend": {
        title: intl.formatMessage({id: "text.admin.default.suspend"}),
        do : (event) => {
            setAction({name: "SUSPEND", event: event});
          }
        ,
        icon: getEventStatusIcon("SUSPENDED"),
        hidden : (event) => event.status === "SUSPENDED"
      },
      "report": {
        title: intl.formatMessage({id: "text.admin.default.report"}),
        do : (event) => {
            setAction({name: "REPORT", event: event});
          }
        ,
        icon: getEventStatusIcon("REPORTED"),
        hidden : (event) => event.status === "REPORTED"
      },
      "cancel": {
        title: intl.formatMessage({id: "text.admin.default.cancel"}),
        do : (event) => {
            setAction({name: "CANCEL", event: event});
          }
        ,
        icon: getEventStatusIcon("CANCELED"),
        hidden : (event) => event.status === "CANCELED"
      },
      "clone": {
        title: intl.formatMessage({id: "text.admin.default.clone"}),
        do : (event) => {
            setAction({name: "CLONE", event: event});
          }
        ,
        icon: "clone"
      },
      "delete": {
        title: intl.formatMessage({id: "text.admin.default.delete"}),
        do : (event) => {
            setAction({name: "DELETE", event: event});
          }
        ,
        icon: "trash",
        className: "text-danger-30"
      },
      "lock": {
        title: intl.formatMessage({id: "text.admin.default.lock"}),
        do : (event) => {
            handleLockEvent(event.ref)
          }
        ,
        icon: "lock",
        className: "text-warning-20"
      },
      "unlock": {
        title: intl.formatMessage({id: "text.admin.default.unlock"}),
        do : (event) => {
            handleLockEvent(event.ref)
          }
        ,
        icon: "lock",
        className: "text-warning-20"
      }
    }

  };

  const get = (str) => {
    let allActions = actions();
    return allActions[str];
  }

  return {Modals, get, actions}
}

HandleEvent.displayName = "HandleEvent";

export default HandleEvent;
