//import 'react-app-polyfill/stable'
//import 'core-js'
import React from 'react'
import {createRoot} from 'react-dom/client';
const NuagikeApplication = React.lazy(() => import ('src/app/admin.js'));
//import Public from './Public'
import * as serviceWorker from 'src/serviceWorker'
import {Provider} from 'react-redux'
//import store from './store'
import store from 'src/redux/nuagike.store'
import config from 'src/config/index';
import Keycloak from 'keycloak-js';

import './scss/style.scss'
import 'bootstrap/dist/js/bootstrap.min.js';

import Loading from "src/components/static/loading"

let subdomain = window.location.host.split('.')[0];

const saveToken = (keycloak_instance) => {
  localStorage.setItem(config.REFRESH_TOKEN, keycloak_instance.refreshToken);
  localStorage.setItem(config.ACCESS_TOKEN, keycloak_instance.token);
  localStorage.setItem(config.USER_PRINCIPAL, JSON.stringify(keycloak_instance.tokenParsed));
}

const root = createRoot(document.getElementById("root"));

root.render(<Loading/>);



  const keycloakConfig = {
    realm: config.KEYCLOAK.REALM,
    url: config.KEYCLOAK.URL,
    clientId: config.KEYCLOAK.CLIENT_ID
  }

  let keycloak = new Keycloak(keycloakConfig);

  window.keycloak = keycloak;

  const token = localStorage.getItem(config.ACCESS_TOKEN);
  const refreshToken = localStorage.getItem(config.REFRESH_TOKEN);

  keycloak.onTokenExpired = () => {
    console.log('token expired', keycloak.token);
    keycloak.updateToken(600).success(() => {
      console.log('successfully get a new token', keycloak.token);
      saveToken(keycloak);

    }).error((e) => {
      console.error(e);
      console.error("update token falied");
    }); 
  }

  keycloak.init({onLoad: 'login-required'}).then((auth) => {
    if (!auth) {
      keycloak.login();
    } else {
      saveToken(keycloak);
      root.render(<Provider store={store}>
        <NuagikeApplication/>
      </Provider>);
    }

  }).catch((error) => {
    console.error(error);
    console.error("authentication falied");
    root.render(<div className="row bg-info-90 text-primary p-2 m-0 h-100">
      <h1 className="text-dark pb-2 fw-bold">Apology for Website Unavailability</h1>
      <p>Dear user,</p>
      <p>I would like to sincerely apologize for the current unavailability of our website. We understand the frustration this may cause, and we deeply regret any inconvenience it has caused you.</p>
      <p>Please be assured that we are actively working to resolve the issue and restore the website as quickly as possible. Our technical team is dedicated to identifying and fixing the underlying problem.</p>
      <p>We understand the importance of accessing our website and are committed to resolving this situation promptly. We kindly request your patience and understanding during this time.</p>
      <p>Once again, we apologize for the temporary disruption and assure you that we are doing everything we can to bring our website back online for your use.</p>
      <p>Thank you for your understanding and support.</p>
      <p>Sincerely,</p>
      <p>The&nbsp;
        <b>Nuagike.com</b>&nbsp;Team</p>
    </div>);
  });


// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister()
