const defaultResource = () => {
  return {
    data: {
      count:0,
      results: []
    },
    current: {}
  }
}

const initialState = {
  public: {
    event: {},
    steps: [],
    currentStep: null
  },
  user: {
    principal: null,
    email: null,
    authorities: [],
    organization: {
      documentGroupRef: null,
      eventRefs: []
    },
    locale: "fr",
    dateFormat :"dd/MM/yyyy",
    timeFormat : "HH:mm:ss",
    timezone: false //take the system timezone
  },
  resources: {
    corporate: defaultResource(),
    user: defaultResource(),
    user_organization: defaultResource(),
    file: defaultResource(),
    log: defaultResource(),
    event: defaultResource(),
    event_public: defaultResource(),
    event_stat: defaultResource(),
    event_param: defaultResource(),
    cfp : defaultResource(),
    paper: defaultResource(),
    vote : defaultResource(),
    review : defaultResource(),
    evaluation : defaultResource(),
    thema : defaultResource(),
    cfp_evaluation : defaultResource(),
    cfp_thema : defaultResource(),
    session : defaultResource(),
    behavior: defaultResource(),
    venue: defaultResource(),
    room: defaultResource(),
    event_status: defaultResource(),
    subscription: defaultResource(),
    subscription_medal: defaultResource(),
    subscription_otp: defaultResource(),
    medal: defaultResource(),
    document: defaultResource(),
    document_category: defaultResource(),
    ticket: defaultResource(),
    order: defaultResource(),
    order_draft: defaultResource(),
    payment: defaultResource(),
    refund: defaultResource(),
    ticket_option: defaultResource(),
    ticket_group: defaultResource(),
    ticket_organization : defaultResource(),
    ticket_organization_stat : defaultResource(),
    form: defaultResource(),
    form_response: defaultResource(),
    form_public_response: defaultResource(),
    form_field: defaultResource(),
    marketplace_subscription: defaultResource(),
    marketplace_subscription_form: defaultResource(),
    marketplace_subscription_form_import: defaultResource(),
    marketplace_subscription_venue: defaultResource(),
    marketplace_subscription_params: defaultResource(),
    marketplace_product: defaultResource(),
    marketplace_authorization: defaultResource(),
    batch_importrrequest: defaultResource(),
    batch_processfile: defaultResource(),
    message: defaultResource(),
    message_group: defaultResource(),
    message_realm: defaultResource(),
    message_realm_contact : defaultResource(),
    message_story: defaultResource()
  },

  errors: {
    resources: {
    }
  },

  config: {
    enums: {},
    repositories: {
      countries: [],
      currencies: []
    },
    public: {},
    wordings: {
      fr: {},
      en: {}
    }
  },
  loading: false,
  actions: []
};

export default initialState;
