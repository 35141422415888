import config from "src/config/index"
const resources = {
  corporate: {
    uri: config.API_EVT_BASE_URL + "/api/evt/corporates",
    store: {
      type: 'state'
    }
  },

  user: {
    uri: config.API_IAM_BASE_URL + "/api/iam-core/users",
    store: {
      type: 'state'
    }
  },

  user_organization: {
    uri: config.API_IAM_BASE_URL + "/api/iam-core/organizations",
    store: {
      type: 'state'
    }
  },

  event: {
    uri: config.API_EVT_BASE_URL + "/api/evt/events",
    store: {
      type: 'state'
    }
  },

  event_form: {
    uri: config.API_EVT_BASE_URL + "/api/evt/events/${eventRef}/forms",
    store: {
      type: false
    },
    parent: 'event'
  },

  event_status: {
    uri: config.API_EVT_BASE_URL + "/api/evt/events/${eventRef}/status",
    store: {
      type: 'override_parent'
    },
    parent: 'event'
  },

  event_public: {
    uri: config.API_EVT_BASE_URL + "/api/evt/events/${eventRef}/public",
    store: {
      type: 'state'
    },
    parent: 'event'
  },

  event_stat: {
    uri: config.API_EVT_BASE_URL + "/api/evt/events/${eventRef}/stats",
    store: {
      type: 'state'
    },
    parent: 'event'
  },
  event_param: {
    uri: config.API_EVT_BASE_URL + "/api/evt/events/${eventRef}/params",
    store: {
      type: 'state'
    },
    parent: 'event'
  },

  event_venue: {
    uri: config.API_EVT_BASE_URL + "/api/evt/events/${eventRef}/venues",
    store: {
      type: "override_parent"
    },
    parent: 'event'
  },

  thema: {
    uri: config.API_EVT_BASE_URL + "/api/evt/cfps/themas",
    store: {
      type: 'state'
    }
  },

  cfp: {
    uri: config.API_EVT_BASE_URL + "/api/evt/cfps",
    store: {
      type: 'state'
    }
  },

  cfp_reset: {
    uri: config.API_EVT_BASE_URL + "/api/evt/cfps/${cfpRef}/resetscores",
    store: {
      type: false
    },
    parent: 'cfp'
  },

  paper: {
    uri: config.API_EVT_BASE_URL + "/api/evt/cfps/papers",
    store: {
      type: 'state'
    }
  },

  paper_status: {
    uri: config.API_EVT_BASE_URL + "/api/evt/cfps/papers/${paperRef}/status",
    store: {
      type: 'override_parent'
    },
    parent: 'paper'
  },

  review: {
    uri: config.API_EVT_BASE_URL + "/api/evt/cfps/reviews",
    store: {
      type: 'state'
    }
  },

  review_status: {
    uri: config.API_EVT_BASE_URL + "/api/evt/cfps/reviews/${reviewRef}/status",
    store: {
      type: 'override_parent'
    },
    parent: 'review'
  },

  vote: {
    uri: config.API_EVT_BASE_URL + "/api/evt/cfps/reviews/${reviewRef}/votes",
    store: {
      type: 'state'
    },
    parent: 'review'
  },

  evaluation: {
    uri: config.API_EVT_BASE_URL + "/api/evt/cfps/evaluations",
    store: {
      type: 'state'
    }
  },

  cfp_evaluation: {
    uri: config.API_EVT_BASE_URL + "/api/evt/cfps/${cfpRef}/evaluations",
    store: {
      type: 'override_parent'
    },
    parent: 'cfp'
  },

  cfp_thema: {
    uri: config.API_EVT_BASE_URL + "/api/evt/cfps/${cfpRef}/themas",
    store: {
      type: 'override_parent'
    },
    parent: 'cfp'
  },

  session: {
    uri: config.API_EVT_BASE_URL + "/api/evt/events/sessions",
    store: {
      type: 'state'
    }
  },

  venue: {
    uri: config.API_EVT_BASE_URL + "/api/evt/venues",
    store: {
      type: 'state'
    }
  },
  room: {
    uri: config.API_EVT_BASE_URL + "/api/evt/venues/rooms",
    store: {
      type: 'state'
    }
  },

  behavior: {
    uri: config.API_EVT_BASE_URL + "/api/evt/behaviors",
    store: {
      type: 'state'
    }
  },

  subscription: {
    uri: config.API_EVT_BASE_URL + "/api/evt/events/subscriptions",
    store: {
      type: 'state'
    }
  },

  subscription_otp: {
    uri: config.API_EVT_BASE_URL + "/api/evt/otps",
    store: {
      type: 'state'
    }
  },

  subscription_medal: {
    uri: config.API_EVT_BASE_URL + "/api/evt/events/subscriptions/${subscriptionRef}/medals",
    store: {
      type: false
    },
    parent : 'subscription'
  },

  subscription_registration : {
    uri: config.API_EVT_BASE_URL + "/api/evt/events/subscriptions/registrations",
    store: {
      type: 'override_parent'
    },
    parent: 'subscription'
  },

  subscription_adminregistration : {
    uri: config.API_EVT_BASE_URL + "/api/evt/events/subscriptions/adminregistrations",
    store: {
      type: 'override_parent'
    },
    parent: 'subscription'
  },

  medal: {
    uri: config.API_EVT_BASE_URL + "/api/evt/events/medals",
    store: {
      type: 'state'
    }
  },

  ticket: {
    uri: config.API_TKT_BASE_URL + "/api/tkt/tickets",
    store: {
      type: 'state'
    }
  },

  ticket_option: {
    uri: config.API_TKT_BASE_URL + "/api/tkt/options",
    store: {
      type: 'state'
    }
  },

  ticket_group: {
    uri: config.API_TKT_BASE_URL + "/api/tkt/groups",
    store: {
      type: 'state'
    }
  },

  ticket_organization: {
    uri: config.API_TKT_BASE_URL + "/api/tkt/organizations",
    store: {
      type: 'state'
    }
  },

  ticket_organization_param: {
    uri: config.API_TKT_BASE_URL + "/api/tkt/organizations/${ticket_organizationRef}/params",
    store: {
      type: 'override_parent'
    },
    parent: 'ticket_organization'
  },

  ticket_organization_stat: {
    uri: config.API_TKT_BASE_URL + "/api/tkt/organizations/stats",
    store: {
      type: 'state'
    }
  },

  order: {
    uri: config.API_TKT_BASE_URL + "/api/tkt/orders",
    store: {
      type: 'state'
    }
  },

  order_draft: {
    uri: config.API_TKT_BASE_URL + "/api/tkt/orders/drafts",
    store: {
      type: 'state'
    }
  },

  order_status: {
    uri: config.API_TKT_BASE_URL + "/api/tkt/orders/${orderRef}/status",
    store: {
      type: 'override_parent'
    },
    parent: 'order'
  },

  payment: {
    uri: config.API_TKT_BASE_URL + "/api/pay/payments",
    store: {
      type: 'state'
    }
  },

  refund: {
    uri: config.API_TKT_BASE_URL + "/api/pay/payments/${paymentRef}/refunds",
    store: {
      type: 'state'
    },
    parent: 'payment'
  },

  file: {
    uri: config.API_DOC_BASE_URL + "/api/doc/files",
    store: {
      type: 'state'
    }
  },

  document: {
    uri: config.API_DOC_BASE_URL + "/api/doc/groups/documents",
    store: {
      type: 'state'
    }
  },

  document_category: {
    uri: config.API_DOC_BASE_URL + "/api/doc/groups/categories",
    store: {
      type: 'state'
    }
  },

  form: {
    uri: config.API_CMS_BASE_URL + "/api/cms/forms",
    store: {
      type: 'state'
    }
  },

  form_field: {
    uri: config.API_CMS_BASE_URL + "/api/cms/forms/${formRef}/fields",
    store: {
      type: 'state'
    },
    parent: 'form'
  },

  form_response: {
    uri: config.API_CMS_BASE_URL + "/api/cms/forms/${formRef}/responses",
    store: {
      type: 'state'
    },
    parent: 'form'
  },

  form_public_response: {
    uri: config.API_CMS_BASE_URL + "/api/cms/responses",
    store: {
      type: 'state'
    }
  },

  marketplace_subscription: {
    uri: config.API_MKT_BASE_URL + "/api/mkt/organizations/subscriptions",
    store: {
      type: 'state'
    }
  },

  marketplace_subscription_form: {
    uri: config.API_MKT_BASE_URL + "/api/mkt/organizations/subscriptions/${marketplace_subscriptionRef}/forms",
    store: {
      type: 'state'
    },
    parent: 'marketplace_subscription'
  },

  marketplace_subscription_form_import: {
    uri: config.API_MKT_BASE_URL + "/api/mkt/organizations/subscriptions/${marketplace_subscriptionRef}/forms/imports",
    store: {
      type: 'state'
    },
    parent: 'marketplace_subscription'
  },

  marketplace_subscription_venue: {
    uri: config.API_MKT_BASE_URL + "/api/mkt/organizations/subscriptions/${marketplace_subscriptionRef}/venues",
    store: {
      type: 'state'
    },
    parent: 'marketplace_subscription'
  },

  marketplace_subscription_params: {
    uri: config.API_MKT_BASE_URL + "/api/mkt/organizations/subscriptions/${marketplace_subscriptionRef}/params",
    store: {
      type: 'override_parent'
    },
    parent: 'marketplace_subscription'
  },

  marketplace_product: {
    uri: config.API_MKT_BASE_URL + "/api/mkt/products",
    store: {
      type: 'state'
    }
  },

  marketplace_authorization: {
    uri: config.API_MKT_BASE_URL + "/api/mkt/organizations/authorizations",
    store: {
      type: 'state'
    }
  },

  marketplace_authorization_link: {
    uri: config.API_MKT_BASE_URL + "/api/mkt/organizations/authorizations/links",
    store: {
      type: 'state'
    }
  },

  log: {
    uri: config.API_LOG_BASE_URL + "/api/log/logs",
    store: {
      type: 'state'
    }
  },

  batch_importrrequest: {
    uri: config.API_BAT_BASE_URL + "/api/bat/importrequests",
    store: {
      type: 'state'
    }
  },

  batch_processfile: {
    uri: config.API_BAT_BASE_URL + "/api/bat/processfiles",
    store: {
      type: 'state'
    }
  },

  message: {
    uri: config.API_OMC_BASE_URL + "/api/omc/messages",
    store: {
      type: 'state'
    }
  },
  message_group: {
    uri: config.API_OMC_BASE_URL + "/api/omc/groups",
    store: {
      type: 'state'
    }
  },
  message_realm: {
    uri: config.API_OMC_BASE_URL + "/api/omc/realms",
    store: {
      type: 'state'
    }
  },
  message_realm_contact: {
    uri: config.API_OMC_BASE_URL + "/api/omc/realms/${message_realmRef}/contacts",
    store: {
      type: 'state'
    },
    parent: 'message_realm'
  },
  message_story: {
    uri: config.API_OMC_BASE_URL + "/api/omc/messages/stories",
    store: {
      type: 'state'
    }
  }
}

export default resources;
