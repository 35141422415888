import React from "react";
import classnames from "classnames"
import {DndProvider} from 'react-dnd'
import {HTML5Backend} from 'react-dnd-html5-backend';
import "./index.scss"

const DraggableContainer = ({className, ...props}) => {

  return <div className={classnames("nuagike-dndcontainer p-0 m-0 pb-1 rounded w-100", className)} {...props}>
    <DndProvider backend={HTML5Backend} className="w-100">
      {props.children}
    </DndProvider>
  </div>
}

export default DraggableContainer;
