import React, {useState} from 'react'
import classnames from 'classnames';
import {Link} from 'react-router-dom'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {OverlayTrigger, Overlay, Tooltip} from 'react-bootstrap';
import Moment from 'moment';
import CountryBadge from "./country"
import TimezoneBadge from "./timezone"
import {Button, useCopyToClipboard} from "src/components/nuagike/index"
import PrependBadge from "./prepend"
import DateBadge from "./date"
import Price from "./price"
import Number from "./number"
import LinkBadge from "./link"
import DefaultBadge from "./default"

import "./index.scss"

const Badge = (props) => {
  return props.tooltip && !props.disabled
    ? <OverlayTrigger
        className="p-0 m-0"
        delay={{
          show: 450,
          hide: 200
        }}
        placement="top"
        overlay={<Tooltip><span> {
          props.tooltip
        }
        </span>

    </Tooltip>}>
        <div className="d-flex align-items-center text-truncate"><BadgeContent {...props}/></div>
      </OverlayTrigger>
    : <BadgeContent {...props}/>
}

const BadgeContent = React.memo((props) => {

  const [clipboard, setClipboard] = useCopyToClipboard()

  return (props.type === "link")
    ? <LinkBadge {...props}/>
    : (props.type === "number")
      ? <Number {...props}/>
      : (props.type === "price")
        ? <Price {...props}/>
        : (props.type === "timezone")
          ? <TimezoneBadge {...props}/>
          : (props.type === "country")
            ? <CountryBadge {...props}/>
            : (props.type === "datetime")
              ? <DateBadge time={true} {...props}/>
              : props.type === "date"
                ? <DateBadge {...props}/>
                : props.type === "icon"
                  ? <div className={classnames("badge nuagike-badge d-flex align-items-center justify-content-center p-1 m-0 w-100  opacity-75 rounded", props.className)}>
                      <FontAwesomeIcon
                        icon={props.icon
                          ? props.icon
                          : "tag"}
                        size={props.iconSize
                          ? props.iconSize
                          : "1x"}
                        className={classnames("opacity-50 fa-fw ", {
                          ["me-2"]: props.text
                        }, {"d-none": props.hideIcon})}/>
                    </div>
                  : <DefaultBadge {...props}/>

});

BadgeContent.displayName = 'BadgeContent';
Badge.displayName = 'Badge';
export default Badge;
