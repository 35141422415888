import React, {useState} from 'react'
import classnames from 'classnames';
import {Link} from 'react-router-dom'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {Button} from "src/components/nuagike/index"
import PrependBadge from "./prepend"

import DefaultBadge from "./default"

const Price = ({text, value, currency, ...props}) => {
  const price = (txt) => txt ? (Math.round(Number(txt)) / 100).toFixed(2) : 0;

  const PriceComp = ({vPrice, vCurrency})=>{
    return <><b className="pe-1">{vPrice}</b><small>{vCurrency}</small></>
  }

  return <DefaultBadge
  text={(text && value === null) ? <PriceComp vPrice={price(text)} vCurrency={currency}/> : text}
  value={value !==null ? <PriceComp vPrice={price(value)} vCurrency={currency}/> : ""}
  clipboard={value || text}
  justify={"end"}
  icon="coins" {...props} />

}

export default Price;
