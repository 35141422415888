import React, {useState} from 'react'
import {useSelector} from 'react-redux'
import classnames from 'classnames';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {Badge} from "src/components/nuagike/index"
import "./index.scss"

const DropDown = (props) => {

  return <div id={props.id} name={props.name} key={"dropdown-" + props.id}
    ////
    className={classnames("nuagike-dropdown dropdown text-center d-flex align-items-center justify-content-center", {
      ["nuagike-dropdown-" + props.size]: props.size
    }, props.className)}>
    {
      props.children
        ? <div className="p-0 m-0 d-flex align-items-center px-2 fw-bold text-info">{props.children}</div>
        : ""
    }

    <div className="btn-group dropstart">

      <button className={classnames("btn nuagike-button nuagike-button-circle rounded-circle border-0 m-0 p-0", {
          ["btn-nuagike-" + props.color]: props.color
        }, {
          ["btn-nuagike-inherit"]: !props.color
        })} data-bs-toggle="dropdown" aria-expanded="false">

        {props.flag ? <div className={classnames("fs-2 fi fis fi-" + props.flag)}></div>
          : <FontAwesomeIcon className="fa-fw justify-contents-center m-auto " icon={props.icon
              ? props.icon
              : "ellipsis-v"}/>
      }
      </button>

      <ul className="dropdown-menu text-truncate" id={"dropdown-menu-" + props.id} name={"dropdown-menu-" + props.name} key={"dropdown-menu-" + props.id}>
        {
          props.items && props.items.map((item, index) => {

            return <li key={"dropdown-" + index} className={classnames("d-block m-0 px-1 text-truncate", {"d-none": item.hidden})}>{
                item.divider
                  ? <hr className="dropdown-divider"/>
                  : <div className={classnames("dropdown-item text-truncate p-0 m-0 py-1 my-0 ps-1 pe-2 w-100 d-flex justify-content-start align-items-center", {
                        "disabled": item.disabled
                      }, item.className)}
                      ////
                      aria-disabled={item.disabled
                        ? "true"
                        : "false"} onClick={() => item.action()}>
                      {item.icon && <FontAwesomeIcon icon={item.icon} className="d-flex align-items-center justify-content-center opacity-75 fa-fw"/>}
                      {item.flag && <div className={classnames("fs-2 fi fis fi-" + item.flag)}></div>}
                      <span className="h-100 p-0 m-0 ps-2 text-truncate">{item.title}</span>
                    </div>
              }</li>

          })
        }

      </ul>
    </div>

  </div>

}

export default React.memo(DropDown);
