import React, {useState, useEffect} from 'react'
import classNames from 'classnames';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'

import {useResource} from "src/components/nuagike/index"

export default function useEditResource({
  original,
  type,
  defaultAttributes = ['ref', 'etag']
}) {

  const {add: add, remove:remove, update:update} = useResource(type, false)

  const [localCopy, setLocalCopy] = React.useState({})
  const [dirtyCopy, setDirtyCopy] = React.useState({})
  const [dirty, setDirty] = React.useState(false)

  React.useEffect(() => {
    console.log({["original ["+type+"] changed"] : original});
    if (original) {
      setLocalCopy({
        ...original
      })
      let dc = {
        ref: original.ref,
        etag: original.etag
      }
      if (defaultAttributes) {
        defaultAttributes.map(att => {
          dc[att] = original[att];
        });
      }
      setDirtyCopy(dc)
      setDirty(false)
    }
  }, [original.ref, original.etag]);

  const onDelete = (ref) => {
    if (ref) {
      remove(ref)
    } else if (original && original.ref) {
      remove(original.ref)
    }
  }

  const onUpdate = (name, value) => {
    setLocalCopy(localCopy => ({
      ...localCopy,
      [name]: value
    }));
    setDirtyCopy(dirtyCopy => ({
      ...dirtyCopy,
      [name]: value
    }));
    setDirty(true)
  }

  const onSave = (params) => {

    if (dirty) {
      let objectToSave = (localCopy.ref === "new")
        ? {
          ...localCopy
        }
        : {
          ...dirtyCopy
        }
      if (params) {
        Object.keys(params).map(key => {
          objectToSave[key] = params[key]
        })
      }

      let callbackFunction = null;
      if (objectToSave.callback) {
        callbackFunction = objectToSave.callback;
        delete objectToSave.callback;
      }

      if (objectToSave.ref === "new") {
        delete objectToSave.ref;
        delete objectToSave.version;
        delete objectToSave.etag;
        delete objectToSave.dirty;
        add(objectToSave, null, callbackFunction);
      } else {
        update(objectToSave.ref, objectToSave, null, callbackFunction);
      }

      setDirty(false)
    } else {
      console.warn("resource with type " + type + " and ref " + localCopy.ref + " is not dirty.. so abord save");
    }
  }

  const onUndo = () => {
    setLocalCopy({
      ...original
    })
    setDirtyCopy({ref: original.ref, etag: original.etag})
    setDirty(false)
  }

  const onClone = (newObject) => {
    setLocalCopy({
      ...newObject
    })
    setDirtyCopy({
      ...newObject
    })
    setDirty(true)
  }

  return {
    onDelete,
    onUpdate,
    onSave,
    onClone,
    dirty,
    localCopy,
    onUndo
  }
}
