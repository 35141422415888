import React, {useState} from 'react'
import classnames from 'classnames';
import {Button} from "src/components/nuagike/index"
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {useIntl} from 'react-intl';
import JoditEditor from "jodit-react";
import "./index.scss"

const RichTextEditor = ({
  handleUpdate,
  defaultValue,
  zoom,
  ...props
}) => {
  const intl = useIntl()
  const [localValue, setLocalValue] = React.useState("")
  const editorRef = React.useRef(null);

  const getButtons = () => {

    let buttons = [
      "source",
      "|",
      "font", {
        //name: 'Font size',
        icon: 'fontsize',
        command: 'fontSize',
        list: [
          '6pt',
          '8pt',
          '10pt',
          '12pt',
          '14pt',
          '16pt',
          '18pt',
          '20pt',
          '22pt',
          "24pt",
          "60pt"
        ],
        //template: (editor, key, value) => key,
        tooltip: 'Font size'
      },
      "lineHeight",

      "paragraph",
      "brush",
      "|",
      "bold",
      "italic",
      "strikethrough",
      "underline",
      "|",
      "\n",
      "ul",
      "ol",
      "table",
      "hr",
      "|",
      'align',
      'undo',
      'redo',
      '|',
      "selectall",
      "cut",
      "copy",
      "paste",
      "copyformat",
      "eraser",
      "\n"

    ];

    return buttons;
  }

  React.useEffect(() => {
    console.log({defaultValue});
    setLocalValue(localValue => defaultValue)
  }, [defaultValue]);

  const editorConfig = {
    readonly: false,
    colorPickerDefaultTab: 'color',
    buttons: getButtons(),
    createAttributes: {
      div: {
        class: 'align-center'
      }
    }

  };

  return <div className="nuagike-rte bg-white" {...props}>
    <JoditEditor autofocus={true} ref={editorRef} enter={"div"} value={defaultValue} config={editorConfig} tabIndex={1} onBlur={newContent => {
        setLocalValue(newContent);
        handleUpdate(newContent);
      }} onChange={newContent => {}}/>
  </div>
}

export default RichTextEditor
