import React from 'react'
import {
  BarChart,
  Bar,
  Cell,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  LabelList,
  Label,
  ResponsiveContainer
} from 'recharts';

const NuagikeBar = ({data, bars}) => {

  const DEFAULT_COLOR = "#c4c9d0";

  const COLORS = ['#0088FE', '#00C49F', '#FFBB28', '#FF8042'];

  const renderCustomizedLabel = (props) => {
    const {
      content,
      ...rest
    } = props;

    return <Label {...rest} fontSize="12" fill="#FFFFFF" fontWeight="Bold"/>;
  };

    React.useEffect(() => {

    },[data]);

  return <ResponsiveContainer width="100%" height="100%">
    <BarChart layout="vertical" data={data} margin={{
        left: 250,
        right: 50
      }} stackOffset="none">
      <XAxis hide="hide" type="number"/>
      <YAxis type="category" dataKey="name" stroke="#000" fontSize="12"/>
      <Tooltip/> {
        bars && bars.map((bkey,bindex)=> {
          return <Bar key={`bar_${bindex}%`} dataKey={bkey} fill={COLORS[bindex % COLORS.length]} stackId="a">
            <LabelList dataKey={bkey} position="center" content={renderCustomizedLabel}/>
          </Bar>
        })
      }
    </BarChart>
  </ResponsiveContainer>

}

export default NuagikeBar;
