import React, {useState} from 'react'
import {useSelector} from 'react-redux'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {
  Card,
  Row,
  Col,
  Dropdown,
  Tab,
  Tabs,
  Nav
} from 'react-bootstrap'

import "./index.scss"

function Navigation({
  items,
  activeTab,
  ...props
}) {

  return activeTab && Array.isArray(items)
    ? <div className="nuagike-navigation mb-2 bg-transparent">
        <Tab.Container activeKey={activeTab} mountOnEnter={true}>

          <Nav variant="tabs" className="mb-1 ">

            {
              items && items.map((item, index) => {
                return <Nav.Item key={"nav-item-" + item.key + "-" + index}>
                  <Nav.Link href={item.link} eventKey={item.key} className="border border-light border-5   border-top-0 border-start-0 border-end-0  text-info">
                    <FontAwesomeIcon icon={item.icon
                        ? item.icon
                        : "hashtag"} className="text-light me-2"/>{item.title}
                  </Nav.Link>
                </Nav.Item>
              })
            }

          </Nav>

          <Tab.Content>
            {
              items && items.map((item, index) => {
                return <Tab.Pane key={"nav-pane-" + item.key + "-" + index} eventKey={item.key} mountOnEnter={true}>
                  <item.component {...props}/>
                </Tab.Pane>
              })
            }

          </Tab.Content>

        </Tab.Container>
      </div>
    : "";

}

export default Navigation;
