import config from 'src/config/index'

export function get(api, includeAuthorization = true, etag = null, returnString = false) {
  return request({
    url: api,
    method: 'GET'
  }, 'application/json', includeAuthorization, etag, returnString).then(response => {
    //console.log({ ['GET ' + api]: response  });
    return Promise.resolve(response);
  }).catch(error => {
    console.error(error);
    return Promise.reject(error);
  });
}

export function remove(api, data, includeAuthorization = true) {

  return request({
    url: api,
    body: JSON.stringify(data),
    method: 'DELETE'
  }, 'application/json', includeAuthorization, null).then(response => {
    //console.log({['DELETE ' + api]: response});
    return Promise.resolve(response);
  }).catch(error => {
    console.error(error);
    return Promise.reject(error);
  });
}

export function post(api, data, includeAuthorization = true, etag = null, returnString = false) {

  let ifMatchEtag = etag;
  if (!ifMatchEtag && data && data.etag) {
    ifMatchEtag = data.etag;
    delete data['etag'];
  }

  return request({
    url: api,
    method: 'POST',
    body: JSON.stringify(data)
  }, 'application/json', includeAuthorization, ifMatchEtag, returnString).then(response => {
    //console.log({   ['POST ' + api]: response    });
    return Promise.resolve(response);
  }).catch(error => {
    return Promise.reject(error);
  });
}

export function postFormData(api, data, includeAuthorization = true, etag = null) {
  return request({
    url: api,
    method: 'POST',
    body: data
  }, null, includeAuthorization, false).then(response => {
    //console.log({   ['POST ' + api]: response   });
    return Promise.resolve(response);
  }).catch(error => {
    return Promise.reject(error);
  });
} 

export function patch(api, data, includeAuthorization = true, etag = null) {
  let ifMatchEtag = etag;
  if (!ifMatchEtag && data && data.etag) {
    ifMatchEtag = data.etag;
    delete data['etag'];
  }
  //console.log({   "patch": api  }, {    ifMatchEtag  }, {data});

  return request({
    url: api,
    method: 'PATCH',
    body: JSON.stringify(data)
  }, 'application/json', includeAuthorization, ifMatchEtag).then(response => {
    //console.log({     ['PATCH ' + api]: response    });
    return Promise.resolve(response);
  }).catch(error => {
    return Promise.reject(error);
  });
}

export function put(api, data, includeAuthorization = true, etag = null) {

  let ifMatchEtag = etag;
  if (!ifMatchEtag && data && data.etag) {
    ifMatchEtag = data.etag;
    delete data['etag'];
  }

  return request({
    url: api,
    method: 'PUT',
    body: JSON.stringify(data)
  }, 'application/json', includeAuthorization, ifMatchEtag).then(response => {
    //  console.log({      ['PUT ' + api]: response    });
    return Promise.resolve(response);
  }).catch(error => {
    return Promise.reject(error);
  });
}

export function request(options, contentType = 'application/json', includeAuthorization = true, etag = null, returnString = false) {
  //console.log({ options, contentType, returnString });

  const headers = new Headers();
  headers.append('Accept', "application/json, application/" + config.API_VERSION + "+json");

  if (contentType) {
    headers.append('Content-Type', contentType);
  }
  if (localStorage.getItem(config.ACCESS_TOKEN) && includeAuthorization) {
    headers.append('Authorization', 'Bearer ' + localStorage.getItem(config.ACCESS_TOKEN))
  }

  if (etag) {
    //console.log({"adding etag to header request": etag});
    if (options && options.method === 'GET') {
      headers.append('If-None-Match', etag);
    } else if (options && (options.method === 'PATCH' || options.method === 'PUT' || options.method === 'POST')) {
      headers.append('If-Match', etag);
    }
  }

  const defaults = {
    headers: headers
  };
  options = Object.assign({}, defaults, options);

  return fetch(options.url, options).then(response => {

    if (response.status === 304) {

      return Promise.resolve(null);

    } else if (returnString && response.ok) {

      return Promise.resolve(response.text());
    } else {
      return response.json().then(json => {
        //console.log({"server responding": json, "server response status": response.status});
        if (response.ok) {
          const etag = response.headers.get('etag');

          if (etag) {
            //console.log({"etag received": etag});
            json.etag = etag;
          }

          return Promise.resolve(json);
        } else if (response.status === 401 || response.status === 403) {

          if (localStorage.getItem(config.ACCESS_TOKEN)) {
            console.error("response is " + response.status + ". should force deconnect user");
            //document.location = '/login';
          }
          return Promise.reject(json);

        } else {
          return Promise.reject(json);
        }
      }).catch(error => {
        console.error(error);
        return Promise.reject(error);
      });
    }

  });
}

export function requestData(options, contentType = 'application/json', includeAuthorization = true, accept = "json") {
  //  console.log({"requesting server ...": options, 'content-type': contentType});

  const headers = new Headers();
  headers.append('Accept', "application/"+accept+", application/" + config.API_VERSION + "+" + accept);
  if (contentType) {
    headers.append('Content-Type', contentType);
  }
  if (localStorage.getItem(config.ACCESS_TOKEN) && includeAuthorization) {
    headers.append('Authorization', 'Bearer ' + localStorage.getItem(config.ACCESS_TOKEN))
  }
  const defaults = {
    headers: headers
  };
  options = Object.assign({}, defaults, options);

  return fetch(options.url, options).then(response => {
    //    console.log({"server responding": response, "server response status": response.status});

    if (response.ok) {
      return Promise.resolve(response);
    } else if (response.status === 401) {

      if (localStorage.getItem(config.ACCESS_TOKEN)) {
        console.error("response is 401. should deconnect user ?");
        //document.location = '/login';
      }
      return Promise.reject(response);

    } else {
      return Promise.reject(response);
    }
  });
}

export function deepCompare() {
  var i,
    l,
    leftChain,
    rightChain;

  function compare2Objects(x, y) {
    var p;

    // remember that NaN === NaN returns false
    // and isNaN(undefined) returns true
    if (isNaN(x) && isNaN(y) && typeof x === 'number' && typeof y === 'number') {
      return true;
    }

    // Compare primitives and functions.
    // Check if both arguments link to the same object.
    // Especially useful on the step where we compare prototypes
    if (x === y) {
      return true;
    }

    // Works in case when functions are created in constructor.
    // Comparing dates is a common scenario. Another built-ins?
    // We can even handle functions passed across iframes
    if ((typeof x === 'function' && typeof y === 'function') || (x instanceof Date && y instanceof Date) || (x instanceof RegExp && y instanceof RegExp) || (x instanceof String && y instanceof String) || (x instanceof Number && y instanceof Number)) {
      return x.toString() === y.toString();
    }

    // At last checking prototypes as good as we can
    if (!(x instanceof Object && y instanceof Object)) {
      return false;
    }

    if (Object.prototype.isPrototypeOf.call(x,y) || Object.prototype.isPrototypeOf.call(y,x)) {
      return false;
    }

    if (x.constructor !== y.constructor) {
      return false;
    }

    if (x.prototype !== y.prototype) {
      return false;
    }

    // Check for infinitive linking loops
    if (leftChain.indexOf(x) > -1 || rightChain.indexOf(y) > -1) {
      return false;
    }

    // Quick checking of one object being a subset of another.
    // todo: cache the structure of arguments[0] for performance
    for (p in y) {
      if (Object.prototype.hasOwnProperty.call(y, p) !== Object.prototype.hasOwnProperty.call(x, p)) {
        return false;
      } else if (typeof y[p] !== typeof x[p]) {
        return false;
      }
    }

    for (p in x) {
      if (Object.prototype.hasOwnProperty.call(y, p) !== Object.prototype.hasOwnProperty.call(x, p)) {
        return false;
      } else if (typeof y[p] !== typeof x[p]) {
        return false;
      }

      switch (typeof(x[p])) {
        case 'object':
        case 'function':

          leftChain.push(x);
          rightChain.push(y);

          if (!compare2Objects(x[p], y[p])) {
            return false;
          }

          leftChain.pop();
          rightChain.pop();
          break;

        default:
          if (x[p] !== y[p]) {
            return false;
          }
          break;
      }
    }

    return true;
  }

  if (arguments.length < 1) {
    return true; //Die silently? Don't know how to handle such case, please help...
    // throw "Need two or more arguments to compare";
  }

  for (i = 1, l = arguments.length; i < l; i++) {

    leftChain = []; //Todo: this can be cached
    rightChain = [];

    if (!compare2Objects(arguments[0], arguments[i])) {
      return false;
    }
  }

  return true;
}
