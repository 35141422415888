import {createReducer} from "@reduxjs/toolkit"
import defaultReducers from "./defaults"
import resourcesReducers from "./resources"

import initialState from "src/redux/initialState"

const rootReducer = createReducer(initialState, (builder)=>{
  defaultReducers(builder);
  resourcesReducers(builder);
});

export default rootReducer;
