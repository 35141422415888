import React, {useState, useEffect, useMemo} from 'react'
import {
  Alert,
  Badge,
  Button,
  Modal,
  Input,
  Page,
  useResource,
  useErrors,
  useLoading
} from 'src/components/nuagike/index'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {FormattedMessage, useIntl} from 'react-intl';
import classnames from "classnames";

const DeleteEvent = ({event, onDelete, onClose, show}) => {
  const intl = useIntl();
  const loading = useLoading();
  const eventResource = useResource("event", false);

  const [deleteEvent, setDeleteEvent] = React.useState({password: "password"})

  React.useEffect(() => {
    setDeleteEvent({
      ...deleteEvent,
      ["ref"]: event.ref
    })
  }, [event.ref])

  const handleDelete = () => {
    onDelete(deleteEvent)
  }

  return <Modal icon="ban" size="lg" title={intl.formatMessage({id: "text.admin.event.delete.title"})} show={show} onClose={() => {
      onClose()
    }}>

    <div className="row p-0 m-0">
      <div className="col p-0 m-0 col-12">
        <Alert alert="danger" text={intl.formatMessage({id: "text.admin.event.delete.alert.title"})}>
          {intl.formatMessage({id: "text.admin.event.delete.alert.description"})}
        </Alert>
      </div>

      <div className="col col-12 p-0 m-0 pt-1">

        <Badge icon="trash" id="input-eventtoclone-ref" bg="danger-90" text={event.title} copyclipboard={true}/>

      </div>

      <div className="col col-12 p-0 m-0 pt-1">

        <Badge icon="trash" id="input-eventtoclone-ref" bg="danger-90" text={event.ref} copyclipboard={true}/>

      </div>

      <div className="col Col-12 p-0 m-0 pt-1">
        <Input type="password" name="password" autoComplete="new-password" label={intl.formatMessage({id: "text.admin.confirmation.password"})}
          ////
          value={deleteEvent.password || ""} onChange={(name, value) => {
            setDeleteEvent({
              ...deleteEvent,
              ["password"]: value
            })
          }}/>
      </div>
    </div>

    <div className="row text-center p-0 m-0 pt-3">
      <div className="col col-12 p-0 m-0">
        <Button start={true} color="danger" loading={loading} icon="trash"
          ////
          message={intl.formatMessage({id: "text.admin.event.confirmdeleteevent"})} action={handleDelete}/>
      </div>
    </div>

  </Modal>
}
DeleteEvent.displayName = "DeleteEvent";
export default DeleteEvent;
