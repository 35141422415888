import React, {useState} from 'react'
import classnames from 'classnames';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {Typeahead, Token} from 'react-bootstrap-typeahead';
import {useLoading} from "src/components/nuagike/index"
import "./index.scss"

const NuagikeTypehead = ({
  key,
  enumName,
  selected,
  disabled,
  required,
  className,
  prependIcon,
  onChange,
  prependIconClassName,
  label,
  invalid,
  type,
  value,
  options,
  isLoading,
  ...props
}) => {
  const loading = false;// useLoading();
  const ref = React.createRef();

  return <div className={classnames("nuagike-input form-floating m-0 p-0 mt-1 d-flex align-items-center",
    //
    {
      ["nuagike-input-disabled"]: disabled || loading || props.loading
    }, {"d-none": props.hidden})}>
    {
      prependIcon
        ? <div className="nuagike-input-prependicon d-flex align-items-center ms-0 rounded-start">
            <FontAwesomeIcon icon={prependIcon} className={prependIconClassName}/></div>
        : ""
    }
    <Typeahead ref={ref} defaultOpen={false}
      isLoading={(isLoading || props.loading || loading) ? true : false}
      selected={selected} key={"nuagike-typeahead-" + props.id} /**/
      onKeyDown={(e) => {
        if (ref.current.props && ref.current.props.allowNew) {
          if (e.keyCode === 13) {
            ref.current._handleSelectionAdd(ref.current.state.text);
          } else {
            ref.current._handleClick(e);
          }
        }
      }} onChange={(values)=>{
        onChange(values, ref.current);
      }} disabled={disabled} clearButton={true} className={classnames("form-control", {
        "is-invalid": invalid
      }, {
        "rounded-0 rounded-end": prependIcon
      }, className)} flip={true} /***/

      options={options} {...props}/>{
      label
        ? <label className={classnames("form-label text-truncate", {"form-label-prependicon": prependIcon})} htmlFor={props.id}>
            {label}<FontAwesomeIcon icon="sun" className="ms-1 text-danger" hidden={!required}/></label>
        : ""
    }</div>
}

export default React.memo(NuagikeTypehead);
