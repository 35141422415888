import React from 'react'
import classnames from 'classnames';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {useIntl} from 'react-intl';
import ReactQuill from 'react-quill';
import EditorToolbar, {modules, formats} from "./QuillToolbar";

import "./quill.nuagike.scss"

const NuagikeRichText = ({
  onChange,
  value,
  ...props
}) => {
  const reactQuillRef = React.useRef(null);
  const [text, setText] = React.useState("");

  React.useEffect(() => {

    if (value && !text) {
      setText(value);
    }

  }, [value])

  return <div className={classnames("row p-0 m-0 form-control mt-1 bg-info-90",
  {
    "d-none": props.hidden
  }, {
    "prepended": props.prepend
  }, {
    "mt-1": !props.normargin
  }, props.className)} key={"quill-" + props.id + "-" + props.name}>
    <div
      className={classnames("nuagike-input nuagike-input-richtext p-0 m-0 border-none overflow-hidden d-flex align-items-center")}>
      <FontAwesomeIcon icon="paragraph" className="ms-1"/> {
        props.label
          ? <label className={classnames("form-label form-check-label text-truncate w-100 mb-0 ps-2 py-2")} htmlFor={props.id}>
              {props.label}<FontAwesomeIcon icon="sun" className="ps-1 text-danger" hidden={!props.required}/></label>
          : ""
      }
    </div>
    <div className="col col-12 p-0 m-0 text-editor">

      <ReactQuill ref={reactQuillRef} theme="snow" className={classnames("", {"ql-error": props.invalid})}
        /////////
        modules={{
          "toolbar" : [
            [
              "bold", "italic", "underline"
            ],
            [
              {
                list: "ordered"
              }, {
                list: "bullet"
              }, {
                indent: "-1"
              }, {
                indent: "+1"
              }
            ]
          ]
        }}
        //
        value={text}
        ////////////
        onChange={(newValue, delta, source) => {
          if (source === 'user') {
            setText(newValue);
            onChange(props.name, newValue);
          }
        }}
        //////
        disabled={props.disabled || props.loading}
        ///////
        readOnly={props.disabled || props.loading || props.readOnly || false}
        ////////
        placeholder={props.placeholder || props.label} onScroll={(args) => {
          if (props.onScroll) {
            props.onScroll(args)
          }
        }}/>
    </div>
  </div>

}

export default NuagikeRichText;
