import React, {useState, useEffect, useMemo} from 'react'
import classnames from "classnames"
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import "./index.scss";

const Alert = (args) => {

  return <div className={classnames("row nuagike-alert d-flex align-items-center m-0 p-1 py-1 alert overflow-hidden", "alert-" +
  (args.alert || "light"), {
      "d-none": args.hidden
    }, args.className)}>

    <div className={classnames("col col-12 d-flex align-items-center ps-1 text-break break-word",
    {["fw-bold"]: args.children})}>
      <FontAwesomeIcon icon={args.icon
          ? args.icon
          : args.alert === "warning"
            ? "exclamation-circle"
            : args.alert === "danger"
              ? "exclamation-triangle"
              : "lightbulb"} className={classnames("fa-fw m-0 p-0", {"d-none": args.hideIcon})}/>
      <span className="d-flex align-items-center p-0 m-0 ps-1 text-wrap ">{args.text}</span>
    </div>
    {
      args.children
        ? <span className="col col-12 p-0 m-0 ps-1 d-flex align-items-center">
            {args.children}
          </span>
        : ""
    }
  </div>

}

export default React.memo(Alert);
