import React, {useState, useEffect, useMemo} from 'react'

import {
  Table,
  Modal,
  Input,
  Page,
  useResource,
  NuagikeContext,
  useErrors
} from 'src/components/nuagike/index'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {FormattedMessage, useIntl} from 'react-intl';
import classnames from "classnames";

const ReportEvent = ({event, onReport, onClose, show}) => {
  const intl = useIntl();
  const {enums} = React.useContext(NuagikeContext);
  const [reportEvent, setReportEvent] = React.useState({
    startDate: null,
    endDate: null,
    now: true,
    forever: true,
    notifyParticipants: false,
    emailRef: null
  })
  const errors = useErrors("event_status");

  const handleChanges = (name, value) => {

    setReportEvent(reportEvent => ({
      ...reportEvent,
      [name]: value
    }))
  }

  const handleSwitchChanges = (name, value) => {

    setReportEvent(reportEvent => ({
      ...reportEvent,
      [name]: !reportEvent[name]
    }))
  }

  const handleReportEvent = () => {
    onReport(reportEvent, event.ref)
  }

  return <Modal icon="forward" size="lg" title={intl.formatMessage({id: "text.admin.event.postpone.title"})} show={show} onSave={() => {
      handleReportEvent()
    }} onClose={() => {
      onClose()
    }}>
    <div className="row p-0 m-0 mt-2">
      <div className="col p-0 m-0 col-12">
        <p>{intl.formatMessage({id: "text.admin.event.postpone.description"})}</p>
      </div>
    </div>
    <div className="row p-0 m-0">
      <div className="col col-lg-6 p-0 m-0 pe-1">

        <Input type="date" required={true} id="input-event-startDate" name="startDate" onChange={(name, value) => {
            handleChanges('startDate', value);
            handleChanges('endDate', reportEvent.endDate)
          }} value={reportEvent.startDate} label={intl.formatMessage({id: "text.admin.event.postpone.eventstartdate"})}
          //
          invalid={errors.find("", "startDate")}/>

      </div>
      <div className="col col-lg-6 p-0 m-0 ps-1">

        <Input type="date" required={true} id="input-event-endDate" name="endDate" onChange={(name, value) => {

            handleChanges('startDate', reportEvent.startDate);
            handleChanges('endDate', value);
          }} value={reportEvent.endDate} label={intl.formatMessage({id: "text.admin.event.postpone.eventenddate"})}
          //
          invalid={errors.find("", "endDate")}/>

      </div>
    </div>

    <div className="row p-0 m-0">
      <div className="col p-0 m-0 col-12">
        <Input type="switch" name="notifyParticipants" id="event.postpone.notifyParticipants" value={reportEvent.notifyParticipants}
          //
          label={intl.formatMessage({id: "text.admin.event.postpone.notifyparticipants.label"})} size="md" onChange={handleSwitchChanges}/>
      </div>
    </div>

    <div className="row p-0 m-0">
      <div className="col p-0 m-0 col-12">

        <Input type="select" name="emailRef" id="event.postpone.emailtemplate" required={true} label={intl.formatMessage({id: "text.admin.event.postpone.emailtemplate"})}
          //
          disabled={!reportEvent.notifyParticipants} value={reportEvent.emailRef || ""} onChange={handleChanges} invalid={false}>

          <option>{intl.formatMessage({id: "text.admin.event.postpone.emailtemplate.choose"})}</option>

        </Input>
      </div>
    </div>

  </Modal>
}
ReportEvent.displayName = "ReportEvent";
export default ReportEvent;
