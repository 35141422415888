import React, {useEffect} from "react";
import {Table, Loading, useResource, useLoading} from "src/components/nuagike/index"
import {deepCompare} from "src/tools/index"

const NuagikeSearchTable = ({resourceName, columns, search, filterFunction}) => {

  const {search: searchResources, update: updateResource, page: page, clear} = useResource(resourceName);

  const defaultSearch = {
    pageSize: 10,
    page: 0,
    sortBy: "creationDate",
    sortDirection: "desc",
    filters: {}
  };

  const [currentSearch, setCurrentSearch] = React.useState(false);

  const updatePagination = React.useCallback(({index, size, callback}) => {
    if (page.page !== index || page.pageSize !== size) {
      console.log({
        'updatePagination': {
          index,
          size
        }
      });

      let searchForm = {
        ...page,
        ["page"]: index,
        ["pageSize"]: size
      };
      searchResources(objectWithoutProperties(searchForm, ["results", "date", "count", "nbPages"]));
    }
  }, [page]);

  const updateSortedBy = React.useCallback(({by, direction}) => {
    if (search) {
      if (page.sortBy !== by || page.sortDirection !== direction) {
        console.log({
          'updateSortedBy': {
            by,
            direction
          }
        });

        let searchForm = {
          ...page,
          ["sortBy"]: by,
          ["sortDirection"]: direction
        };
        searchResources(objectWithoutProperties(searchForm, ["results", "date", "count", "nbPages"]));
      }
    }
  }, [page]);

  const updateFilters = React.useCallback(({filters}) => {
    console.log({"##############################": filters});
    if (search) {
      let sbF = {};
      filters && filters.map(filter => {
        sbF[filter.id] = filter.value;
      });

      let isFilterChanged = !deepCompare(page.filters || {}, sbF);

      console.log({
        'updateFilters': {
          search,
          "changed ?": isFilterChanged,
          "old": page.filters,
          "new": sbF
        },
        page
      });

      if (isFilterChanged) {
        let searchForm = {
          ...page,
          "page": 0,
          "filters": {
            ...sbF
          }
        };
        searchResources(objectWithoutProperties(searchForm, ["results", "date", "count", "nbPages"]));
      }
    }
  }, [page]);

  const updateFromTable = React.useCallback((index, name, value, saveNow, original) => {
    //console.log({original});
    updateResource(original.ref, {
      ref: original.ref,
      [name]: value,
      etag: original.etag
    });
  }, [updateResource]);

  React.useEffect(() => {
    if (search) {
      let nextSearchShouldBe = {
        ...defaultSearch,
        ...search
      }
      if (!deepCompare(currentSearch, nextSearchShouldBe)) {
        setCurrentSearch(currentSearch => nextSearchShouldBe);
      }
    } else {
      clear()
    }
  }, [search]);

  React.useEffect(() => {
    if (currentSearch) {
      //console.log({"-------------------------------------------------------- SEARCH AGAIN WITH ": currentSearch});
      searchResources(currentSearch);
    }else{
      clear()
    }
  }, [currentSearch]);
  /*
  console.log({
    "READY": page.results
      ?.length,
    [page?.date +  " render search table "]: {
      columns,
      filters,
      search,
      filterFunction
    }
  });
*/
  const mainArea = React.useMemo(
    () => <Table
      updateData={updateFromTable}
      columns={columns}
      data={page.results || []}
      customTotalSize={page.count || 0}
      customPageCount={page.nbPages || 0}
      customPageSize={page.pageSize || 10}
      customPageIndex={page.page || 0}
      customSortBy={page.sortBy}
      customSortDirection={page.sortDirection}
      customFilters={search?.filters}
      totalSize={page.count || 0}
      updateFilters={updateFilters}
      updateSortedBy={updateSortedBy}
      updatePagination={updatePagination}
      manualPagination={true}/>,
    [page.date]
  );

  return columns && page.date
    ? mainArea
    : null
}

function filterData({data, filterFunction}) {
  if (Array.isArray(data)) {
    if (filterFunction) {
      console.log("data changed with filterFunction");
      return data.slice().filter(item => filterFunction(item));
    } else {
      console.log("data changed without filterFunction");
      return data
    }
  } else {
    return []
  }
}

function objectWithoutProperties(obj, keys) {
  var target = {};
  for (var i in obj) {
    if (keys.indexOf(i) >= 0)
      continue;
    if (!Object.prototype.hasOwnProperty.call(obj, i))
      continue;
    target[i] = obj[i];
  }
  return target;
}

export default NuagikeSearchTable;
