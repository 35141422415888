import React, {useState} from 'react'
import classnames from 'classnames';
import {useDispatch, useSelector} from 'react-redux'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'

const TimezoneBadge = (props) => {
  const countries = useSelector((state) => state.config.repositories.countries)
  const [country, setCountry] = React.useState(null)

  React.useEffect(() => {
    if (countries && props.code) {
      countries.filter(c => c.cca2.toLowerCase() === props.code.toLowerCase()).map(c => {
        setCountry({
          ...c
        })
      })
    }

  }, [countries, props.code]);

  return <div className="badge nuagike-badge text-dark p-1 d-flex fw-normal align-items-center float-start overflow-hidden w-100">
    <FontAwesomeIcon icon="globe" className="opacity-50 me-1 fa-fw"/><span className="fs-6 d-inline-block align-items-center flex-grow-1 text-truncate text-start">{props.code}</span>
  </div>

}

export default React.memo(TimezoneBadge);
