import React, {useState, useEffect, useMemo} from 'react'

import {
  Table,
  Badge,
  Modal,
  Input,
  Page,
  useResource,
  useEnums,
  useErrors,
  useLoading,
  NuagikeContext
} from 'src/components/nuagike/index'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {FormattedMessage, useIntl} from 'react-intl';
import classnames from "classnames";

const CloneEvent = ({event, onClone, onClose, show}) => {
  const intl = useIntl();
  const [eventToClone, setEventToClone] = React.useState({...event});
  const {enums} = React.useContext(NuagikeContext);
  const loading = useLoading();

  const [cloneEvent, setCloneEvent] = React.useState({
    eventRef: event.ref,
    venues: true,
    tickets: true,
    title: "",
    medals: false,
    forms: true,
    startDate: "",
    endDate: ""
  }
);

  const errors = useErrors("event");

  const handleChanges = (name, value) => {
    setCloneEvent(cloneEvent => ({
      ...cloneEvent,
      [name]: value
    }))
  }

  const handleSwitchChanges = (name, value) => {
    setCloneEvent(cloneEvent => ({
      ...cloneEvent,
      [name]: !cloneEvent[name]
    }))
  }

  const handleSave = () => {
    onClone(cloneEvent, event.ref);
  }

  return event ? <Modal icon="clone" size="lg" loading={loading} title={intl.formatMessage({id: "text.admin.event.clone.title"})}
  show={show} onSave={handleSave} onClose={onClose}>

    <div className="row p-0 m-0">

      <div className="col col-12 p-0 m-0 ">

        <Badge icon="copy" id="input-eventtoclone-ref" bg="info-90" text={event.title} copyclipboard={true}/>

      </div>

      <div className="col col-12 p-0 m-0 pt-1">

        <Badge icon="copy" id="input-eventtoclone-ref" bg="info-90" text={event.ref} copyclipboard={true}/>

      </div>
    </div>

    <div className="row p-0 m-0">
      <div className="col col-lg-12 p-0 m-0">

        <Input type="text" required={true} id="input-event-title" name="title" onChange={handleChanges} value={cloneEvent.title || ""} label={intl.formatMessage({id: "text.admin.event.title"})}
          //
          invalid={errors.find("clone", "title")}/>

      </div>
    </div>

    <div className="row p-0 m-0">
      <div className="col col-lg-6 p-0 m-0 pe-1">

        <Input type="date" required={true} id="input-event-startDate" name="startDate" onChange={(name, value) => {
            handleChanges('startDate', value);
            handleChanges('endDate', cloneEvent.endDate)
          }} value={cloneEvent.startDate || ""} label={intl.formatMessage({id: "text.admin.event.startdate"})}
          //
          invalid={errors.find("clone", "startDate")}/>

      </div>
      <div className="col col-lg-6 p-0 m-0">

        <Input type="date" required={true} id="input-event-endDate" name="endDate" onChange={(name, value) => {

            handleChanges('startDate', cloneEvent.startDate);
            handleChanges('endDate', value);
          }} value={cloneEvent.endDate || ""} label={intl.formatMessage({id: "text.admin.event.enddate"})}
          //
          invalid={errors.find("clone", "endDate")}/>

      </div>
    </div>

    <div className="row p-0 m-0 mt-3">
      <div className="col p-0 m-0 col-12">
        <p>{intl.formatMessage({id: "text.admin.event.clone.itemstoclone"})}</p>
      </div>
    </div>
    <div className="row p-0 m-0">
      <div className="col p-0 m-0 col-12">
        <Input type="switch" name="venues" id="event.clone.venues" value={cloneEvent.venues || false} label={intl.formatMessage({id: "text.admin.event.clone.venues.label"})} size="md" onChange={handleSwitchChanges}/>
      </div>
    </div>
    <div className="row p-0 m-0">
      <div className="col p-0 m-0 col-12">
        <Input type="switch" name="tickets" id="event.clone.tickets" value={cloneEvent.tickets || false} label={intl.formatMessage({id: "text.admin.event.clone.tickets.label"})} size="md" onChange={handleSwitchChanges}/>
      </div>
    </div>
    <div className="row p-0 m-0">
      <div className="col p-0 m-0 col-12">
        <Input type="switch" name="medals" id="event.clone.medals" value={cloneEvent.medals || false} label={intl.formatMessage({id: "text.admin.event.clone.medals.label"})} size="md" onChange={handleSwitchChanges}/>
      </div>
    </div>
    <div className="row p-0 m-0">
      <div className="col p-0 m-0 col-12">
        <Input type="switch" name="forms" id="event.clone.forms" value={cloneEvent.forms || false} label={intl.formatMessage({id: "text.admin.event.clone.forms.label"})} size="md" onChange={handleSwitchChanges}/>
      </div>
    </div>
  </Modal> : ""
}
CloneEvent.displayName = "CloneEvent";
export default CloneEvent;
