import React, {useState, useEffect, useMemo} from 'react'

import {Table, Modal, Input, Page, NuagikeContext, useErrors, useResource} from 'src/components/nuagike/index'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {FormattedMessage, useIntl} from 'react-intl';
import classnames from "classnames";


const CancelEvent = ({event, onCancel, onClose, show}) => {
  const intl = useIntl()

  const {enums} = React.useContext(NuagikeContext);

  const [cancelEvent, setCancelEvent] = React.useState(
    {startDate: null, endDate: null, now: true, forever:true, notifyParticipants: false, emailRef: null})
  const errors = useErrors("event_status");

  const eventResource = useResource("event", false);

  React.useEffect(()=>{
    if(event.status === "CANCELED"){
      //onClose();
    }
  }, [event.status])

  const handleChanges = (name, value) => {

    setCancelEvent(cancelEvent => ({
      ...cancelEvent,
      [name]: value
    }))
  }

  const handleSwitchChanges = (name, value) => {
    setCancelEvent(cancelEvent => ({
      ...cancelEvent,
      [name]: !cancelEvent[name]
    }))
  }

  const handleCancel = () => {
    onCancel(cancelEvent, event.ref)
  }

  return <Modal icon="ban" size="lg" title={intl.formatMessage({id: "text.admin.event.cancel.title"})} show={show} onSave={handleCancel} onClose={onClose}>
    <div className="row p-0 m-0 mt-1">
      <div className="col p-0 m-0 col-12">
        <p>{intl.formatMessage({id: "text.admin.event.cancel.description"})}</p>
      </div>
    </div>

    <div className="row p-0 m-0">
      <div className="col p-0 m-0 col-12">
        <Input type="switch" name="notifyParticipants" id="event.cancel.notifyParticipants" value={cancelEvent.notifyParticipants}
          //
          label={intl.formatMessage({id: "text.admin.event.cancel.notifyparticipants.label"})} size="md" onChange={handleSwitchChanges}/>
      </div>
    </div>

    <div className="row p-0 m-0">
      <div className="col p-0 m-0 col-12">

        <Input type="select" name="emailRef" id="event.cancel.emailtemplate"
        required={true} label={intl.formatMessage({id: "text.admin.event.cancel.emailtemplate"})}
          //
          disabled={!cancelEvent.notifyParticipants} value={cancelEvent.emailRef || ""}
          onChange={handleChanges}
          invalid={false}>

          <option>{intl.formatMessage({id: "text.admin.event.cancel.emailtemplate.choose"})}</option>

        </Input>
      </div>
    </div>

  </Modal>
}

CancelEvent.displayName = "CancelEvent";

export default CancelEvent;
