import React from 'react'
import {call} from "redux-saga/effects";
import {
  get,
  post,
  postFormData,
  patch,
  put,
  remove
} from 'src/tools/index'
import resources from 'src/config/resources'

export default class NuagikeHTTPClient {

  resource_config = {
    uri: null
  }

  constructor(type, parentRef) {
    let typeConfig = resources[type];
    if (!typeConfig) {
      console.error("no resource configured for [" + type + "]");
      this.resource_config = {
        uri: null
      }
    } else {
      this.resource_config = {
        ...typeConfig
      }
      if (parentRef) {
        const parent_resource = typeConfig.parent;
        this.resource_config.uri = typeConfig.uri.replace("${" + parent_resource + "Ref}", parentRef);
      }
    }
  }

  load(ref) {
    return call(get, this.resource_config.uri + `/${ref}`, true)
  }

  loadAll(filter) {
    return call(get, this.resource_config.uri + ( filter ? `/${filter}` : ''));
  }

  replaceAll(payload, partialMode) {
    return call(partialMode ? patch : put, this.resource_config.uri, payload);
  }

  search(search) {
    return call(post, this.resource_config.uri + "/search", search, true);
  }

  update(ref, payload) {
    return call(patch, this.resource_config.uri + `/${ref}`, payload, true);
  }

  replace(ref, payload) {
    return call(put, this.resource_config.uri + `/${ref}`, payload);
  }

  add(payload) {

    if (payload && payload.formData) {
      return call(postFormData, this.resource_config.uri, payload.formData, true);
    }
    return call(post, this.resource_config.uri, payload, true);

  }

  remove(ref, password) {
    let data = {}
    if(password){
      data.ref = ref;
      data.password = password;
    }
    return call(remove, this.resource_config.uri + `/${ref}`, data, true);
  }

  lock(ref, payload) {
    return call(post, this.resource_config.uri + `/${ref}` + "/lock", payload, true);
  }

  clone(ref, payload) {
    return call(post, this.resource_config.uri + `/${ref}` + "/clone", payload);
  }

}
