import React from 'react'
import ConfirmAction from "src/components/event/confirm/index"
import {confirm as confirm_action} from "src/redux/actions/index"
import {useSelector, useDispatch} from 'react-redux'

function useAction(props) {
  const dispatch = useDispatch();

  const confirm = ({callback, message}) => {
    dispatch(confirm_action({
      action: {
        callback: callback,
        message: message
      }
    }))
  }

  return {confirm}
}

export default useAction;
