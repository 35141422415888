import React, {useState} from 'react'
import classnames from 'classnames';
import {useLoading, NuagikeContext} from "src/components/nuagike/index"
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {useIntl} from 'react-intl';

const NuagikeSelect = ({
  enumName,
  disableOptionOn,
  hideOptionOn,
  onClick,
  ...props
}) => {
  const loading = false; //useLoading();
  const intl = useIntl();
  const {enums} = React.useContext(NuagikeContext)
  const enumValues = enums[enumName];

  return <select onClick={onClick} className={classnames("form-select text-truncate", {
      ["opacity-50"]: !props.value
    }, {
      "is-invalid": props.invalid
    }, {
      "rounded-0 rounded-end": props.prependIcon
    }, props.className)} placeholder={props.placeholder
      ? props.placeholder
      : props.label} id={props.id}
    //value
    value={props.value}
    // onchange
    onChange={(e) => props.onChange(props.name, e.target.value)} disabled={props.disabled || loading || props.loading}>
    {
      enumName
        ? <> <option value = "" className = "text-grise-3" > {
          intl.formatMessage({
            id: "text.admin.default.chooseitem"
          })
        }
        </option>

          {
          enumValues && enumValues.map((enumToken, enumTokenIdx) => {
            return !(hideOptionOn && hideOptionOn(enumToken))
              ? <option className={classnames(
                  //
                  {
                    "text-grise-1": disableOptionOn && disableOptionOn(enumToken)
                  },
                  ////
                  {
                    "text-primary": !disableOptionOn || !disableOptionOn(enumToken)
                  })} disabled={disableOptionOn && disableOptionOn(enumToken)} value={enumToken} key={"option-enumindex-" + enumTokenIdx}>{
                    intl.formatMessage({
                      id: "text.enum." + enumName + "." + enumToken
                    })
                  }</option>
              : '';
          })
        }
        </>
        : props.children
    }
  </select>
}

export default React.memo(NuagikeSelect);
