import React, {useState} from 'react'

const delay = ms => new Promise(res => setTimeout(res, ms));

function useCopyToClipboard() {
  const [copiedText, setCopiedText] = React.useState(null)

  React.useEffect(() => {
    if (copiedText) {
      delay(2000).then(() => {
        setCopiedText(null)
      });

    }
  }, [copiedText]);

  const copy = async text => {
    if (
      !navigator
      ?.clipboard) {
      console.warn('Clipboard not supported')
      return false
    }

    // Try to save to clipboard then save it in the state if worked
    if (text) {
      try {
        await navigator.clipboard.writeText(text)
        setCopiedText(text)
        return true;
      } catch (error) {
        console.warn('Copy failed', error)
        setCopiedText(null)
        return false;
      }
    } else {
      setCopiedText(null);
      return true;
    }
  }

  const clear = async () => {
    try {
      await navigator.clipboard.writeText(null)
      setCopiedText(null)
      return true;
    } catch (error) {
      console.warn('Copy failed', error)
      setCopiedText(null)
      return false;
    }
  }

  return [copiedText, copy, clear]
}

export default useCopyToClipboard
