import React, {useState} from 'react'
import classnames from 'classnames';
import {Link} from 'react-router-dom'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'

const PrependBadge = ({icon, text, ...props}) => {

  return <div className={classnames("d-flex align-items-center m-0 p-0 h-100 text-truncate", props.className)}>
    {
      icon
        ? <FontAwesomeIcon icon={icon} className="fa-fw me-1 p-0 float-start opacity-50"/>
        : ""
    }
    {
      text
        ? <span className="d-flex align-items-center m-0 p-0 pe-2 opacity-50">{text + " : "}</span>
        : ""
    }

  </div>

}

export default React.memo(PrependBadge);
