import React, {useState} from 'react'
import {Tabs, Tab} from 'react-bootstrap'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import "./index.scss"

function NavTabs({id, defaultActiveTab, items, ...props}) {
  const [activeTab, setActiveTab] = useState(defaultActiveTab)

  const getTitle = (item) => {
    return <div><FontAwesomeIcon icon={item.icon || "hashtag"} className="me-1 opacity-75"/>
      <span className="text-truncate">{item.title}</span>
    </div>
  }

  return <div className="nuagike-navigation mb-1 bg-white">
    <Tabs id={id ||"controlled-tab"} activeKey={activeTab} onSelect={(k) => setActiveTab(k)} className="mb-1" mountOnEnter={true}>

      {
        items && items.filter(item=>!item.hidden).map((item, index) => {
          return <Tab eventKey={item.key} title={getTitle(item)} mountOnEnter={true} key={"tab-" + index}>
            <item.component {...props}/>
          </Tab>
        })
      }
    </Tabs>
  </div>

}

export default NavTabs;
