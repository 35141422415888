import {

  clearResources,

  selectRef,
  selectResource,
  selectResources,

  loadResource,
  loadResourceSuccess,
  loadResourceFromCache,
  loadResourceError,

  loadResources,
  loadResourcesSuccess,
  loadResourcesError,

  searchResources,
  searchResourcesSuccess,
  searchResourcesError,

  replaceResources,
  replaceResourcesSuccess,
  replaceResourcesError,

  cloneResource,
  cloneResourceSuccess,
  cloneResourceError,

  updateResource,
  updateResourceSuccess,
  updateResourceError,

  addResource,
  addResourceSuccess,
  addResourceError,

  removeResource,
  removeResourceSuccess,
  removeResourceError
} from "src/redux/actions"

import resources_config from "src/config/resources"
import initialState from "src/redux/initialState"

function resourcesReducer(builder) {

  builder.addCase(selectRef, (state, action) => {
    const resource_config = resources_config[action.payload.type];
    if (resource_config.store.type === "state" && state.resources[action.payload.type]['data']['results']) {
      let selected = state.resources[action.payload.type]['data']['results'].filter(item => item.ref === action.payload.ref);
      if (selected && selected[0]) {
        state.resources[action.payload.type]['current'] = selected[0];
      } else {
        state.resources[action.payload.type]['current'] = {};
      }
    }
  }).addCase(clearResources, (state, action) => {
    const resource_config = resources_config[action.payload.type];
    if (resource_config.store.type === "state") {
      state.resources[action.payload.type] = initialState.resources[action.payload.type];
    } else if (resource_config.store.type === "override_parent") {
      const parent_resource_type = resource_config.parent;
      state.resources[parent_resource_type] = initialState.resources[parent_resource_type];
    }
  }).addCase(selectResources, (state, action) => {
    const resource_config = resources_config[action.payload.type];
    if (resource_config.store.type === "state") {
      state = replaceStateResults(state, action.payload.type, action.payload.resources)
    }
  }).addCase(selectResource, (state, action) => {
    const resource_config = resources_config[action.payload.type];
    if (resource_config.store.type === "state") {
      state.resources[action.payload.type]['current'] = action.payload.resource;
    } else if (resource_config.store.type === "override_parent") {
      const parent_resource_type = resource_config.parent;
      state.resources[parent_resource_type]['current'] = action.payload.resource;
    }
  }).addCase(addResource, (state, action) => {
    state.loading = state.loading + 1;
    state.errors.resources[action.payload.type] = {
      ...initialState.errors.resources[action.payload.type]
    }
  }).addCase(addResourceError, (state, action) => {
    state.loading = state.loading > 0
      ? (state.loading - 1)
      : 0;
    state.errors.resources[action.payload.type]["new"] = action.payload.error;

  }).addCase(addResourceSuccess, (state, action) => {
    state.loading = state.loading > 0
      ? (state.loading - 1)
      : 0;

    const resource_config = resources_config[action.payload.type];
    if (resource_config.store.type === "state") {
      state = findAndReplaceIntoStateResults(state, action.payload.type, action.payload.resource)
      state.resources[action.payload.type]['current'] = action.payload.resource;
    } else if (resource_config.store.type === "override_parent") {
      const parent_resource_type = resource_config.parent;
      state = findAndReplaceIntoStateResults(state, parent_resource_type, action.payload.resource);
      state.resources[parent_resource_type]['current'] = action.payload.resource;
    }
  }).addCase(updateResource, (state, action) => {
    state.loading = state.loading + 1;
    state.errors.resources[action.payload.type] = {
      ...initialState.errors.resources[action.payload.type]
    }
  }).addCase(updateResourceError, (state, action) => {
    state.loading = state.loading > 0
      ? (state.loading - 1)
      : 0;
    state.errors.resources[action.payload.type][action.payload.ref] = action.payload.error;
  }).addCase(updateResourceSuccess, (state, action) => {
    state.loading = state.loading > 0
      ? (state.loading - 1)
      : 0;

    const resource_config = resources_config[action.payload.type];
    if (resource_config.store.type === "state") {
      state = findAndReplaceIntoStateResults(state, action.payload.type, action.payload.resource)
      state.resources[action.payload.type]['current'] = action.payload.resource;

      if (state.resources[action.payload.type]['data']) {
        state.resources[action.payload.type]['data'].date = Date.now()
      }
    }
  }).addCase(cloneResource, (state, action) => {
    state.loading = state.loading + 1;
    state.errors.resources[action.payload.type] = {
      ...initialState.errors.resources[action.payload.type]
    }
  }).addCase(cloneResourceError, (state, action) => {
    state.loading = state.loading > 0
      ? (state.loading - 1)
      : 0;
    const resource_config = resources_config[action.payload.type];
    if (resource_config.store.type === "state") {
      state.errors.resources[action.payload.type]['clone'] = action.payload.error;
    }
  }).addCase(cloneResourceSuccess, (state, action) => {
    state.loading = state.loading > 0
      ? (state.loading - 1)
      : 0;
    const resource_config = resources_config[action.payload.type];
    if (resource_config.store.type === "state") {
      state = findAndReplaceIntoStateResults(state, action.payload.type, action.payload.resource)
    }
  }).addCase(replaceResources, (state, action) => {
    state.loading = state.loading + 1;
    state.errors.resources[action.payload.type] = {
      ...initialState.errors.resources[action.payload.type]
    }
  }).addCase(replaceResourcesSuccess, (state, action) => {
    state.loading = state.loading > 0
      ? (state.loading - 1)
      : 0;

    const resource_config = resources_config[action.payload.type];

    if (resource_config.store.type === "state") {
      state = findAndReplaceIntoStateResults(state, action.payload.type, action.payload.result);
    } else if (resource_config.store.type === "override_parent") {
      const parent_resource_type = resource_config.parent;
      state = findAndReplaceIntoStateResults(state, parent_resource_type, action.payload.result);
    }
  }).addCase(replaceResourcesError, (state, action) => {
    state.loading = state.loading > 0
      ? (state.loading - 1)
      : 0;
    state.errors.resources[action.payload.type]['data'] = action.payload.error

  }).addCase(searchResources, (state, action) => {
    state.loading = state.loading + 1;
    state.errors.resources[action.payload.type] = {
      ...initialState.errors.resources[action.payload.type]
    }
  }).addCase(searchResourcesError, (state, action) => {
    state.loading = state.loading > 0
      ? (state.loading - 1)
      : 0;

    const resource_config = resources_config[action.payload.type];
    if (resource_config.store.type === "state") {
      state.errors.resources[action.payload.type]['data'] = action.payload.error
    }
  }).addCase(searchResourcesSuccess, (state, action) => {
    state.loading = state.loading > 0
      ? (state.loading - 1)
      : 0;

    const resource_config = resources_config[action.payload.type];
    if (resource_config.store.type === "state") {
      state.resources[action.payload.type]['data'] = {};

      if (action.payload.resources && action.payload.resources.countOnly) {
        //Nothing to update
      } else if (action.payload.resources && 'results' in action.payload.resources) {
        state.resources[action.payload.type]['data'] = {
          ...initialState.resources[action.payload.type]['data'],
          ...action.payload.resources
        }
      } else {
        state.resources[action.payload.type]['data'] = {
          ...initialState.resources[action.payload.type]['data'],
          ['results']: action.payload.resources
        }
      }
    }
  }).addCase(loadResources, (state, action) => {
    state.loading = state.loading + 1;
    state.errors.resources[action.payload.type] = {
      ...initialState.errors.resources[action.payload.type]
    }
  }).addCase(loadResourcesError, (state, action) => {
    state.loading = state.loading > 0
      ? (state.loading - 1)
      : 0;

    const resource_config = resources_config[action.payload.type];
    if (resource_config.store.type === "state") {
      state.errors.resources[action.payload.type]['data'] = action.payload.error
    }
  }).addCase(loadResourcesSuccess, (state, action) => {

    state.loading = state.loading > 0
      ? (state.loading - 1)
      : 0;

    const resource_config = resources_config[action.payload.type];

    if (resource_config.store.type === "state") {
      if (Array.isArray(action.payload.resources)) {
        state.resources[action.payload.type]['data'] = action.payload.resources;
      } else if (action.payload.resources && Array.isArray(action.payload.resources.results)) {
        state.resources[action.payload.type]['data'] = action.payload.resources;
      } else if (action.payload.resources) {
        state.resources[action.payload.type]['current'] = action.payload.resources;
      } else {
        state.resources[action.payload.type]['data'] = {
          ...initialState.resources[action.payload.type]['data']
        };
      }
    }

  }).addCase(loadResource, (state, action) => {
    state.loading = state.loading + 1;
    state.errors.resources[action.payload.type] = {
      ...initialState.errors.resources[action.payload.type]
    }
  }).addCase(loadResourceError, (state, action) => {
    state.loading = state.loading > 0
      ? (state.loading - 1)
      : 0;

    const resource_config = resources_config[action.payload.type];
    if (resource_config.store.type === "state") {
      state.errors.resources[action.payload.type][action.payload.ref] = action.payload.error;
    }
  }).addCase(loadResourceSuccess, (state, action) => {
    state.loading = state.loading > 0
      ? (state.loading - 1)
      : 0;
    const resource_config = resources_config[action.payload.type];
    if (resource_config.store.type === "state") {

      if (action.payload.resource && action.payload.resource.ref) {
        state = findAndReplaceIntoStateResults(state, action.payload.type, action.payload.resource)
      }

      state.resources[action.payload.type]['current'] = action.payload.resource;
    }
  }).addCase(loadResourceFromCache, (state, action) => {}).addCase(removeResource, (state, action) => {
    state.loading = state.loading + 1;
  }).addCase(removeResourceError, (state, action) => {
    state.loading = state.loading > 0
      ? (state.loading - 1)
      : 0;
  }).addCase(removeResourceSuccess, (state, action) => {
    const resource_config = resources_config[action.payload.type];
    state.loading = state.loading > 0
      ? (state.loading - 1)
      : 0;
    state.errors.resources[action.payload.type] = {
      ...initialState.errors.resources[action.payload.type]
    }
    if (state.resources[action.payload.type] && resource_config.store.type === "state") {
      if (state.resources[action.payload.type]['current'] && action.payload.ref === state.resources[action.payload.type]['current']['ref']) {
        state.resources[action.payload.type]['current'] = {
          ...initialState.resources[action.payload.type]['current']
        }
      }

      if (state.resources[action.payload.type]['data']['results']) {
        state.resources[action.payload.type]['data']['results'] = //
        state.resources[action.payload.type]['data']['results'].filter(res => res.ref !== action.payload.ref);
        state.resources[action.payload.type]['data'].date = Date.now()
      }
    }
  })
}

const replaceStateResults = (state, type, resources) => {
  let resource_replaced = false;
  if ('results' in state.resources[type]['data']) {
    state.resources[type]['data'] = {
      ...initialState.resources[type]['data']
    };
    if (resources && 'results' in resources) {
      state.resources[type]['data'] = resources;
    } else {
      state.resources[type]['data']['results'] = resources;
    }
  }
  return state;
}
/**
** Permet d'ajouter un élément dans le current et de modifier sa valeur dans results
**/
const findAndReplaceIntoStateResults = (state, type, resource) => {
  let resource_replaced = false;
  if (state.resources[type]['data'] && state.resources[type]['data']['results']) {

    state.resources[type]['data']['results'] = //
    state.resources[type]['data']['results'].map(res => {
      if (res.ref === resource.ref) {
        resource_replaced = true;
        return resource;
      } else {
        return res;
      }
    });

  }
  if (state.resources[type]['current'] && state.resources[type]['current']['ref'] === resource.ref) {
    state.resources[type]['current'] = resource;
    console.log({"resource founded and replaced into state": resource.ref});
  }
  if (resource_replaced) {
    state.resources[type]['data'].date = Date.now();
  } else {
    if(state.resources[type]['data']['results']){
      state.resources[type]['data']['results'] = //
      [
        resource, ...state.resources[type]['data']['results']
      ]
    }else{
      state.resources[type]['data']['results'] = //
      [
        resource
      ]
    }


  }
  return state;
}

export default resourcesReducer;
