import React from "react";
import {Input, Button} from "src/components/nuagike/index"
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import classnames from "classnames"
// Define a default UI for filtering
const DefaultColumnFilter = ({
  column: {
    id,
    filterList,
    filterValue,
    filterClassName,
    setFilter
  }
}) => {

  const [filterTextValue, setFilterTextValue] = React.useState(undefined)

  const checkFilter = (theValue) => {

    console.log("check filter");
    if (theValue) {
      setFilter(theValue)
    } else if (filterValue && filterValue === filterTextValue) {
      setFilter(undefined)
      theValue = undefined
    } else {
      setFilter(filterTextValue || undefined)
      theValue = filterTextValue || undefined
    }
  }

  const handleKeyPress = (event) => {
    if (event.charCode === 13) {
      checkFilter(event.target.value)
      //setFilterTextValue(event.target.value)
    }
  }


  return <div className="d-flex align-items-center w-100 justify-content-beetween px-1 bg-light-70 border border-light-50 border-1 rounded overflow-hidden">
    {
      filterList && filterList.length
        ? <Input type="select" name={"filter_"+id} value={filterValue || ""}
            nomargin={true} nofloating={true}
            //
            className={classnames("flex-grow-1 w-100 border-0 overflow-hidden", filterClassName)}
            ////
            onChange={(name, value) => {
              //setFilter(value || undefined);
              checkFilter(value || undefined)
              // Set undefined to remove the filter entirely
            }}>
            <option value=""></option>
            {
              filterList.map((item, index) => {
                return <option key={"option-filter-" + index + item.value
                    ? item.value
                    : item} value={item.value || item}>{item.text || item}</option>
              })
            }
          </Input>
        : <Input type="text" value={filterValue || ""}
            //
            className={classnames("flex-grow-1 w-100 border-0 ps-1 overflow-hidden", filterClassName)}
            nomargin={true} nofloating={true}
            ////
            onChange={(name, value) => {
              setFilterTextValue(value || undefined)
              // Set undefined to remove the filter entirely
            }} onKeyPress={handleKeyPress}/>
    }
    <Button className="rounded border-0 ps-1 flex-shrink-1" type="icon" icon={filterValue
        ? "times"
        : "filter"} action={() => {
        checkFilter()
      }} color={filterValue
        ? "grise-2"
        : "grise-1"}/>
  </div>
}

export default DefaultColumnFilter;
