import React, {useState} from 'react'
import classnames from 'classnames';
import {Link} from 'react-router-dom'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import formatDate from "date-fns/format";
import PrependBadge from "./prepend"

import DefaultBadge from "./default"

const DateBadge = ({text, value, icon, ...props}) => {

  const [localDate, setLocalDate] = React.useState("")
  const [localTime, setLocalTime] = React.useState("")
  const dateFormat = "dd-MM-yyyy";
  const timeFormat = "HH:mm:ss";

  function dateIsValid(date) {
    return !Number.isNaN(new Date(date).getTime());
  }

  React.useEffect(() => {

    let localText = value || text;

    if (dateIsValid(localText)) {
      let formattedDate = formatDate(new Date(localText), dateFormat);
      setLocalDate(formattedDate);

      if (props.time) {
        let formattedTime = formatDate(new Date(localText), timeFormat);
        setLocalTime(formattedTime);
      }
    }
  }, [props]);

  return (text || value) ? <DefaultBadge icon={icon || "clock"} text={text && !value ? <>{localDate + " "}<em className="fw-light">{localTime}</em></> : text}
  value={value ? <>{localDate + " "}<b className="fw-light">{localTime}</b></> : ""} {...props}/>: ""

}

export default DateBadge;
