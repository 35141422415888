import React from 'react'
import {useIntl} from 'react-intl';
import {Badge} from "src/components/nuagike/index"

const Resume = ({object={}}) => {
  const intl = useIntl();
  return <div className="row p-0 m-0">
    <div className="col col-12 p-0 m-0 pt-1" hidden={!object.creationUserUsername}>
      <Badge  start={true} icon="user" text={intl.formatMessage({id: "text.admin.default.creationuserusername"})}
        // value
        value={object.creationUserUsername} copyclipboard={true}/>
    </div>
    <div className="col col-12 p-0 m-0 pt-1" hidden={!object.creationDate}>
      <Badge start={true} type="datetime" icon="clock" text={intl.formatMessage({id: "text.admin.default.creationdate"})}
        // value
        value={object.creationDate} copyclipboard={true}/>
    </div>
    <div className="col col-12 p-0 m-0 pt-1" hidden={!object.lastModificationUserUsername}>
      <Badge start={true} icon="user" text={intl.formatMessage({id: "text.admin.default.lastmodificationuserusername"})}
        // value
        value={object.lastModificationUserUsername} copyclipboard={true}/>
    </div>
    <div className="col col-12 p-0 m-0 pt-1" hidden={!object.lastModificationDate}>
      <Badge start={true} type="datetime" icon="clock" text={intl.formatMessage({id: "text.admin.default.lastmodificationdate"})}
        // value
        value={object.lastModificationDate} copyclipboard={true}/>
    </div>
  </div>
}

export default Resume;
