import React, {useState} from 'react'
import classNames from 'classnames';
import {Button} from "src/components/nuagike/index"

const SelectItems = (props) => {
  return <div className="row border border-1 border-light border-top-0 border-end-0 border-start-0 pb-2">
    <div className="col-lg-6">
      <div className="form-floating">
        {props.children}
      </div>
    </div>
    <div className="col col-6 d-flex align-items-center">
      <Button action={props.action} icon={props.actionIcon
          ? props.actionIcon
          : "plus-square"} message={props.actionMessage}/>
    </div>
  </div>

}

export default React.memo(SelectItems);
