import React from 'react';
import classnames from 'classnames';

const Loading = React.memo((props) => {
  return <div className={classnames("row p-2 m-0", {
      "d-none": props && props.hidden
    })}>
    <div className={classnames("col col-12 p-0 m-0 d-flex align-items-center justify-content-center", (
        props
        ? props.className
        : ""))}>
      <div className="spinner-border text-info text-center " role="status">
        {
          props && props.text
            ? <span className="sr-only text-center">{props.text}</span>
            : ""
        }
      </div>
    </div>
  </div>
});
Loading.displayName = "Loading";
export default Loading;
