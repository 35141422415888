import {takeEvery, put} from "redux-saga/effects";

import {
  selectRef,

  successMessage,
  errorMessage,

  lockResource,
  lockResourceSuccess,
  lockResourceError,

  loadResource,
  loadResourceSuccess,
  loadResourceFromCache,
  loadResourceError,

  loadResources,
  loadResourcesSuccess,
  loadResourcesError,

  updateResource,
  updateResourceSuccess,
  updateResourceError,

  replaceResources,
  replaceResourcesSuccess,
  replaceResourcesError,

  cloneResource,
  cloneResourceSuccess,
  cloneResourceError,

  addResource,
  addResourceSuccess,
  addResourceError,

  removeResource,
  removeResourceSuccess,
  removeResourceError,

  searchResources,
  searchResourcesSuccess,
  searchResourcesError,

  selectResource,
  selectResources,

  clearResources
} from 'src/redux/actions'

import {NuagikeHTTPClient} from 'src/components/nuagike/index';

export default function* watcherResourcesSaga() {
  yield takeEvery(cloneResource, workerCloneResource);
  yield takeEvery(replaceResources, workerReplaceResources);
  yield takeEvery(searchResources, workerSearchResources);
  yield takeEvery(loadResource, workerLoadResource);
  yield takeEvery(loadResources, workerLoadResources);
  yield takeEvery(updateResource, workerUpdateResource);
  yield takeEvery(addResource, workerAddResource);
  yield takeEvery(removeResource, workerRemoveResource);
  yield takeEvery(lockResource, workerLockResource);
  yield takeEvery(selectResource, workerSelectResource);
  yield takeEvery(selectRef, workerselectRef);
  yield takeEvery(clearResources, workerClearResources);
}

function* workerselectRef(action) {
  yield(function() {
    if (action.payload.callback) {
      action.payload.callback(action.payload.ref);
    }
  })
}

function* workerSelectResource(action) {
  yield(function() {
    if (action.payload.callback) {
      action.payload.callback(action.payload.resource);
    }
  })
}

function* workerClearResources(action) {
  yield(function() {
    if (action.payload.callback) {
      action.payload.callback(action.payload.resource);
    }
  })
}

function* workerCloneResource(action) {
  if (action && action.payload) {
    try {
      const client = new NuagikeHTTPClient(action.payload.type, action.payload.parentRef);
      const resource = yield client.clone(action.payload.ref, action.payload.data);
      yield put(cloneResourceSuccess({resource: resource, type: action.payload.type, fromRef: action.payload.ref}));
      yield put(selectResource({resource: resource, type: action.payload.type, ref: resource.ref}));
      yield put(successMessage({message: "message.admin.resource.clone.success", ref: action.payload.ref}));
      if (action.payload.callback) {
        action.payload.callback(resource);
      }
    } catch (e) {
      console.error(e);
      yield put(cloneResourceError({ref: action.payload.ref, type: action.payload.type, data: action.payload.data, error: e}));
      yield put(errorMessage({message: "message.admin.resource.clone.error", type: action.payload.type, ref: action.payload.ref, error: e}));
    }
  }
}

function* workerReplaceResources(action) {
  if (action && action.payload) {
    try {
      const client = new NuagikeHTTPClient(action.payload.type, action.payload.parentRef);
      const result = yield client.replaceAll(action.payload.data, action.payload.partialMode);
      yield put(replaceResourcesSuccess({result: result, type: action.payload.type, parentRef: action.payload.parentRef}));
      yield put(successMessage({message: "message.admin.resource.replaceall.success", ref: action.payload.ref}));
      if (action.payload.callback) {
        action.payload.callback(result);
      }
    } catch (e) {
      yield put(errorMessage({message: "message.admin.resource.replaceall.error", type: action.payload.type, ref: action.payload.ref, error: e}));
      yield put(replaceResourcesError({data: action.payload.data, type: action.payload.type, error: e}));
    }
  }
}

function* workerSearchResources(action) {
  if (action && action.payload) {
    try {
      const client = new NuagikeHTTPClient(action.payload.type, action.payload.parentRef);
      const resources = yield client.search(action.payload.search);
      yield put(searchResourcesSuccess({resources: resources, type: action.payload.type}));
      if (action.payload.callback) {
        let {
          ["results"]: _,
          ...page
        } = resources;
        if (Array.isArray(resources.results)) {
          action.payload.callback(resources.results, page)
        } else {
          action.payload.callback(resources);
        }
      }
    } catch (e) {
      console.error(e);
      yield put(searchResourcesError({search: action.payload.search, type: action.payload.type, error: e}));
      yield put(errorMessage({message: "message.admin.resource.search.error", type: action.payload.type, error: e}));
    }
  }
}

function* workerLoadResources(action) {
  if (action && action.payload) {
    try {

      const client = new NuagikeHTTPClient(action.payload.type, action.payload.parentRef);
      const resources = yield client.loadAll(action.payload.filter);
      yield put(loadResourcesSuccess({resources: resources, type: action.payload.type}));
      if (action.payload.callback) {
        action.payload.callback(resources);
      }
    } catch (e) {
      yield put(loadResourcesError({type: action.payload.type, error: e}));
      yield put(errorMessage({message: "message.admin.resource.loadall.error", type: action.payload.type, error: e}));
    }
  }
}

function* workerLoadResource(action) {
  if (action && action.payload) {
    try {
      const client = new NuagikeHTTPClient(action.payload.type, action.payload.parentRef);
      const response = yield client.load(action.payload.ref);

      if (response) {
        yield put(loadResourceSuccess({resource: response, ref: action.payload.ref, type: action.payload.type}));
      } else {
        console.warn("response is null. it's a cache response");
      }
      if (action.payload.callback) {
        action.payload.callback(response);
      }
    } catch (e) {
      yield put(loadResourceError({ref: action.payload.ref, type: action.payload.type, error: e}));
      yield put(errorMessage({message: "message.admin.resource.load.error", type: action.payload.type, ref: action.payload.ref, error: e}));
    }
  }
}

function* workerUpdateResource(action) {
  if (action && action.payload) {
    try {
      const client = new NuagikeHTTPClient(action.payload.type, action.payload.parentRef);
      const response = yield client.update(action.payload.ref, action.payload.data);
      yield put(updateResourceSuccess({ref: action.payload.ref, resource: response, type: action.payload.type}));
      yield put(successMessage({message: "message.admin.resource.update.success", ref: action.payload.ref}));
      if (action.payload.callback) {
        action.payload.callback(response);
      }
    } catch (e) {
      yield put(updateResourceError({ref: action.payload.ref, data: action.payload.data, type: action.payload.type, error: e}));
      yield put(errorMessage({message: "message.admin.resource.update.error", type: action.payload.type, ref: action.payload.ref, error: e}));
    }
  }
}

function* workerAddResource(action) {
  if (action && action.payload) {
    try {
      const client = new NuagikeHTTPClient(action.payload.type, action.payload.parentRef);
      const resource = yield client.add(action.payload.data);
      yield put(addResourceSuccess({resource: resource, type: action.payload.type, ref: resource.ref}));
      yield put(successMessage({message: "message.admin.resource.add.success", ref: resource.ref}));
      yield put(selectResource({resource: resource, type: action.payload.type, ref: resource.ref}));

      if (action.payload.callback) {
        action.payload.callback(resource);
      }
    } catch (e) {
      yield put(addResourceError({data: action.payload.data, type: action.payload.type, error: e}));
      yield put(errorMessage({message: "message.admin.resource.add.error", type: action.payload.type, error: e}));
    }
  }
}

function* workerRemoveResource(action) {
  if (action && action.payload) {
    try {
      const client = new NuagikeHTTPClient(action.payload.type, action.payload.parentRef);

      const removed = yield client.remove(action.payload.ref, action.payload.password);
      yield put(removeResourceSuccess({ref: action.payload.ref, removed: removed, type: action.payload.type}));
      yield put(successMessage({message: "message.admin.resource.remove.success", ref: action.payload.ref}));
      if (action.payload.callback) {
        action.payload.callback(removed);
      }
    } catch (e) {
      yield put(removeResourceError({ref: action.payload.ref, type: action.payload.type, error: e}));
      yield put(errorMessage({message: "message.admin.resource.remove.error", ref: action.payload.ref, type: action.payload.type, error: e}));
    }
  }
}

function* workerLockResource(action) {
  if (action && action.payload) {
    try {
      const client = new NuagikeHTTPClient(action.payload.type, action.payload.parentRef);
      const resource = yield client.lock(action.payload.ref, action.payload.data);
      yield put(lockResourceSuccess({resource: resource, type: action.payload.type, ref: resource.ref}));
      yield put(successMessage({message: "message.admin.resource.lock.success", ref: resource.ref}));
      yield put(selectResource({resource: resource, type: action.payload.type, ref: resource.ref}));
      if (action.payload.callback) {
        action.payload.callback(resource);
      }
    } catch (e) {
      yield put(lockResourceError({data: action.payload.data, type: action.payload.type, error: e}));
      yield put(errorMessage({message: "message.admin.resource.lock.error", type: action.payload.type, error: e}));
    }
  }
}
