import React, {useState} from 'react'
import classnames from 'classnames';
import {useLoading} from "src/components/nuagike/index"
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'

const NuagikeSwitch = (props) => {

  const loading = false;// useLoading()

  return <div className={classnames("w-100", {
    ["border border-grise-100 rounded border-2"]: props.borderon
  })}>
      <div className={classnames("nuagike-input form-check form-switch d-flex align-items-center m-0 p-1",

        //
        {
          ["nuagike-input-disabled"]: props.disabled || loading || props.loading
        },
        //
        {
          ["form-switch-" + props.color]: props.color
        }, {
          ["form-switch-" + props.size]: props.size
        }, {
            ["d-none"]: props.hidden
          }, {
            ["d-flex align-items-center"]: !props.hidden
          }, props.className)}>
        <input className="form-check-input" disabled={props.disabled || loading || props.loading} checked={props.value} onChange={(e) => props.onChange(props.name, !props.value)} type="checkbox" role="switch" id={props.id} name={props.name
            ? props.name
            : props.id}/>
        <label className="flex-grow-1 align-items-center form-label text-primary p-0 ps-2 m-0 w-100 text-truncate" required={props.required} htmlFor={props.id} hidden={!props.label}>{props.label}</label>



      </div>

      {
        props.children
          ? <div className="col col-12 p-1 m-0">
              {props.children}
            </div>
          : ""
      }

  </div>
}

export default NuagikeSwitch;
