import React, {useState} from 'react'
import classnames from 'classnames';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {Typeahead, Token} from 'react-bootstrap-typeahead';
import {useLoading, useRepos} from "src/components/nuagike/index"

const Currency = (props) => {
  const data = useRepos("currencies");
  const loading = false;// useLoading()

  const [currencies, setCurrencies] = React.useState([])
  const ref = React.createRef();

  React.useEffect(() => {
    if (data) {
      let currency = data.filter(c => c.id === props.value)[0]
      if (currency) {
        setCurrencies(currencies => [
          {
            ...currency
          }
        ])
      } else {

        setCurrencies(currencies => [])
        ref.current.clear();
      }

    }
  }, [data, props.value]);

  const handleChange = (values) => {
    props.onChange(
      props.name, values[0]
      ? values[0].id
      : "")
    setCurrencies(values)
  }

  return <div className={classnames("nuagike-input form-floating mt-1 d-flex align-items-center",
    //
    {
      ["nuagike-input-disabled"]: props.disabled || props.loading || loading
    })}>
    <div className="nuagike-input-prependicon d-flex align-items-center rounded-start">
      <FontAwesomeIcon icon={"money-bill-alt"} className=""/>
    </div>
    <Typeahead ref={ref} className={classnames("form-control ", {
        "rounded-0 rounded-end": true
      }, {"is-invalid": props.invalid})} disabled={props.disabled || props.loading || loading} id={props.id} name={props.name} multiple={false}
      //
      onChange={handleChange} filterBy={['symbol', 'name', 'country', 'id']} clearButton={true} labelKey={(option) => String(option.id)}
      renderMenuItemChildren={(option) => {
        return <div className="row">
          <div className="col col-12">
            <span className={classnames("me-2 fi fi-" + option.cca2.toLowerCase())}></span>
            <span>{option && option.symbol
                  ? option.symbol + " " + option.id
                  : ""}</span>
          </div>
          <div className="col col-12">
          <small>{option.name + " (" + option.country + ")"}</small>
          </div>

        </div>
      }} highlightOnlyResult={true} minLength={1} options={data} placeholder={props.placeholder
        ? props.placeholder
        : props.label} selected={currencies}/>
    <label className={classnames("form-label text-truncate", {"form-label-prependicon": true})} htmlFor={props.id}>{props.label}<FontAwesomeIcon icon="sun" className="ms-1 text-danger" hidden={!props.required}/></label>
  </div>
}

export default Currency;
