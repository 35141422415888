import React, {useState, useEffect, useMemo} from 'react'
import {useSelector, useDispatch} from 'react-redux'
import classnames from "classnames"
import {useIntl} from 'react-intl';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'

import "./index.scss"
const Accordion = (args) => {
  const intl = useIntl()

  return <div className="accordion" id={"accordion" + args.id}>{args.children}
  </div>

}

export default Accordion;
