import React, { useEffect } from "react";
import ReactTable, { useTable, usePagination, useSortBy, useFilters, useGlobalFilter } from 'react-table'
import { useIntl } from 'react-intl';
import { Table } from 'react-bootstrap';
import classnames from "classnames";
import EditableCell from './EditableCell'
import DefaultColumnFilter from './DefaultColumnFilter'
import Pagination from './Pagination'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import DraggableRow from './DraggableRow'
import { useSelector, useDispatch } from 'react-redux'
import { Button, Loading, useLoading } from "src/components/nuagike/index"
import { deepCompare } from "src/tools/index"

import TableHeader from "./header"
import TableBody from "./body"

import './index.scss'

const NuagikeTable = ({
  hidden = false,
  columns = [],
  data = [],
  updateData,
  moveRow,
  saveMoveRow,
  draggable,

  className,

  customFilters = [],

  updateFilters,
  updateSortedBy,
  updatePagination,

  manualPagination = false,

  customPageCount,
  customPageSize,
  customPageIndex,
  customSortBy,
  customSortDirection,
  customTotalSize,
  customLoading,
  currentEdit,
  hideFilters
}) => {
  // Use the useTable Hook to send the columns and data to build the table

  const intl = useIntl()
  const loading = useSelector((state) => state.loading) || customLoading;
  const [ignoreFirst, setIgnoreFirst] = React.useState({ sort: false, filter: false })

  const hiddenColumns = React.useMemo(() => columns.filter(c => c.hidden).map(c => c.id));


  //if given customSortBy then columns sorts will be ignored
  const defaultSorts = React.useMemo(() => columns.filter(c => c.sort).map(c => ({
    'id': c.accessor,
    'desc': c.sort && c.sort === 'desc'
  })));
  ////console.log({defaultSorts}); console.log({customSortBy, customSortDirection});
  const defaultFilters = React.useMemo(() => {
    let defs = [];
    columns && columns.filter(c => c.filter).map(c => {
      defs.push({ "id": c.id, "value": c.filter })
    });

    customFilters && Object.keys(customFilters).map((cfk, cfindex) => {
      defs.push({ "id": cfk, "value": customFilters[cfk] })
    });

    return defs;
  }, []);

  const initialState = React.useMemo(() => {
    return {
      hiddenColumns: hiddenColumns,
      sortBy: defaultSorts,
      filters: defaultFilters,
      pageSize: customPageSize || 10,
      pageIndex: customPageIndex || 0
    }
  }, []);

  // Set our editable cell renderer as the default Cell renderer
  const defaultColumn = React.useMemo(() => {
    return {
      Cell: EditableCell,
      Filter: DefaultColumnFilter
    }
  }, []);

  /*
  const filterTypes = React.useMemo(() => ({
    text: (rows, id, filterValue) => {
      return rows.filter(row => {
        const rowValue = row.values[id]
        return rowValue !== undefined
          ? String(rowValue).toLowerCase().startsWith(String(filterValue).toLowerCase())
          : true
      })
    }
  }), []);*/

  // Set our editable cell renderer as the default Cell renderer

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page, // Instead of using 'rows', we'll use page,
    // which has only the rows for the active page The rest of these things are super handy, too ;)
    canPreviousPage,
    canNextPage,
    pageOptions,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    setFilter,
    state: {
      pageIndex,
      pageSize,
      sortBy,
      filters
    }
  } = useTable({
    columns: columns,
    data: data,
    pageCount: customPageCount
      ? customPageCount
      : 100,
    manualPagination: manualPagination,

    initialState: initialState,
    defaultColumn: defaultColumn,

    autoResetPage: true,
    autoResetFilters: false,
    autoResetSortBy: false,
    // updateData isn't part of the API, but anything we put into these options will automatically be available on the instance. That way we can call this function from our cell renderer!
    currentEdit,
    updateData,
    updatePagination
  }, useFilters, useGlobalFilter, useSortBy, usePagination);


  React.useEffect(() => {
    if (ignoreFirst.sort) {
      if (updateSortedBy) {
        console.log({ "updateSortedBy from table .................": filters });
        if (sortBy && sortBy.length) {
          updateSortedBy({
            by: sortBy[0].id,
            direction: sortBy[0].desc
              ? "desc"
              : "asc"
          })
        } else {
          //updateSortedBy({by: null, direction: null})
        }
      }
    } else {
      setIgnoreFirst(prev => ({ ...prev, sort: true }))
    }
  }, [sortBy]);

  React.useEffect(() => {

    //ignore first update
    if (ignoreFirst.filter) {
      if (updateFilters) {
        console.log({ "updateFilters from table .................": filters });
        updateFilters({ filters: filters })
      }
    } else {
      setIgnoreFirst(prev => ({ ...prev, filter: true }))
    }
  }, [filters]);


  /*
    console.log({
      ["RENDER TABLE " + data.length + " items"]: {
        hidden,
        columns,
        data ,
        updateData,
        moveRow,
        saveMoveRow,
        draggable,
  
        className,
  
        customFilters,
  
        updateFilters,
        updateSortedBy,
        updatePagination,
  
        manualPagination,
  
        customPageCount,
        customPageSize,
  
        customTotalSize,
        customLoading,
        currentEdit,
        hideFilters
      }
    });
  */
  const body = React.useMemo(() => {
    //console.log({"THE MOTHER FUCKER DATA CHANGED !!!!!!!!!!!!!!!!!!!!!!!!!!!!!": page});
    return <TableBody page={page} tableBodyProps={getTableBodyProps()} prepareRow={prepareRow} />
  }, [page]);

  const pagination = React.useMemo(() => {
    return <Pagination
      loading={loading}
      count={customTotalSize !== null
        ? customTotalSize
        : Array.isArray(data)
          ? data.length
          : 0}
      updatePagination={updatePagination}
      previousPage={previousPage}
      nextPage={nextPage}
      gotoPage={gotoPage}
      setPageSize={setPageSize}
      canPreviousPage={canPreviousPage}
      canNextPage={canNextPage}
      pageOptions={pageOptions}
      pageIndex={customPageIndex || pageIndex}
      pageSize={customPageSize || pageSize} />
  }, [data, page])

  return <div hidden={hidden} className={"nuagike-table w-100 rounded p-0 m-0" + (
    className
      ? className
      : ""
  )}>
    {
      (data && data.length > 0 || filters && filters.length)
        ? <Table striped={true} hover={true} sorted={"asc"} {...getTableProps({
          className: classnames("rounded p-0 m-0", { "loading": loading }),
        })}>
          <TableHeader headerGroups={headerGroups} draggable={draggable} hideFilters={hideFilters} />
          {body}
        </Table>

        : <div className={classnames("d-block text-center bg-info-90 text-grise-3 m-0 p-1 border border-light rounded d-flex align-items-center justify-content-center", { "loading": loading })}>
          {
            loading
              ? <div className=" d-flex align-items-center justify-content-center">
                <Loading />
              </div>
              : <span className=" border-none m-0 p-1 py-2 small">

                <FontAwesomeIcon icon="battery-empty" className="me-2" />&nbsp;{intl.formatMessage({ id: "text.admin.default.nodata" })}

              </span>

          }
        </div>
    }

    {
      !draggable
        ? pagination
        : null
    }

  </div>
}

export default NuagikeTable;
