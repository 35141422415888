import {createAction} from "@reduxjs/toolkit";

export const loading = createAction("LOADING");

export const clearResources = createAction("CLEAR_RESOURCES");
export const selectRef = createAction("SELECT_REF");
export const selectResource = createAction("SELECT_RESOURCE");
export const selectResources = createAction("SELECT_RESOURCES");

export const loadResource = createAction("LOAD_RESOURCE");
export const loadResourceSuccess = createAction("LOAD_RESOURCE_SUCCESS");
export const loadResourceFromCache = createAction("LOAD_RESOURCE_FROM_CACHE");
export const loadResourceError = createAction("LOAD_RESOURCE_ERROR");

export const loadResources = createAction("LOAD_RESOURCES");
export const loadResourcesSuccess = createAction("LOAD_RESOURCES_SUCCESS");
export const loadResourcesError = createAction("LOAD_RESOURCES_ERROR");

export const searchResources = createAction("SEARCH_RESOURCES");
export const searchResourcesSuccess = createAction("SEARCH_RESOURCES_SUCCESS");
export const searchResourcesError = createAction("SEARCH_RESOURCES_ERROR");

export const removeResource = createAction("REMOVE_RESOURCE");
export const removeResourceSuccess = createAction("REMOVE_RESOURCE_SUCCESS");
export const removeResourceError = createAction("REMOVE_RESOURCE_ERROR");

export const addResource = createAction("ADD_RESOURCE");
export const addResourceSuccess = createAction("ADD_RESOURCE_SUCCESS");
export const addResourceError = createAction("ADD_RESOURCE_ERROR");

export const updateResource = createAction("UPDATE_RESOURCE");
export const updateResourceSuccess = createAction("UPDATE_RESOURCE_SUCCESS");
export const updateResourceError = createAction("UPDATE_RESOURCE_ERROR");

export const replaceResource = createAction("REPLACE_RESOURCE");
export const replaceResourceSuccess = createAction("REPLACE_RESOURCE_SUCCESS");
export const replaceResourceError = createAction("REPLACE_RESOURCE_ERROR");

export const replaceResources = createAction("REPLACE_RESOURCES");
export const replaceResourcesSuccess = createAction("REPLACE_RESOURCES_SUCCESS");
export const replaceResourcesError = createAction("REPLACE_RESOURCES_ERROR");

export const lockResource = createAction("LOCK_RESOURCE");
export const lockResourceSuccess = createAction("LOCK_RESOURCE_SUCCESS");
export const lockResourceError = createAction("LOCK_RESOURCE_ERROR");

export const cloneResource = createAction("CLONE_RESOURCE");
export const cloneResourceSuccess = createAction("CLONE_RESOURCE_SUCCESS");
export const cloneResourceError = createAction("CLONE_RESOURCE_ERROR");

export const login = createAction("LOGININ");
export const loginSuccess = createAction("LOGININ_SUCCESS");
export const loginError = createAction("LOGININ_ERROR");

export const logout = createAction("LOGIN_OUT");

export const successMessage = createAction("SUCCESS_MESSAGE");
export const errorMessage = createAction("ERROR_MESSAGE");

export const confirm = createAction("CONFIRM_ACTION");
export const confirmDone = createAction("CONFIRM_ACTION_DONE");

export const loadRepository = createAction("LOAD_REPOSITORY");
export const loadRepositorySuccess = createAction("LOAD_REPOSITORY_SUCCESS");

export const loadConfiguration = createAction("LOAD_CONFIG");
export const loadConfigurationSuccess = createAction("LOAD_CONFIG_SUCCESS");
export const loadConfigurationError = createAction("LOAD_CONFIG_ERROR");

export const uploadFile = createAction("UPLOAD_FILE");
export const uploadFileSuccess = createAction("UPLOAD_FILE_SUCCESS");
export const uploadFileError = createAction("UPLOAD_FILE_ERROR");

export const switchLocale = createAction("SWITCH_LOCALE");

export const pingSidebar = createAction("SIDEBAR");

export const nextStep = createAction("NEXT_STEP");
