import React, {useState} from 'react'
import classnames from 'classnames';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'

const LinkBadge = (props) => {
  const [text, setText] = React.useState("")

  React.useEffect(() => {
    setText(props.text)
  }, [props.text])

  return <span className={classnames("badge nuagike-badge text-start align-items-center d-flex m-0 p-1 px-2 border rounded border-1 border-light text-truncate",
    //
    {
      ["bg-" + props.bg]: props.bg
    }, {
      ["text-" + props.color]: props.color
    }, {
      ["d-none"]: props.hidden
    }, {
      ["nuagike-badge-copy"]: props.copy
    }, {
      ["nuagike-badge-copied"]: props.varvalue && props.clipboard === props.varvalue
    }, props.className)} onClick={props.onClick}>
    <FontAwesomeIcon icon={props.icon
        ? props.icon
        : "tag"} size={props.iconSize
        ? props.iconSize
        : "1x"} className={classnames("me-2", {"d-none": props.hideIcon})}/>{
      props.link
        ? <a target="_blank" className={classnames("text-truncate text-decoration-none w-100", {
              ["bg-" + props.bg]: props.bg
            }, {
              ["text-" + props.color]: props.color
            })} onClick={() => {
              window.location = props.link;
            }}>{text}</a>
        : text
    }
  </span>

}

export default React.memo(LinkBadge);
