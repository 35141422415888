import React, {useState} from 'react'
import classnames from 'classnames';
import {useLoading, useEnums} from "src/components/nuagike/index"
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {useIntl} from 'react-intl';

const NumberFloat = ({
  handleChange,
  localValue,
  ...props
}) => {

  const loading = false;// useLoading()
  const intl = useIntl();

  const defaultFloat = 100;
  const [float, setFloat] = React.useState(defaultFloat);

  const [displayedValue, setDisplayedValue] = React.useState("");

  React.useEffect(() => {

    if (props.float) {
      setFloat(props.float)
    } else {
      setFloat(defaultFloat)
    }

  }, [props.float])

  React.useEffect(() => {
    if (localValue !==null && float) {
      setDisplayedValue(localValue / float)
    }
  }, [localValue, float])

  return <div className={classnames("nuagike-input p-0 m-0 border-none d-flex align-items-center mt-1",
    //
    {
      ['form-floating']: !props.nofloating
    }, {
      ["nuagike-input-disabled"]: props.disabled || loading || props.loading
    }, {
      "d-none": props.hidden
    }, {
      "prepended": props.prepend
    }, {
      "mt-1": !props.nomargin
    }, props.className)}>

    {
      props.prependIcon
        ? <div className={classnames("nuagike-input-prependicon d-flex align-items-center rounded-start", props.prependIconClassName)}>
            <FontAwesomeIcon icon={props.prependIcon}/>
          </div>
        : ""
    }
    {
      props.prepend
        ? <div className="nuagike-input-prepend d-flex align-items-center">
            <span className="pe-1 fw-light lh-sm one-line">{props.prepend}</span>
          </div>
        : ""
    }

    <input type={"number"} step="0.10" id={props.id} className={classnames("fw-bold font-monospace fs-3 form-control", {
        "rounded-0 rounded-end ": props.prependIcon
      }, {"is-invalid": props.invalid})}
      //
      value={displayedValue} placeholder={props.placeholder
        ? props.placeholder
        : props.label} onChange={(e) => handleChange((e.target.value * float))}
      /////
      disabled={props.disabled || loading || props.loading}/> {
      props.label
        ? <label className={classnames("form-label form-check-label text-truncate w-100 ", {"form-label-prependicon": props.prependIcon})} htmlFor={props.id}>
            {props.label}<FontAwesomeIcon icon="sun" className="ms-1 text-danger" hidden={!props.required}/></label>
        : ""
    }
  </div>

}

export default NumberFloat;
