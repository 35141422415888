import React from 'react'
import classnames from 'classnames';
import {NuagikeContext} from "src/components/nuagike/index"
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {useIntl} from 'react-intl';
import {getEnumIcon} from "src/tools/icons"

const DropDownMenu = ({enumName, enumClassName, currentValue, handleSelect, label}) => {

  const intl = useIntl();
  const {enums} = React.useContext(NuagikeContext)
  const enumValues = enums[enumName];

  return <div className="dropdown nuagike-dropdown" key={"dropdown-"+enumName}>
    <button
      className={classnames("btn nuagike-badge dropdown-toggle dropdown-toggle-split w-100 d-flex align-items-center justify-content-center", {
        [enumClassName + currentValue]: currentValue
      })}
      type="button"
      id={"dropdownMenu"+enumName}
      aria-expanded="false"
      data-bs-toggle="dropdown">
      {
        "[ " + intl.formatMessage({
          id: "text.enum."+enumName+"." + currentValue
        }) + " ] "
      }
      {label}
      <FontAwesomeIcon className="fa-fw ps-1" icon="angle-down"/>
    </button>
    <ul className="dropdown-menu dropdown-menu-light w-100" aria-labelledby={"dropdownMenu"+enumName}>
      {enumValues && enumValues.map((enumValue, enumIndex)=>
        <li key={"li-enum-"+enumName+"-"+enumValue} onClick={(e)=>{handleSelect(enumValue); e.stopPropagation();}}>
          <span hidden={enumValue === currentValue} className="dropdown-item text-truncate">
          <FontAwesomeIcon className="fa-fw ps-1 me-2" icon={getEnumIcon(enumName, enumValue)}/>
          {intl.formatMessage({id: "text.enum."+enumName+"." + enumValue})}
        </span>
      </li>)}

    </ul>
  </div>
}


export default DropDownMenu;
