import React from 'react'
import {useDispatch} from 'react-redux'
import {switchLocale as switchLocaleAction} from "src/redux/actions/index";

export default function useLanguage() {
  const dispatch = useDispatch();
  const switchLocale = (locale) => {
    dispatch(switchLocaleAction({locale: locale}));
  }

  return {switchLocale}
}
