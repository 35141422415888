import React from 'react'
import {Input, Button, useConfig, useMessage} from "src/components/nuagike/index"

const useRecaptcha = ({action}) => {
  const message = useMessage();
  const googleCaptchaKey = useConfig("params")["google.recaptcha.key.site"];
  const [token, setToken] = React.useState(null)

  const getToken = ({callback}) => {
    if (googleCaptchaKey) {
      window.grecaptcha.ready(_ => {
        window.grecaptcha.execute(googleCaptchaKey, {action: action}).then(token => {
          callback(true, token);
        })
      })
    }else{
      message.error("text.admin.config.repatchaconfigismissing");
      callback(false, null);
    }
  }

  React.useEffect(() => {
    if (googleCaptchaKey) {
      // Add reCaptcha
      const script = document.createElement("script");
      script.src = "https://www.google.com/recaptcha/api.js?render=" + googleCaptchaKey;
      script.async = true;
      script.defer = true;
      //script.addEventListener("load", handleLoaded)
      document.body.appendChild(script)
    }
  }, [googleCaptchaKey])

  const recaptcha = () => {
    return <div className="g-recaptcha" data-sitekey={googleCaptchaKey} data-size="invisible"></div>
  }

  return {recaptcha, token, getToken}

}

export default useRecaptcha
