import React, {useState} from 'react'
import {Button, useLoading, useUser} from "src/components/nuagike/index"
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {format, isValid, parse, getMonth, getYear} from 'date-fns';
import classnames from 'classnames';
import DatePicker from 'react-datepicker';

import range from "lodash/range";
import "./index.scss";

const years = range(1900, 2100, 1);

const months = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December"
];

const NuagikeDateInputHeader = ({
  date,
  changeYear,
  changeMonth,
  decreaseMonth,
  increaseMonth,
  prevMonthButtonDisabled,
  nextMonthButtonDisabled
}) => <div style={{
    margin: 10,
    display: "flex",
    justifyContent: "center"
  }}>
  <button onClick={decreaseMonth} disabled={prevMonthButtonDisabled}>
    {"<"}
  </button>
  <select value={getYear(date)} onChange={({target: {
        value
      }}) => changeYear(value)}>
    {
      years.map((option) => (<option key={option} value={option}>
        {option}
      </option>))
    }
  </select>

  <select value={months[getMonth(date)]} onChange={({target: {
        value
      }}) => changeMonth(months.indexOf(value))}>
    {
      months.map((option) => (<option key={option} value={option}>
        {option}
      </option>))
    }
  </select>

  <button onClick={increaseMonth} disabled={nextMonthButtonDisabled}>
    {">"}
  </button>
</div>;

const NuagikeDatePicker = ({
  name,
  value,
  onChange,
  showTime,
  onlyTime,
  inputFormat = 'yyyy-MM-dd\'T\'HH:mm:ssXXX',
  ...props
}) => {
  const loading = useLoading();
  const user = useUser();

  const [dateFormat, setDateFormat] = React.useState("dd-MM-yyyy");
  const [inputValue, setInputValue] = useState(new Date());

  React.useEffect(() => {
    if (showTime) {
      if (onlyTime) {
        setDateFormat(user.timeFormat)
      } else if (user.dateFormat && user.timeFormat) {
        setDateFormat(user.dateFormat + " " + user.timeFormat)
      } else {
        setDateFormat("dd-MM-yyyy HH-mm-ss")
      }
    } else {
      if (user.dateFormat) {
        setDateFormat(user.dateFormat)
      } else {
        setDateFormat("dd-MM-yyyy")
      }
    }
  }, [user, showTime, onlyTime]);

  React.useEffect(() => {
    //console.log({value, inputFormat});
    if (value && inputFormat) {
      const date = parse(value, inputFormat, new Date());
      //console.log({value, inputFormat, date});
      if (isValid(date)) {
        setInputValue(date);
      } else {
        console.error(value + " not valid");
        setInputValue("");
      }
    } else {
      setInputValue("");
    }
  }, [value, inputFormat]);

  return <DatePicker
    ////
    renderCustomHeader={({
      ...headerProps
    }) => <NuagikeDateInputHeader {...headerProps}/>}
    //
    customInput={<NuagikeDateInput value = {
      inputValue
    } {
      ...props
    } />}
    locale={user.locale || "enUS"}
    dateFormat={dateFormat}
    showIcon={false}
    selected={inputValue}
    showTimeSelectOnly={onlyTime || false}
    showTimeSelect={showTime || false}
    timeIntervals={5} onChange={(date) => {
      if (inputFormat) {
        onChange(name, format(date, inputFormat));
      }
    }}/>
}

const NuagikeDateInput = React.forwardRef(
  ({
    id,
    hidden,
    disabled,
    invalid,
    required,
    label,
    prependIcon,
    prependIconClassName,
    value,
    onClick
  }, ref) => <div
    className={classnames("nuagike-input form-floating p-0 m-0 border-none d-flex align-items-between align-items-center w-100",
    //
    {
      ["nuagike-input-disabled"]: disabled
    }, {"d-none": hidden})}>
    {
      prependIcon
        ? <div className="nuagike-input-prependicon d-flex align-items-center ms-0 rounded-start">
            <FontAwesomeIcon icon={prependIcon} className={prependIconClassName}/></div>
        : ""
    }

    <input type="text"
      ////
      value={value} onChange={() => {}}
      //////////
      className={classnames("form-control input-reset pe-5 ", {
        "is-invalid": invalid
      }, {"rounded-0 rounded-end": prependIcon})}/>

    <Button hidden={disabled} icon="calendar-day" color="light" type="icon" size="1x" className="m-2 position-absolute end-0 bottom-0"
      ////
      action={(e) => {
        onClick(e);
        e.currentTarget.blur();
        e.stopPropagation();
      }}/>{
      label
        ? <label className={classnames("form-label text-truncate w-100 pe-5", {"form-label-prependicon": prependIcon})} htmlFor={id}>
            {label}<FontAwesomeIcon icon="sun" className="ms-1 text-danger" hidden={!required}/></label>
        : ""
    }

  </div>
);
NuagikeDateInput.displayName = "NuagikeDateInput";
export default NuagikeDatePicker;
