import React, { useState } from 'react'
import classnames from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useSelector } from 'react-redux'
import Country from "./country"
import Timezone from "./timezone"
import Typehead from "./typehead"
import Currency from "./currency"
import Readonly from "./readonly"
import File from "./file";
import Range from "./range";
import EnumInput from "./enum";
import NuagikeDate from "./date/index";
import NuagikeDatePicker from "./date/picker";
import NuagikeSwitch from "./switch";
import NuagikeSelect from "./select";
import NuagikeRichText from "./richtext/index";
import NuagikeTextarea from "./textarea";
import FloatNumber from "./float";
import DropDownMenu from "./dropdown";
import DefaultInput from "./default";
import config from "src/config/index"

import "./index.scss"

const Input = (props) => {

  const checkAndUpdate = (value) => {
    if (props.type === "number") {
      if (props.min != null && Number(props.min) >= Number(value)) {
        value = props.min
      }
      if (props.max != null && Number(props.max) <= Number(value)) {
        value = props.max
      }
    }
    props.onChange(props.name, value)
  }

  return props.type === "dropdown" ?
    <DropDownMenu {...props} /> : props.type === "float"
      ? <FloatNumber handleChange={checkAndUpdate} localValue={props.value} {...props} />
      : props.type === "textarea"
        ? <NuagikeRichText {...props} />
        : props.type === "richtext"
          ? <NuagikeRichText {...props} />
          : props.type === "datepicker"
            ? <NuagikeDatePicker {...props} />
            : (props.type === "enum")
              ? <EnumInput {...props} />
              : (props.type === "range")
                ? <Range {...props} />
                : (props.type === "file")
                  ? <File {...props} />
                  : (props.type === "readonly")
                    ? <Readonly {...props} />
                    : (props.type === "typehead")
                      ? <Typehead {...props} />
                      : (props.type === "currency")
                        ? <Currency {...props} />
                        : (props.type === "timezone")
                          ? <Timezone {...props} />
                          : (props.type === "country")
                            ? <Country {...props} />
                            : (props.type === "switch")
                              ? <NuagikeSwitch {...props} />
                              : <DefaultInput {...props} />
}

export default Input;
