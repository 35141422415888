import {
  loadConfiguration,
  loadConfigurationSuccess,
  loadConfigurationError,
  login,
  loginSuccess,
  loginError,
  confirm,
  confirmDone,
  switchLocale,
  pingSidebar,
  nextStep,
  loadRepositorySuccess,
  loading
} from 'src/redux/actions'

import initialState from "src/redux/initialState"

function defaultReducer(builder) {
  builder.addCase(loading, (state, action) => {
    if (action.payload) {
      state.loading = state.loading + 1;
    } else {
      state.loading = state.loading - 1;
    }
  }).addCase(confirmDone, (state, action) => {
    state.actions = [];
  }).addCase(confirm, (state, action) => {
    if (action.payload && action.payload.action) {
      state.actions = [action.payload.action]
    }
  }).addCase(switchLocale, (state, action) => {
    state.user.locale = action.payload.locale;
  })


  .addCase(login, (state, action) => {
    state.loading = state.loading + 1;
    state.user = {...initialState.user};
    state.user.principal = action.payload.principal;
    state.user.email = action.payload.email;
    state.user.roles = action.payload.roles;
  }).addCase(loginError, (state, action) => {
    state.loading = state.loading > 0
      ? (state.loading - 1)
      : 0;
  }).addCase(loginSuccess, (state, action) => {
    state.loading = state.loading > 0
      ? (state.loading - 1)
      : 0;
    state.user = action.payload.user;
  })

  .addCase(loadConfiguration, (state, action) => {
    state.loading = state.loading + 1;
    state.config = initialState.config;
    state.user.organization = initialState.user.organization;
  }).addCase(loadConfigurationError, (state, action) => {
    state.loading = state.loading > 0
      ? (state.loading - 1)
      : 0;
  }).addCase(loadConfigurationSuccess, (state, action) => {
    state.loading = state.loading > 0
      ? (state.loading - 1)
      : 0;
    state.config = action.payload.config;
    state.user.organization = action.payload.organization;
  }).addCase(loadRepositorySuccess, (state, action) => {
    state.config.repositories[action.payload.name] = action.payload.repository;
  });
}

export default defaultReducer;
