import React, {useState} from 'react'
import classnames from 'classnames';
import formatDate from "date-fns/format";
import parseISO from "date-fns/parseISO";
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {Badge, Button, useCopyToClipboard} from "src/components/nuagike/index"

const getReadonlyValue = (props) => {
  if (props.value) {
    if (props.format === "datetime") {
      return formatDate(parseISO(props.value), "dd-MM-yyyy  HH:mm:ss")
    } else if (props.format === "date") {
      return formatDate(parseISO(props.value), "dd-MM-yyyy")
    } else if (props.format === "price" && props.value) {
      return (Math.round(props.value) / 100).toFixed(2) + " " + props.currency
    }
  }

  return props.value || "";
}

const Readonly = (props) => {
  const [localValue, setLocalValue] = React.useState("")

  React.useEffect(() => {
    if (props && props.value && props.value !== "new") {
      let theValue = getReadonlyValue(props)
      setLocalValue(theValue)
    }
  }, [props.value]);



  return <Badge hidden={!localValue} icon={props.prependIcon
      ////
      || (
        (props.format === "price")
        ? "dollar-sign"
        : (props.format === "date" || props.format === "datetime")
          ? "clock"
          : "hashtag")} type={props.type || "text"} value={localValue}
      //
      text={props.label} className={classnames({
            "mt-1": !props.nomargin
          }, props.className)} copyclipboard={true} onClick={props.onClick}/>

}

export default Readonly;
