import React, {useState} from 'react'
import {Modal, Button, Input} from "src/components/nuagike/index"
import {useIntl} from 'react-intl';
import {confirmDone} from "src/redux/actions/index"
import {useSelector, useDispatch} from 'react-redux'

const ConfirmAction = () => {
  const intl = useIntl()
  const [showModal, setShowModal] = useState(false);
  const [password, setPassword] = useState("password");

  const [payload, setPayload] = React.useState(null);
  const [message, setMessage] = React.useState(null);

  const dispatch = useDispatch();
  const actions = useSelector((state) => state.actions);

  React.useEffect(() => {
    if (actions && actions.length) {
      setPayload(actions[0].payload)
      setMessage(actions[0].message)
      setShowModal(true)
    } else {
      setShowModal(false)
    }
  }, [actions])

  const onConfirm = () => {
    if (actions && actions.length) {
      actions[0].callback(password, payload);
    }
    dispatch(confirmDone());
  }

  const onDiscard = () => {
    dispatch(confirmDone());
  }

  return <Modal show={showModal} onClose={onDiscard} hideButtons={true} icon="exclamation" size="md" title={intl.formatMessage({id: "text.admin.confirmation.title"})}
  color="danger">
    <form>
      {
        message
          ? <div className="row text-center p-0 m-0">
              <div className="col col-12">
                <p>{message}
                </p>
              </div>
            </div>
          : ""
      }
      <div className="row text-start p-0 m-0">
        <div className="col m-0 p-0 col-12">
          <Input
          type="password"
          name="password"
          label={intl.formatMessage({id: "text.admin.confirmation.password"})}
          value={password || ""}
          id={"confirm-password" + Date.now()} onChange={(name, value) => {
              setPassword(value)
            }}/>
        </div>
      </div>
      <div className="row text-center p-0 m-0 mt-3">
        <div className="col m-0  col-md-12 col-lg-6 py-2 d-flex flex-row-reverse p-1">
          <Button className="w-100 text-truncate" color="light" icon="ban" message={intl.formatMessage({id: "text.admin.confirmation.discard"})}
           size="1x" action={onDiscard}/>
        </div>

        <div className="col m-0  col-md-12 col-lg-6 py-2 d-flex flex-row p-1">
          <Button className="w-100 text-truncate" color="danger" icon="trash" message={intl.formatMessage({id: "text.admin.confirmation.yes"})}
          size="1x" action={onConfirm}/>
        </div>

      </div>
    </form>
  </Modal>
}

export default React.memo(ConfirmAction);
