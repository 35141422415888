import React, {useState} from 'react'
import classnames from 'classnames';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';

const Number = (props) => {

  return <div className={classnames("badge nuagike-badge border-0 p-1 d-flex align-items-center bg-light-90", {
    ["cursor-pointer"]: props.onClick
  },{
    ["nuagike-badge-clickable"]: props.onClick
  }, props.className)} onClick={props.onClick}>
  <div className={classnames("d-flex align-items-center  ", {
    "d-none": props.hideIcon || !props.icon
  })}>
    <FontAwesomeIcon
      icon={props.icon
        ? props.icon
        : "tag"}
      size={props.iconSize
        ? props.iconSize
        : "1x"}
      className={classnames("fa-fw ", {
        ["me-1"]: props.text
      }, {
        ["text-" + props.iconColor]: props.iconColor,
        ["text-" + props.color]: props.color && !props.iconColor,
        ["opacity-" + props.iconOpacity]: props.iconOpacity,
        ["opacity-50"]: !props.iconOpacity
      })}/>
  </div>
    <span className={classnames("align-items-center w-100 fw-bold", {
      ["text-" + props.color]: props.color,
      ["text-primary"]: !props.color,
      ["text-center"] : props.center,
      ["text-start"] : props.start,
      ["text-end"] : !props.center && !props.start,
      ["fw-bold"] : props.bold
    })}>{props.text || 0}</span>
  </div>

}

export default React.memo(Number);
