import React, {useState} from 'react'
import classnames from 'classnames';
import {useDispatch, useSelector} from 'react-redux'
import {OverlayTrigger, Overlay, Tooltip} from 'react-bootstrap';

const CountryBadge = (props) => {
  const countries = useSelector((state) => state.config.repositories.countries)
  const [country, setCountry] = React.useState(null)

  React.useEffect(() => {
    if (countries && props.code) {
      countries.filter(c => c.cca2.toLowerCase() === props.code.toLowerCase()).map(c => {
        setCountry({
          ...c
        })
      })
    }

  }, [countries, props.code]);

  return props.code
    ?
        <div className={classnames("badge nuagike-badge nuagike-badge-country p-0 m-0 d-flex align-items-center justify-content-start text-truncate",
//
         {"justify-content-center": props.onlyFlag})}>
          <div className={classnames("fs-2 fi fis fi-" + props.code.toLowerCase())}></div>
          {
            !props.onlyFlag
              ? <span className="d-inline-block text-truncate ps-2 py-0 align-items-center">{
                    country && country.name
                      ? country.name.common
                      : props.code
                  }</span>
              : ""
          }

        </div>

    : ""

}

export default React.memo(CountryBadge);
