import React, {useState} from 'react'
import {useDropzone} from 'react-dropzone';
import Loading from "../loading/index"
import {useIntl} from 'react-intl';
import classnames from 'classnames';
import {uploadFile} from "src/tools/request"

import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'

const File = ({
  url,
  view,
  defaultMetas,
  onFileChoosen,
  onFileUploaded,
  ...props
}) => {
  const intl = useIntl()
  const [files, setFiles] = useState([]);
  const [errors, setErrors] = useState([]);
  const [localLoading, setLocalLoading] = React.useState(false);

  const [maxFileSize, setMaxFileSize] = useState(
    props.maxFileSize
      ? props.maxFileSize
      : 1048000
  );

  const {acceptedFiles, getRootProps, getInputProps, open, inputRef} = useDropzone({
    maxFiles: 1,
    // Disable click and keydown behavior
    accept: props.types
      ? props.types
      : {
        'application/pdf': [".pdf"],
        'text/plain': [".text"],
        'text/csv': ['.csv'],
        'application/msword': ['.doc'],
        'application/vnd.openxmlformats-officedocument.wordprocessingml.document': ['.docx'],
        'application/vnd.ms-excel': ['.xls'],
        'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': ['.xlsx']
      },
    noClick: true,
    noKeyboard: true,
    maxSize: maxFileSize,
    onDrop: (acceptedFiles, fileRejections) => {
      setErrors(errors => [])

      fileRejections.forEach((file) => {

        file.errors.forEach((err) => {

          setErrors(errors => [
            ...errors,
            "text.admin.input.file.error." + err.code
          ]);

        });
      });
      let newFiles = acceptedFiles.map(file => Object.assign(file, {preview: URL.createObjectURL(file)}));
      setFiles(newFiles);
      if (onFileChoosen) {
        onFileChoosen(newFiles)
      } else {
        if(newFiles){
          newFiles.map(newFile=>{
            //default behavior is to uploadFile as temp
            let form = new FormData();
            form.append('file', newFile);
            form.append('data', JSON.stringify({ref: null, temp: true, metas: {...defaultMetas}}));

            //First we upload the file
            if (setLocalLoading) {
              setLocalLoading(true);
            }
            uploadFile(form).then(response => {
              if (onFileUploaded) {
                onFileUploaded(response);
                setFiles([])
              }
            }).finally(() => {
              setLocalLoading(false)
            });
          })
        }
      }
    }
  });

  React.useEffect(() => {
    if (props.invalid === true) {
      setErrors(errors => ["text.admin.input.file.error.invalid"])
    }
  }, [props.invalid]);

  function bytesToSize(bytes) {
    var sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'];
    if (bytes == 0)
      return '0 Byte';
    var i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)));
    return Math.round(bytes / Math.pow(1024, i), 2) + ' ' + sizes[i];
  }

  const removeAll = () => {
    setFiles([])
    onFileChoosen && onFileChoosen([])
  }

  React.useEffect(() => {
    // Make sure to revoke the data uris to avoid memory leaks, will run on unmount
    return() => files.forEach(file => URL.revokeObjectURL(file.preview));
  }, []);

  //console.log({files});

  return <div className="nuagike-input form-floating my-1 w-100">
    <div className="row p-0 m-0 px-1 py-1 nuagike-input-file">
      <div
        className={classnames("col col-12 p-0 m-0  overflow-hidden", {
          "d-none": view === "min"
        }, {
          "d-flex align-items-center": view !== "min"
        })}
        hidden={view === "min"}>
        <FontAwesomeIcon icon="file-upload" className={classnames("ms-1 me-2", {
            "text-grise-2": errors.length === 0
          }, {"text-danger": errors.length})} size="2x"/>
        <div {...getRootProps({className: 'dropzone d-flex align-items-center overflow-hidden'})}>
          <input {...getInputProps()}/> {
            props.loading
              ? <ul className="list-group text-grise-3">
                  <li className="list-group-item text-grise-3">
                    <div className="row ">
                      <div className="col m-0 p-0 d-flex align-items-center">
                        <Loading className="d-flex align-items-center"/>
                        <span>
                          Processing file ...</span>
                      </div>
                    </div>
                  </li>
                </ul>

              : errors && errors.length
                ? <ul className="list-group text-danger">
                    {errors.map((err, index) => (<li key={"error-" + index} className="list-group-item text-danger">{err}</li>))}
                  </ul>
                : <ul className="list-group text-grise-3">{
                      files && files.length
                        ? files.map(file => (
                          <li key={file.path} className="list-group-item">
                            <div className="row p-0 m-0 overflow-hidden">
                              <div className="col col-12 m-0 p-0 overflow-hidden">
                                <span className="text-primary one-line overflow-hidden">{file.path}</span>

                                <small className="text-grise-2">{bytesToSize(file.size)}</small>
                              </div>
                            </div>
                          </li>
                        ))
                        : <li className="list-group-item text-grise-3">
                            <span className="text-muted">{intl.formatMessage({id: "text.admin.input.file.draganddrop"})}</span>
                            <span className="ps-1 text-muted">{
                                props.types
                                  ? <small className="text-info">
                                      {" [" + Object.keys(props.types).map(tkey => props.types[tkey] + " ") + "] "}</small>
                                  : ""
                              }
                              <small className="text-info">[max:{bytesToSize(maxFileSize)}]</small>
                            </span>
                          </li>
                    }</ul>
          }
        </div>
      </div>
      {
        props.thumbnail
          ? <div className={"col p-0 m-0 col-xl-4"}>
              <div className="d-flex align-items-center h-100">
                <div className="d-flex justify-content-center w-100">
                  <img src={props.thumbnail} style={{
                      maxHeight: "80px",
                      maxWidth: "100px"
                    }}/>
                </div>
              </div>
            </div>
          : files && files.length
            ? <div className={"col p-0 m-0 col-xl-6"}>
                <div className="row p-0 m-0 d-flex align-items-center justify-content-center h-100">
                  {
                    files.map((f,f_index )=><span key={"file-ref-"+f_index} className = "col col-12 text-truncate "> {
                      f.name
                    }</span>)
                  }
                </div>
              </div>
            : null
      }
      <div className="col p-0 m-0">
        <div className="d-flex align-items-center h-100">
          {
            url || files && files.length
              ? <button type="button" onClick={() => removeAll()} className="mx-auto nuagike-button btn btn-nuagike-grise-2 d-flex align-items-center py-2" disabled={props.loading || props.disabled}>
                  <FontAwesomeIcon icon="window-close" className="me-2 text-grise-3" size="1x"/> {intl.formatMessage({id: "text.admin.input.file.cancelfile"})}
                </button>
              : <button type="button" onClick={open} className="mx-auto nuagike-button btn btn-nuagike-success d-flex align-items-center py-2" disabled={props.loading || props.disabled}>
                  <FontAwesomeIcon icon="file-upload" className="me-2 text-light" size="1x"/> {intl.formatMessage({id: "text.admin.input.file.selectefile"})}
                </button>
          }
        </div>
      </div>

    </div>

  </div>;
}

export default File;
