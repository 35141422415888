import React, {useState} from 'react'
import classnames from 'classnames';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import "./index.scss"
import {OverlayTrigger, Overlay, Tooltip} from 'react-bootstrap';
import {useLoading} from "src/components/nuagike/index"

const Button = (props) => {

  return props.tooltip && !props.disabled
    ? <OverlayTrigger delay={{
          show: 650,
          hide: 200
        }} placement="top" overlay={<Tooltip><div> {
          props.tooltip
        }
        </div>
    </Tooltip>}>
        <div className="w-100 h-100 align-items-center"><ButtonContent {...props}/></div>
      </OverlayTrigger>
    : <ButtonContent {...props}/>
}

const ButtonContent = (props) => {
  const loading = false;// useLoading();

  return props.type === "float"
    ? <button onClick={(e) => props.action(e)} className={classnames("btn nuagike-button nuagike-button-float rounded-circle border-0 m-0",
        //
        "btn-nuagike-" + (
          props.color
          ? props.color
          : "success"), props.className)}
        /////
        disabled={props.disabled || loading || props.loading}>
        <div className="d-flex align-items-center p-0 m-0">
          <FontAwesomeIcon
            //
            icon={loading || props.loading
              ? "spinner"
              : props.icon
                ? props.icon
                : "check"}
            ////
            size={props.size
              ? props.size
              : "2x"}
            //////
            className={classnames("fa-fw justify-contents-center m-auto", {["fa-spin"]: props.loading})}/>
        </div>
      </button>
    : props.type === "circle"
      ? <button onClick={(e) => props.action(e)} className={classnames("btn nuagike-button nuagike-button-circle rounded-circle border-0 m-0 p-0 mx-1",

          //
          "btn-nuagike-" + (
            props.color
            ? props.color
            : "success"), props.className)} disabled={props.disabled || loading || props.loading}>
          <div className="nuagike-button-circle d-flex align-items-center p-0 m-0 ">
            <FontAwesomeIcon icon={props.icon
                ? props.icon
                : "check"} size={props.size
                ? props.size
                : "1x"} className="fa-fw justify-contents-center m-auto "/>
          </div>
        </button>
      : props.type === "icon"
        ? <button type="button"
        className={classnames("nuagike-button nuagike-button-carret btn p-0 m-0 d-flex align-items-center overflow-hidden",
            //
            {
              "d-none": props.hidden
            }, {
              ["btn-nuagike-size-" + (
                props.size || "1x")]: true
            }, "btn-nuagike-" + (
              props.color
              ? props.color
              : "success"), props.className)}
            ////
            disabled={Boolean(props.disabled || loading || props.loading)} onClick={(e) => props.action(e)}>
            <div className="mx-auto py-0 d-flex align-items-center ">
              <FontAwesomeIcon icon={props.loading
                  ? "spinner"
                  : props.icon || "check"} size={props.size || "1x"}
                ////
                className={classnames("fa-fw text-center mx-0 my-auto overflow-hidden", {["fa-spin"]: props.loading})}/>
            </div>
          </button>
        : <button type="button" className={classnames("nuagike-button btn h-100 p-0 m-0 d-flex align-items-center text-truncate ",


          //
          {
            "d-none": props.hidden
          }, {
            ["btn-nuagike-size-" + (
              props.size || "1x")]: true
          }, "btn-nuagike-" + (
            props.color
            ? props.color
            : "success"), props.className)} disabled={props.disabled || loading || props.loading}
          //
          onClick={(event) => {
            if (props.action) {
              props.action(event);
              event.stopPropagation();
              return true;
            }
          }}>
          <div className={classnames("rounded-start m-0 h-100 py-0 nuagike-button-icon d-flex align-items-center justify-content-center",
          {
            "py-2": props.size || props.size !== "1x"
          },
          {
            "py-1 ": ! props.size || props.size === "1x"
          },
        )}>
            <FontAwesomeIcon icon={props.loading
                ? "spinner"
                : props.icon
                  ? props.icon
                  : "check"} className={classnames("fa-fw", {["fa-spin"]: props.loading})} size={props.size || "1x"}/>

          </div>
          {
            props.message || props.text
              ? <span className={classnames("align-items-center nuagike-button-text pe-3 py-1 flex-grow-1 text-truncate",
                  //
                  {
                    "fs-3": props.size === "2x"
                  }, {
                    "justify-content-center": !props.start
                  }, {"justify-content-start": props.start})}>
                  {props.message || props.text}
                </span>
              : ""
          }
        </button>

}

export default Button;
