import React, {useState} from 'react'
import classnames from 'classnames';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {Typeahead, Token} from 'react-bootstrap-typeahead';

import {useEnums, useLoading, NuagikeContext} from "src/components/nuagike/index"

import "./index.scss"

const Timezone = (props) => {
  const {enums} = React.useContext(NuagikeContext);
  const data = enums["timezones"];
  const loading = false;// useLoading()
  const [timezones, setTimezones] = React.useState([])
  const ref = React.createRef();

  React.useEffect(() => {

    if (data) {
      let timezone = data.filter(c => c === props.value)[0]
      if (timezone) {
        setTimezones([timezone])
      } else {
        setTimezones([])
        ref.current.clear();
      }

    }
  }, [data, props.value]);

  const handleChange = (values) => {

    props.onChange(
      props.name, values[0]
      ? values[0]
      : "")
    //setTimezones(values)
  }

  return <div className={classnames("nuagike-input form-floating mt-1 d-flex align-items-center",
    //
    {
      ["nuagike-input-disabled"]: props.disabled || loading || props.loading
    })}>
    <div className="nuagike-input-prependicon d-flex align-items-center rounded-start">
      <FontAwesomeIcon icon={"globe"} className=""/>
    </div>
    <Typeahead ref={ref} className={classnames("form-control ",
      /*****/
      {
        "is-invalid": props.invalid
      }, {"rounded-0 rounded-end": true})} /**/

      id={props.id} name={props.id} multiple={false} disabled={props.disabled || loading}
      //
      onChange={(values) => handleChange(values)} labelKey={option => option} renderMenuItemChildren={(option) => {
        let tokens = option.split(" ");
        return <div className="row">
          <div className="col col-12">{tokens[1] ||option}</div>
          <div className="col col-12"><small>{tokens[0] ||option}</small></div>
        </div>
      }} highlightOnlyResult={true} minLength={1} options={data || []} placeholder={props.placeholder
        ? props.placeholder
        : props.label} selected={timezones} clearButton={true}/>
    <label className={classnames("form-label", {"form-label-prependicon": true})} htmlFor={props.id}>{props.label}<FontAwesomeIcon icon="sun" className="ms-1 text-danger" hidden={!props.required}/></label>
  </div>
}

export default React.memo(Timezone);
