import React, {useState} from 'react'
import classnames from 'classnames';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {DropDown, Button, Badge, Loading, useLoading} from "src/components/nuagike/index";
import {useIntl} from 'react-intl';
import "./index.scss";

const Page = ({
  floatSaveButton,
  bg,
  color,
  className,
  hidden,
  title,
  actions,
  icon,
  subtitle,
  onReload,
  onSave,
  onClose,
  onDownload,
  dirty,
  loading,
  children,
  ...props
}) => {
  const intl = useIntl();
  const isLoading = useLoading();

  return <div className="p-1 bg-light-30 rounded">
  <div className={classnames("nuagike-page rounded container-fluid w-100 p-0 m-0", className)} hidden={hidden} {...props}>
    {
      floatSaveButton
        ? <Button type="float" icon="save" size="3x" className="" color="success" action={(e) => onSave(e)}
            ///
            hidden={!onSave} loading={isLoading || loading} disabled={isLoading || loading || !dirty}/>
        : ""
    }

    <div className="row p-0 m-0 rounded">
      <div className={classnames("col col-12 p-0 m-0 border border-1 rounded border-light-10 ")}>

        <div className={classnames("row nuagike-page-row-header bg-gradient m-0 p-0 rounded-top", { //
            ["bg-" + bg]: bg
          }, {
            ["text-" + color]: color
          }, {
            ["text-dark"]: !color
          },
          /////
          {
            "d-none": !title
          })}>
          <div className="col col-12 m-0 p-0 py-1 d-flex align-items-center ">
          {
            actions
              ? <DropDown icon={"ellipsis-v"} items={actions} small={true} className="m-0 p-0 px-1"/>

              : <FontAwesomeIcon icon={icon
                    ? icon
                    : "tag"} className={classnames("fa-fw m-0 p-0 ms-2 me-2", {
                    ["text-" + color]: color
                  }, {
                    ["text-grise-3"]: !color
                  })}/>
          }
            <div className="d-flex flex-grow-1 align-items-center text-truncate">



                <div className={classnames("d-flex flex-grow-1 nuagike-page-title p-0 m-0 fw-bold align-items-center h-100 text-truncate", {
                    ["text-" + color]: color
                  }, {
                    ["text-dark"]: !color
                  })}>{
                    title + (
                      subtitle
                      ? " / " + subtitle
                      : "")
                  }</div>


            </div>

            <div className="d-flex align-items-center pe-1 text-truncate">

              <Button className="me-1" icon="save" color="success"
              text={intl.formatMessage({id:"text.admin.default.save"})} action={(e) => onSave(e)}
              hidden={!onSave} disabled={isLoading || loading || !dirty}/>

              <Button className="me-1" icon="sync" color="light" action={(e) => onReload(e)}
              hidden={!onReload} disabled={isLoading || loading}/>

              <Button icon="download" color="success" action={(e) => onDownload(e)}
              hidden={!onDownload} disabled={isLoading || loading}/>

              <Button icon="times" color="light" action={(e) => onClose(e)}
              hidden={!onClose} disabled={isLoading || loading}/>

            </div>

          </div>
        </div>

        <div className="row m-0 p-0">
          <div className="col p-1 m-0">
            {children}
          </div>
        </div>
      </div>
    </div>

  </div>
</div>
}

export default Page;
