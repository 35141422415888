import React, {useState, useEffect, useMemo} from 'react'

import {Table, Modal, Input, Page, useResource, NuagikeContext, useErrors} from 'src/components/nuagike/index'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {FormattedMessage, useIntl} from 'react-intl';
import classnames from "classnames";

const SuspendEvent = ({event, onSuspend, onClose, show}) => {
  const intl = useIntl()

  const {enums} = React.useContext(NuagikeContext);

  const [suspendEvent, setSuspendEvent] = React.useState({startDate: null, endDate: null, now: true, forever:true, notifyParticipants: false, emailRef: null})
  const errors = useErrors("event_status")

  const handleChanges = (name, value) => {
    setSuspendEvent(suspendEvent => ({
      ...suspendEvent,
      [name]: value
    }))
  }

  const handleSwitchChanges = (name, value) => {
    setSuspendEvent(suspendEvent => ({
      ...suspendEvent,
      [name]: !suspendEvent[name]
    }))
  }

  const handleSuspend = () => {
    onSuspend(suspendEvent, event.ref)
  }

  return <Modal icon="stopwatch" size="lg"
   title={intl.formatMessage({id: "text.admin.event.suspend.title"})}
   show={show} onSave={handleSuspend}
    onClose={onClose}>
    <div className="row p-0 m-0 mt-2">
      <div className="col p-0 m-0 col-12">
        <p>{intl.formatMessage({id: "text.admin.event.suspend.description"})}</p>
      </div>
    </div>
    <div className="row p-0 m-0">
      <div className="col p-0 m-0 col-lg-6 pe-1">
        <Input type="switch" name="now" id="event.suspend.now" value={suspendEvent.now}
          //
          label={intl.formatMessage({id: "text.admin.event.suspend.now.label"})} size="md" onChange={handleSwitchChanges}/>
      </div>
      <div className="col p-0 m-0 col-lg-6 ps-1">
        <Input type="switch" name="forever" id="event.suspend.forever" value={suspendEvent.forever}
          //
          label={intl.formatMessage({id: "text.admin.event.suspend.forever.label"})} size="md" onChange={handleSwitchChanges}/>
      </div>
    </div>
    <div className="row p-0 m-0">
      <div className="col p-0 m-0 col-lg-6 pe-1">

        <Input type="date" disabled={suspendEvent.now} required={true}
        id="input-event-startdate" name="startDate"
        onChange={handleChanges} value={suspendEvent.startDate || ""} label={intl.formatMessage({id: "text.admin.event.suspend.startdate"})}
          //
          invalid={errors.find("", "endDate")}/>

      </div>
      <div className="col p-0 m-0 col-lg-6 ps-1">

        <Input type="date" disabled={suspendEvent.forever} required={true}
        id="input-event-enddate" name="endDate"
        onChange={handleChanges} value={suspendEvent.endDate || ""} label={intl.formatMessage({id: "text.admin.event.suspend.enddate"})}
          //
          invalid={errors.find("", "endDate")}/>

      </div>
    </div>

    <div className="row p-0 m-0">
      <div className="col p-0 m-0 col-12">
        <Input type="switch" name="notifyParticipants" id="event.suspend.notifyParticipants" value={suspendEvent.notifyParticipants}
          //
          label={intl.formatMessage({id: "text.admin.event.suspend.notifyparticipants.label"})} size="md" onChange={handleSwitchChanges}/>
      </div>
    </div>

    <div className="row p-0 m-0">
      <div className="col p-0 m-0 col-12">

        <Input type="select" name="emailRef" id="event.suspend.emailtemplate"
        required={true}
        label={intl.formatMessage({id: "text.admin.event.suspend.emailtemplate"})}
          //
          disabled={!suspendEvent.notifyParticipants}
          value={suspendEvent.emailRef || ""} onChange={handleChanges}
          invalid={false}>

          <option>{intl.formatMessage({id: "text.admin.event.suspend.emailtemplate.choose"})}</option>

        </Input>
      </div>
    </div>

  </Modal>
}
SuspendEvent.displayName = "SuspendEvent";
export default SuspendEvent;
