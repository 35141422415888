import React from "react";
import classnames from "classnames";
import TableRow from "./row";

const TableBody = ({page, tableBodyProps, prepareRow}) => {

  

  return page && tableBodyProps && prepareRow
    ? <tbody {...tableBodyProps} style={{
          width: '100%'
        }}>
        {page.map((row, index) => prepareRow(row) || <TableRow row={row} key={"row-" + row.original.ref +"-"+ index}/>)}
      </tbody>
    : ""
}

export default TableBody;
