import React from "react";

const TableRow = React.memo(({row}) => {

  return <tr {...row.getRowProps()} style={{
      width: '100%'
    }}>
    {
      row.cells.map((cell, cell_idx) => {
        return <td key={"td_"+ cell.column.id+ "_" +cell_idx} {...cell.getCellProps([
              {
                      className: cell.column.className ,
                      style: {
                        minWidth: cell.column.minWidth ? cell.column.minWidth : cell.column.width,
                        width: cell.column.width,
                        maxWidth : cell.column.maxWidth ? cell.column.maxWidth : cell.column.width }
              } ])}>{cell.render('Cell')}</td>
      })
    }
  </tr>

});

TableRow.displayName = "TableRow";
export default TableRow;
