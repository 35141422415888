import React, {useState} from 'react'
import classnames from 'classnames';
import {useEnums} from "src/components/nuagike/index"
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {useIntl} from 'react-intl';

const EnumInput = (props) => {
  const intl = useIntl()
  const enumValues = useEnums(props.enum)

  const [value, setValue] = React.useState([])

  React.useEffect(() => {

    if (props.ms) {
      setValue(props.value.split(";").filter(v => v))
    } else {
      setValue([props.value])
    }

  }, [props]);

  return <div className={classnames("nuagike-input nuagike-input-enum p-1 m-0", {
      ["d-flex align-items-center"]: !props.hidden
    }, {
      ["rounded border border-2 border-grise-100"]: props.borderon
    }, {["d-none"]: props.hidden})}>
    <div className="d-flex align-content-center justify-content-start p-0 m-0">
      {
        props.label
          ? <label className={classnames("d-flex align-content-center h-100 p-0 m-0 ps-2 pe-2", props.labelClassName)}>
              {props.label}<FontAwesomeIcon icon="sun" className="ms-1 text-danger" hidden={!props.required}/></label>
          : ""
      }
    </div>
    <div className="w-100 d-flex flex-wrap">
      {
        enumValues && enumValues.length && enumValues.filter(enumName=>!props.excludes || !props.excludes.includes(enumName)).map(enumValue => {
          return <div className={classnames("nuagike-input-enum-item d-flex", {
              ["nuagike-input-enum-item-" + props.enum + "-" + enumValue]: enumValue
            })} key={"input-enum-" + props.enum + "_" + enumValue}>
            <input type="checkbox" className="btn-check border-none" id={props.id + "_" + enumValue} name={props.name}
              //
              checked={value.includes(enumValue) || false} disabled={props.disabled} onChange={(e) => {}}/>
            <label className={classnames("btn d-flex align-items-center h-100 border-light", {
                ["btn-outline-primary"]: !props.color
              }, {
                ["btn-outline-" + props.color]: props.color
              }, {
                ["btn-" + props.enum + "-" + enumValue]: enumValue
              })} htmlFor={props.id}
              ////
              onClick={(e) => {
                if (props.ms) {
                  let newValue = "";
                  if (value.includes(enumValue)) {
                    newValue = value.slice().filter(v => v !== enumValue);
                  } else {
                    newValue = [
                      ...value,
                      enumValue
                    ];
                  }
                  props.onChange(props.name, newValue.join(";"));
                } else {
                  props.onChange(props.name, enumValue)
                }

              }}>{
                intl.formatMessage({
                  id: "text.enum." + props.enum + "." + enumValue,
                  defaultMessage: enumValue
                })
              }</label>
          </div>
        })
      }
    </div>
  </div>

}

export default EnumInput;
