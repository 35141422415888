import React from 'react'
import {useDispatch} from 'react-redux'
import {successMessage, errorMessage} from "src/redux/actions/index";

export default function useMessage() {
  const dispatch = useDispatch();
  const success = (message) => {
    dispatch(successMessage({message: message}));
  }

  const error = (error) => {
    dispatch(errorMessage({message: error}));
  }

  return {success, error}
}
