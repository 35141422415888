import React from "react";
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import classnames from "classnames";

const TableHeader = ({headerGroups, draggable= false, hideFilters=false}) => {

  return headerGroups ? <thead style={{
      width: '100%'
    }}>
    {
      headerGroups.map((headerGroup, headerGroup_idx) => (<tr key={"tr_"+headerGroup_idx} {...headerGroup.getHeaderGroupProps()}>

        <th hidden={!draggable} style={{
            width: "45px"
          }}>
          <span className="text-center">
            <FontAwesomeIcon icon="sort-numeric-down"/>
          </span>
        </th>

        {
          headerGroup.headers.map((column, cindex) => {

            return <th key={"th_"+cindex} {...column.getHeaderProps([
                    {
                      className: column.headerClassName,
                      style: { minWidth: column.minWidth ? column.minWidth : column.width, width: column.width, maxWidth : column.maxWidth ? column.maxWidth : column.width },
                    }
                  ])}>
              {
                column.sortable
                  ? <span {...column.getSortByToggleProps()} title={null} className={column.isSorted
                        ? column.isSortedDesc
                          ? 'sorted-desc'
                          : 'sorted-asc'
                        : ''}>
                      <FontAwesomeIcon icon={column.isSorted
                          ? column.isSortedDesc
                            ? "sort-down"
                            : "sort-up"
                          : "sort"} className={classnames("me-1", {
                          "text-light": !column.isSorted
                        }, {"text-dark": column.isSorted})} hidden={column.action}/>{column.render('Header')}</span>
                  : <span className="text-truncate">{column.render('Header')}</span>
              }

              {
                column.filterable && !hideFilters
                  ? <div>{
                        column.canFilter
                          ? column.render('Filter')
                          : ""
                      }</div>
                  : ""
              }
            </th>
          })
        }
      </tr>))
    }
  </thead> : ""

}

export default TableHeader;
