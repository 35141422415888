import React, {useState} from 'react'
import classNames from 'classnames';
import "./index.scss"

const Card = ({selected, className, children, props}) => {

  return <div className={classNames("nuagike-card card m-0 mt-1 mb-2 p-1 border border-0 border-top-0 border-bottom-0 border-end-0 container-fluid",
//
{"border-info" : !selected },
////
{"border-success" : selected === true},
  className)} {...props}>
    <div className="card-body m-0 p-0">
      {children}
    </div>
  </div>

}

export default React.memo(Card);
