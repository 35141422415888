import React, {useState, useEffect} from 'react'
import classNames from 'classnames';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'

export default function useEditList({originals}) {
  const [localCopies, setLocalCopies] = React.useState([]);
  const [dirties, setDirties] = React.useState([]);
  const [dirty, setDirty] = React.useState(false);

  useEffect(() => {
    if (originals) {

      setLocalCopies(localCopies => {
        return originals.slice().sort(
          (a, b) => a.order > b.order
          ? 1
          : a.order < b.order
            ? -1
            : 0).map(original => ({
          ...original,
          ["etag"]: null,
          ["version"]: null
        }))
      });

      setDirties(dirties => []);
      setDirty(false);
    }
  }, [originals]);

  useEffect(() => {
    setDirty(dirties && dirties.length);
  }, [dirties]);

  const onAddNew = (params) => {
    let already = localCopies.filter(d => d.ref === "new").length;

    if (already === 0) {
      let newObject = {
        ...params,
        ['ref']: "new",
        ['dirty']: true
      }
      setDirties(dirties => [
        newObject, ...dirties
      ]);
      setLocalCopies(localCopies => [
        newObject, ...localCopies
      ]);
    }
  }

  const onDelete = (ref) => {
    setLocalCopies(localCopies => localCopies.slice().filter(o => o.ref !== ref));
    setDirties(dirties => dirties.slice().filter(o => o.ref !== ref));
  }

  const onClone = (ref, params) => {
    localCopies.filter(obj => obj.ref === ref).map(obj => {
      let cloneObject = Object.assign({
        ...obj
      }, params);
      cloneObject.dirty = true;
      cloneObject.ref = "new";

      setDirties(dirties => [
        cloneObject, ...dirties
      ]);
      setLocalCopies(localCopies => [
        cloneObject, ...localCopies
      ]);
    })
  }

  const onUpdateAll = (array) => {
    setLocalCopies(([...array]));
  }

  const onUpdate = (index, name, value) => {
    let ref = index !== null && localCopies[index]
      ? localCopies[index].ref
      : null;

    if (ref) {
      setLocalCopies(localCopies => [
        ...localCopies.slice(0, index), {
          ...localCopies[index],
          [name]: value,
          "dirty": true
        },
        ...localCopies.slice(index + 1)
      ]);

      setDirties(dirties => {
        let dirtyIndex = null;
        dirties.map((o, i) => {
          if (o.ref === ref) {
            dirtyIndex = i;
          }
        });

        if (dirtyIndex != null) {
          return [
            ...dirties.slice(0, dirtyIndex), {
              ...dirties[dirtyIndex],
              [name]: value
            },
            ...dirties.slice(dirtyIndex + 1)
          ];
        } else {
          return [
            {
              ref: ref,
              [name]: value
            },
            ...dirties
          ];
        }
      });

    }

  }


  const isDirty = (ref)=>{
    return dirties.map(d=>d.ref).includes(ref);
  }

  return {
    onDelete,
    onClone,
    onUpdate,
    onUpdateAll,
    onAddNew,
    localCopies,
    dirty,
    dirties,
    isDirty
  }
}
